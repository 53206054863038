import {IRiskItem} from "../../classes/IRiskItem";
import * as Fhir from "resources/classes/FhirModules/Fhir";
import {Questionnaire, QuestionnaireResponse, Tools} from "resources/classes/FhirModules/Fhir";
import {AnalyzerClass} from '../AnalyzerClass';
import {NitTools} from 'resources/classes/NursitTools';
import {IQuestionnaireList, QuestionnaireService} from '../QuestionnaireService';
import {fhirEnums} from "../../classes/fhir-enums";
import {ConfigService} from "../ConfigService";
import {PatientItem} from "../../classes/Patient/PatientItem";
import {PatientService} from "../PatientService";
import {QuestionnaireDialogContainer} from "../../elements/questionnaire/questionnaire-dialog-container";
import {RuntimeInfo} from "../../classes/RuntimeInfo";
import {BasicForm} from "../../elements/BasicForm";
import * as environment from "../../../../config/environment.json";
import {HttpClient, HttpResponseMessage} from "aurelia-http-client";
import {ReportService} from "../ReportService";
import {PatientChangeNotifier} from "../PatientChangeNotifier";
import {UserService} from "../UserService";
import {Prompt} from "../../elements/prompt";
import SystemHeaders from "../../classes/SystemHeaders";
import {translations} from "../../classes/translations";
import {INcsRow, ISempaResult, ISempaRow} from "./isempa-result";
import {IFormSetting} from "resources/classes/IFormSettings";
import {FhirService} from "../FhirService";
import HTTPVerb = fhirEnums.HTTPVerb;
import BundleType = fhirEnums.BundleType;
import QuestionnaireResponseStatus = fhirEnums.QuestionnaireResponseStatus;

const moment = require('moment');

export class AnalyzerSEMPA extends AnalyzerClass {

    public get resultHtml(): string {
        if (!this._resultHtml) {
            this._resultHtml = this.generateHtml(this.sempaResult);
        }

        return this._resultHtml;
    }

    ///// START IMPLEMENTATION/OVERLOADS HERE //////////////////
    protected static _svgImage: SVGImageElement;
    public static demmiWarningDisplayed: boolean = false;
    _version = "SEMPA";
    description: string = "SEMPA-Standard";
    demmiName = 'CareIT_DEMMI';
    fillGreen = 'rgb(53, 179, 94)';
    fillOrange = 'rgb(223, 112, 0)';
    fillRed = 'rgb(222, 0, 0)';
    fillGrey = '#cccccc';
    barthelIndexName: string = "CareITBarthelIndexSempa";
    barthelIndexEXName: string = "CareITBarthelIndexExSempa";
    patient: PatientItem;
    extraRows: string;
    analysisConfig: IFormSetting;
    public SFI: number = NaN;
    public SPI: number = NaN;
    _anamnesis: any;

    protected sempaResult: ISempaResult;

    public dysphagie = {
        isRisk: false,
        text: '',
        field: 'risk_dysphagie',
        color: undefined
    };
    public braden = {
        sum: NaN,
        field: 'risk_deku',
        text: '',
        color: undefined
    };
    public ncd = {
        text: '',
        isRisk: false,
        field: 'risk_vdd',
        sum: 0,
        sumField: 'risk_vdd_sum',
        color: undefined
    };

    public continence = {
        field: 'kontinenz',
        text: '',
        value: -1,
        color: undefined
    };
    public mobility = {
        field: 'mobility',
        text: '',
        value: -1,
        color: undefined
    };

    getFrueRehaConfig() {
        const route = 'FruehRehaIndex'.toUpperCase()    ;
        const cfg = ConfigService.cfg;
        if (!cfg)
            return undefined;

        const settings : IFormSetting = cfg.forms.find(o=> o.route?.toUpperCase() === route || o.questionnaireName?.toUpperCase().endsWith(route));

        return settings;
    }

    /**
     * Calculate the CareGrade / NBA
     * @param patient
     * @param assessment
     */
    public async calcCareGrade(patient: PatientItem, assessment: any) {
        const result = {
            sum: NaN,
            text: '',
            validated: false,
            enabled: false,
            isValid: false,
            errorMessage: undefined,
            questionnaireName: '',
            response: undefined
        };

        try {
            const cfg = ConfigService.GetFormSettings('CareIT_NBA');
            if (!cfg) {
                console.warn('No config for route "CareIT_NBA" found. Exiting CalcCareGrade!');
                return result;
            }

            if (!cfg?.enabled) {
                return result;
            }

            result.enabled = true;

            const nbaName = PatientItem.GetQuestionnaireName(patient, 'CareIT_NBA');
            if (!nbaName)
                return result;

            result.questionnaireName = nbaName;

            let nbaResponse: any = QuestionnaireResponse.GetAttachedResponseDirect(patient, assessment, nbaName);

            const questionnaireNba = QuestionnaireService.GetQuestionnaireByNameDirect(nbaName);
            //#region create new nba-response if none found
            if (!nbaResponse) {
                try {
                    nbaResponse = Fhir.Tools.SubstituteDefaultQRSkeleton(patient, questionnaireNba.id, 'in-progress');
                    QuestionnaireResponse.LinkResponsesMutual(patient, nbaResponse, assessment);
                    await this.fhirService.update(nbaResponse, false);
                } catch (e) {
                    console.warn(e);

                    return undefined;
                }
            }
            //#endregion

            result.validated = ['completed', 'amended'].indexOf(nbaResponse.status) > -1;
            /* const calcResult = SempaCalcCareGrade.CalcCareGrade(patient, nbaResponse, assessment);
            if (calcResult) {
                result.text = `${calcResult.text} (${calcResult.value}Pkt.)`;
                result.sum = calcResult.value;
            } */
            if (!result.validated) {
                //#region when not validated calculate the response fields to get the values
                // as this sure as hell contains recursive validations we call this three times!
                for (let i = 0; i <= 2; i++) {
                    /*if (ConfigService.Debug)
                        console.warn(`Calculating FieldValues for NBA pass ${i}/3`); */

                    let calculatedFields = Questionnaire.GetCalculatedFields(questionnaireNba);
                    if (calculatedFields) {
                        for (const field of calculatedFields) {
                            let val = undefined;
                            try {
                                val = Tools.CalculateField(field, questionnaireNba, nbaResponse);
                            } catch (e) {
                                if (ConfigService.Debug)
                                    console.warn(e);
                                val = undefined;
                            }

                            if (typeof val !== "undefined") {
                                // store as coding. Is corrected later
                                // if (ConfigService.Debug) console.debug("In SetDefaultValues, calculated field " + field.linkId + " with value " + String(val));
                                QuestionnaireResponse.SetResponseItemCodingByLinkId(nbaResponse, field.linkId, val);
                            }
                        }
                    }
                }
                //#endregion
            }

            const nba900 = QuestionnaireResponse.GetResponseItemValueIntByLinkId(nbaResponse, "NIT_NBA_900", NaN);
            const nba901 = QuestionnaireResponse.GetResponseItemValueByLinkId(nbaResponse, "NIT_NBA_901", this.i18n.tr("nb"));
            result.text = nba901;
            // console.log("NBA 900", nba900, nba901);

            if (typeof nba900 == "number" && !isNaN(nba900)) {
                result.sum = nba900;
                result.text = `${result.text} (${result.sum}Pkt.)`;
            }

            result.response = nbaResponse;
        } catch (e) {
            result.errorMessage = e;
        }

        return result;
    }

    protected updateCustomRisks(patient: PatientItem, assessment: any) {
        // to be overridden in derived classes
    }

    /**
     * Refreshes the Analyze-Values for the given patient
     * @param patient - the patient to analyze
     * @param assessment - the assessment to use for analysis
     * @param updateRiskAssessment - a value indicating whether the RiskAssessment for the patient should be created/updated. Defaults to true
     * @param storeRiskAssessment - as value indicating whether the RiskAssessment for the patient should be updated on the fhir server
     */
    public async analyse(patient: PatientItem, assessment?: any, updateRiskAssessment: boolean = true, storeRiskAssessment: boolean = true): Promise<any> {
        if (ConfigService.IsTest && typeof this.i18n.tr !== "function") {
            this.i18n.tr = (s: string | string[]) => {
                return String(s);
            };
        }

        this.patient = patient;

        if (!assessment && patient?.latestAssessment) {
            if (!patient.latestAssessment.subject?.reference)
                throw "Latest Assessment has no subject?";

            if (patient.latestAssessment.subject?.reference.indexOf('/' + patient.id) === -1)
                throw "Patient for latest Assessment does not match";

            assessment = patient.latestAssessment;
        }

        if (assessment?.subject?.reference?.indexOf('/' + patient.id) == -1 && PatientItem.LastLoadedPatient?.latestAssessment?.subject?.reference.indexOf('/' + patient.id) > -1) {
            console.warn('Given Assessment does not match the current Patient! Falling back to latestAssessment');
            assessment = PatientItem.LastLoadedPatient.latestAssessment;
        }

        this.currentAssessment = assessment;
        await super.analyse(patient, assessment, updateRiskAssessment, storeRiskAssessment);

        const cfgBarthel = ConfigService.GetFormSettings('barthelindex');
        this.barthelIndexName = await PatientItem.GetQuestionnaireName(patient, 'barthelindex', 'CareITBarthelIndexSempa');

        const cfgBarthelEx = ConfigService.GetFormSettings('barthelindexEx');
        this.barthelIndexEXName = await PatientItem.GetQuestionnaireName(patient, 'barthelindexEx', 'CareITExBarthelIndexSempa');

        await QuestionnaireService.Fetch();

        let qList = await QuestionnaireService.GetQuestionnaireIds();

        let questionnaireAssessment: any = undefined;
        let assessmentQuestionnaire = QuestionnaireService.GetQuestionnaireByNameDirect(patient.getAssessmentName());
        if (assessmentQuestionnaire) {
            if (!assessment ||
                (assessment && assessment.questionnaire && assessment.questionnaire.reference?.indexOf('/' + assessmentQuestionnaire.id) === -1)
                || this.assessmentName !== assessmentQuestionnaire.name) {
                assessment = QuestionnaireService.GetLatestResponseOfType(patient, assessmentQuestionnaire.id, [QuestionnaireResponseStatus.amended, QuestionnaireResponseStatus.completed]);
                if (assessment) {
                    QuestionnaireService.__listResult.QAssessmentId = assessmentQuestionnaire.id;
                    qList.QAssessmentId = assessmentQuestionnaire.id;
                    questionnaireAssessment = assessmentQuestionnaire;
                    this.assessmentName = assessmentQuestionnaire.name;
                }
            }
        }

        this.anamnesisName = await PatientItem.GetQuestionnaireName(patient, 'anamnesis');
        const anamnesisQuestionnaire = QuestionnaireService.GetQuestionnaireByNameDirect(this.anamnesisName);

        if (ConfigService.Debug && !ConfigService.IsTest) {
            console.debug('This is Analyzer Version: ' + this._version + ' using AssessmentName: ' + this.assessmentName + ' and anamnesisName: ' + this.anamnesisName);
        }

        if (!assessment) {
            if (ConfigService.Debug && !ConfigService.IsTest)
                console.warn("No Assessment in Patient", patient);

            return Promise.reject("No Assessment in Patient");
        }

        this._anamnesis = QuestionnaireService.GetLatestResponseOfType(patient, anamnesisQuestionnaire?.id, [QuestionnaireResponseStatus.completed, QuestionnaireResponseStatus.amended]);
        const _anamnesis = this._anamnesis;

        // check for the riskAssessment
        if (updateRiskAssessment && !patient.currentRisks) {
            patient.currentRisks = Fhir.CIRiskAssessment.CreateRiskAssessment(patient.encounter, patient, this.userService.practitioner, assessment, _anamnesis);
        }

        if (patient.currentRisks && patient.currentRisks.basis && !patient.currentRisks.basis.find(o => o.id === assessment.id)) {
            // when the basis is not the latest assessment, then create a new RiskAssessement
            patient.currentRisks = Fhir.CIRiskAssessment.CreateRiskAssessment(patient.encounter, patient, this.userService.practitioner, assessment, _anamnesis);
        }

        const biSum = cfgBarthel.enabled ? await this.mapBarthelIndexResponse(patient, assessment) : -1;
        let biSumText = String(biSum);
        const barthelIndexQuestionnaire = cfgBarthel.enabled ? QuestionnaireService.GetQuestionnaireByNameDirect(this.barthelIndexName) : undefined;

        if (barthelIndexQuestionnaire) {
            let responseBi: any;
            try {
                responseBi = await Fhir.QuestionnaireResponse.GetAttachedResponse(patient, assessment, barthelIndexQuestionnaire.name);
            } catch (e) {
                console.warn(e);
            }
            // const responseBi = QuestionnaireService.GetLatestResponseOfType(patient, q.id, [QuestionnaireResponseStatus.amended, QuestionnaireResponseStatus.completed, QuestionnaireResponseStatus.inProgress]);

            biSumText += this.getWarningMessage(responseBi);
        }

        const biExSum = cfgBarthelEx.enabled ? await this.mapBarthelIndexExResponse(patient, assessment) : -1;
        const barthelIndexEXQuestionnaire = cfgBarthelEx.enabled ? QuestionnaireService.GetQuestionnaireByNameDirect(this.barthelIndexEXName) : undefined;
        let biExSumText = String(biExSum);
        let responseBiEx: any;
        if (barthelIndexEXQuestionnaire) {
            try {
                responseBiEx = await Fhir.QuestionnaireResponse.GetAttachedResponse(patient, assessment, barthelIndexEXQuestionnaire.name);
            } catch (e) {
                console.warn(e);
            }

            //const responseBiEx = QuestionnaireService.GetLatestResponseOfType(patient, qEx.id, [QuestionnaireResponseStatus.amended, QuestionnaireResponseStatus.completed, QuestionnaireResponseStatus.inProgress]);
            biExSumText += this.getWarningMessage(responseBiEx);
        }

        const resultKontinenz = this.calculateIncontinenceProfile(patient, assessment);
        const resultDecu = this.calculateRiskDecu(patient, assessment);
        const resultVDD = this.calculateVdd(patient, assessment); // AKA NCD
        const resultFall = this.calculateFall(patient, assessment);

        const resultMnaBase = this.calcMNABase(patient, _anamnesis);
        let mnaText = await this.calcMNA(patient, assessment, _anamnesis, resultMnaBase.sum);

        const resultPneumo = this.calculatePneumo(patient, assessment);
        // const resultUebernahme = this.calculateUebernahme(patient, assessment);
        const resultDysphagie = this.calculateDysphagie(patient, assessment);
        const resultNcsA = this.calculateNCSA(patient, assessment);
        const resultNcsP = this.calculateNCSP(patient, assessment);
        const resultPAV = await this.calculatePAV(patient);

        const resultSPI = await this.calculateSpiSync(patient, assessment);
        patient.careLevel = -1; // resultNcsA.level; // Fhir.Tools.SpiToCareLevel(typeof resultSPI.sum === "number" ? resultSPI.sum : -1);
        patient.careLevelString = resultNcsA.overview;
        patient.careLevelColor = resultNcsA.color;

        patient.careLevel = resultNcsA.level; // just to be sure
        patient.SPI = resultNcsA.sum;

        this.patient = patient;

        window["printSubReport"] = async (route: string) => {
            await ConfigService.LoadConfigOverride(this.patient?.ward, this.patient);
            const fs = ConfigService.GetFormSettings(route);
            if (!fs || !fs.questionnaireName)
                return;
            const q = QuestionnaireService.GetQuestionnaireByNameDirect(fs.questionnaireName);
            if (!q) return;

            let latest = await QuestionnaireResponse.GetAttachedResponse(this.patient, this.patient.latestAssessment, q.name);
            if (!latest) {
                let anamnesis = QuestionnaireService.GetLatestResponseOfType(patient, QuestionnaireService.GetQuestionnaireByNameDirect(this.anamnesisName).id, [QuestionnaireResponseStatus.completed, QuestionnaireResponseStatus.amended]);

                if (anamnesis) {
                    latest = await QuestionnaireResponse.GetAttachedResponse(this.patient, anamnesis, q.name);
                }
            }

            if (!latest)
                latest = QuestionnaireService.GetLatestResponseOfType(this.patient, q.id, [QuestionnaireResponseStatus.inProgress, QuestionnaireResponseStatus.amended, QuestionnaireResponseStatus.completed]);

            if (!latest) return;

            if (latest.status === 'in-progress') {
                return this.dialogService
                    .open({
                        viewModel: Prompt, model: {
                            headerClass: 'info', showNo: false, showCancel: false, yesText: this.i18n.tr('ok'),
                            message: `Das aktuelle "${q.title}" Dokument ist nicht validiert.<br />\nBitte validieren Sie das Dokument vor dem Drucken.`
                        }, lock: true
                    });
            }

            ReportService.Preview(latest.id, fs.report.name, undefined, false);
        };

        const resultMobility = this.calcMobility(patient, assessment);
        let demmiButton = '';

        const demmiSetting = ConfigService.GetFormSettings('CareIT_Demmi');
        if (demmiSetting && demmiSetting.enabled === true) {
            this.demmiName = PatientItem.GetQuestionnaireName(patient, 'CareIT_DEMMI', 'CareIT_DEMMI');
            const questionnaireDemmi = QuestionnaireService.GetQuestionnaireByNameDirect(this.demmiName);
            if (questionnaireDemmi) {
                window['demmiButtonClicked'] = async () => {
                    await this.createPopup(patient, assessment, this.demmiName, false, () => {
                        this.calculateDemmiResultFields(responseDemmi);
                        PatientService.AddQuestionnaireResponse(patient, responseDemmi, true);
                    });
                };

                demmiButton = this.getButtonText('demmiButtonClicked', this.i18n.tr("sempa_demmi_button_text"), 'CareIT_DEMMI');
            } else {
                demmiButton = `<label class="text-danger">Questionnaire ${this.demmiName} nicht gefunden</label>`;
            }
        }

        let mnaButton = '';
        const mnaName = 'CareIT_MNA';
        const mnaSetting = ConfigService.GetFormSettings(mnaName);
        if (mnaSetting && (mnaSetting && mnaSetting.enabled === true)) {
            const questionnaireMNA = QuestionnaireService.GetQuestionnaireByNameDirect(mnaName);
            if (questionnaireMNA) {
                // mnaButton = `<button type="button" class="btn btn-primary" onclick="JavaScript:mnaButtonClicked()">MNA</button>`;

                window["mnaButtonClicked"] = async () => {
                    await this.calcMNA(patient, assessment, _anamnesis, resultMnaBase.sum);
                    await this.createPopup(patient, _anamnesis, mnaName);
                };

                mnaButton = this.getButtonText('mnaButtonClicked', this.i18n.tr("sempa_mna_button_text"), 'CareIT_MNA');
            } else {
                mnaButton = `<label class="text-danger">Questionnaire ${mnaName} nicht gefunden</label>`;
            }
        } else {
            mnaText = '';
        }

        window['nrsButtonClicked'] = async () => {
            await this.createPopup(patient, assessment, 'CareITNRS_SEMPA', true);
        };

        let eatButton = '';
        const eatName = 'CareIT_EAT';
        const eatSetting = ConfigService.GetFormSettings(eatName);
        if (eatSetting && eatSetting.enabled === true) {
            const questionnaireEAT = QuestionnaireService.GetQuestionnaireByNameDirect(eatName);
            if (questionnaireEAT) {
                // eatButton = `<button type="button" class="btn btn-primary" onclick="JavaScript:eatButtonClicked()">EAT-10</button>`;
                window["eatButtonClicked"] = async () => {
                    await this.createPopup(patient, assessment, eatName);
                };

                eatButton = this.getButtonText('eatButtonClicked', this.i18n.tr("sempa_eat_button_text"), 'CareIT_EAT');
            } else {
                eatButton = `<label class="text-danger">Questionnaire ${eatName} nicht gefunden</label>`;
            }
        }

        const biSetting = ConfigService.GetFormSettings('barthelindex');
        let biButton = this.getButtonText('barthelIndexClicked', this.i18n.tr("sempa_bi_button_text"), 'barthelindex');
        if (biSetting && biSetting.enabled !== true) {
            biButton = '';
        } else {
            window['barthelIndexClicked'] = async () => {
                await this.createPopup(patient, assessment, this.barthelIndexName, true);
            };
        }

        const biExSetting = ConfigService.GetFormSettings('barthelindexex');
        let biExButton = this.getButtonText('barthelIndexExClicked', this.i18n.tr("sempa_bi_ex_button_text"), 'barthelindexex');
        if (biExSetting && biExSetting.enabled !== true) {
            biExButton = '';
        } else {
            window['barthelIndexExClicked'] = async () => {
                await this.createPopup(patient, assessment, this.barthelIndexEXName, true);
            };
        }

        if (ReportService.ReportServer) {
            window["printstammblatt"] = async () => {
                await ConfigService.LoadConfigOverride(this.patient?.ward, this.patient);
                let fs = ConfigService.GetFormSettings('analysis');
                ReportService.Preview(_anamnesis.id, fs.report.name);
            };
        } else {
            window["printstammblatt"] = () => alert("Kein Report-Server konfiguriert.");
        }

        // handle social service buttons for PAV
        let btnSozialDienst = '';   // the button to display in the PAV-Row
        let btnSozialDienstMail = ''; // the last column content in the PAV-Row
        const cfg = ConfigService.GetFormSettings('analysis');
        const pavSettings = cfg.settings?.riskOverview?.find(o => o.field === "pav");

        // create the btnSozialDienst-Button element
        if (pavSettings?.showMailButtons !== false && this.patient && resultPAV && !isNaN(resultPAV.sum) && resultPAV.sum >= 8) {
            if (cfg?.settings?.socialService && this.patient) {
                if (!NitTools.IsArray(cfg.settings.socialService)) {
                    // btnSozialDienst = this.substituteMailString(`<a target="_blank" style="overflow: hidden; text-overflow: ellipsis;" href="${cfg.settings.socialService}" class="btn btn-primary width-100">${this.i18n.tr('inform_social_services_html')}</a>`);
                    console.warn("OLD VERSION OF SocialService Mail Settings! Please update to new Setting-Version");
                    btnSozialDienst = '<div style="color: red; border: 1px solid red; padding: 4px; text-align:center">SETTING WRONG</div>';
                } else {
                    btnSozialDienst = `<div class="w-100">`;
                    btnSozialDienstMail = `<div class="w-100">`;
                    for (const serviceEntry of cfg.settings.socialService) {
                        const mailInfo = <IMailSetting>serviceEntry;
                        const [button, confirm] = this.createMailButtons(mailInfo);
                        if (button && confirm) {
                            btnSozialDienst += button;
                            btnSozialDienstMail += confirm;
                        }
                    }

                    btnSozialDienst += "</div>";
                    btnSozialDienstMail += "</div>";
                }
            }
        }
        // check whether to display BASS Button

        if (pavSettings?.showBrass === true) {
            let brassHtml = '';
            // when no questionnaire has been provided in the setup, show as error
            if (!pavSettings?.brassQuestionnaireName) {
                brassHtml = `<b style="color:red">No brassQuestionnaireName specified in PAV-settings!</b>`;
            } else {
                const brassQuestionnaire = QuestionnaireService.GetQuestionnaireDirect(pavSettings?.brassQuestionnaireName);
                if (!brassQuestionnaire) {  // when questionnaire has not been found
                    brassHtml = `<b style="color:red">configured brassQuestionnaireName: "${pavSettings?.brassQuestionnaireName}" not found</b>`;
                } else {
                    if (brassQuestionnaire.status != 'active') {    // when questionnaire is not active
                        brassHtml = `<b style="color:red">configured brassQuestionnaireName: "${pavSettings?.brassQuestionnaireName}" is not status active</b>`;
                    } else {    // finally, display the brass button
                        window['brassButtonClicked'] = async () => {
                            await this.createPopup(patient, assessment, brassQuestionnaire.name, false, () => {
                                // this.calculateDemmiResultFields(responseDemmi);
                                // PatientService.AddQuestionnaireResponse(patient, responseDemmi, true);
                            });
                        };

                        brassHtml = this.getButtonText('brassButtonClicked', this.i18n.tr("sempa_BRASS_button_text"), 'BRASS');
                    }
                }
            }

            btnSozialDienst += brassHtml;
        }

        let icds = await this.icdService.calculateICDCodes(patient, assessment || patient.latestAssessment);
        let icdText = '<table>';
        for (const icd of icds) {
            icdText += `<tr><td class="icd-id-column">${String(icd.icd).replace('--', '-')}</td><td>${icd.text}</td>`;
        }

        icdText += '</table>';

        const ncsAColor = resultNcsA.color == "#c9c9c9" ? "black" : resultNcsA.color;

        let responseMNA: any;
        try {
            responseMNA = await Fhir.QuestionnaireResponse.GetAttachedResponse(patient, _anamnesis, mnaName);
        } catch (e) {
            console.warn(e);
        }

        const mapResult = this.mapMNAResponseScreeningGroup(patient, responseMNA, assessment, _anamnesis, false);
        let resultMNATextHtml = `${mapResult.text} / ${mapResult.sum} ${this.i18n.tr("points")}`;
        resultMNATextHtml += this.getWarningMessage(responseMNA);

        // planio:#1971, only show text in 4th column when mna is validated:
        if (responseMNA && ['completed', 'amended'].indexOf(responseMNA.status) === -1) {
            mnaText = '';
        }

        let resultMobilityTextHtml = resultMobility.text;
        let responseDemmi: any;
        try {
            responseDemmi = await Fhir.QuestionnaireResponse.GetAttachedResponse(patient, assessment, this.demmiName);
        } catch (e) {
            console.warn(e);
        }

        resultMobilityTextHtml += this.getWarningMessage(responseDemmi);

        let resultDysphagieTextHtml = resultDysphagie.text;
        let responseEAT: any;
        try {
            responseEAT = await Fhir.QuestionnaireResponse.GetAttachedResponse(patient, assessment, eatName);
        } catch (e) {
            console.warn(e);
        }

        resultDysphagieTextHtml += this.getWarningMessage(responseEAT);

        let demmiText = '';
        if (responseDemmi && responseDemmi.status !== 'in-progress') {
            const demmi501 = QuestionnaireResponse.GetResponseItemValueByLinkId(responseDemmi, 'NIT_DEMMI_501');
            if (demmi501)
                demmiText = `${this.i18n.tr("sempa_demmi_score_prefix")} ${demmi501}`;
        }

        let eatText = '';
        if (responseEAT && responseEAT.status !== 'in-progress') {
            let NIT_EAT_110: string | number = QuestionnaireResponse.GetResponseItemValueByLinkId(responseEAT, 'NIT_EAT_110', undefined);
            if (typeof NIT_EAT_110 === 'string') {
                NIT_EAT_110 = parseInt(NIT_EAT_110);
            }

            if (typeof NIT_EAT_110 === 'number' && !isNaN(NIT_EAT_110)) {
                if (NIT_EAT_110 < 3) // 'Füllen Sie diesen Fragebogen bei einer Änderung der Dysphagiesymptome erneut aus.'
                    eatText = this.i18n.tr("sempa_dysphagie_hint_update");
                else // 'Besprechen Sie diesen EAT-Fragebogen mit dem behandelnden Arzt.';
                    eatText = this.i18n.tr("sempa_dysphagie_hint_confere");
            }
        }

        //#region generate the diabetes mail button        
        const dInfo = this.getDiabetesButtonText(_anamnesis, assessment, patient);
        let diabetesDisplay = '';
        let diabetesButton = '';
        let diabetesOutCome = '';
        if (dInfo) {
            diabetesDisplay = dInfo[0];
            diabetesButton = dInfo[1];
            diabetesOutCome = dInfo[2];
        }
        //#endregion

        //#region get the nrs values
        const nrsButton = this.getButtonText('nrsButtonClicked', this.i18n.tr("sempa_nrs_button_text"), 'CareITNRS_SEMPA');
        let nrsText1 = this.i18n.tr('sempa_nrs_not_validated');
        let nrsValidated: boolean = false;
        let nrsMailButton = '';
        let nrsQuestionnaireName = "CareITNRS_SEMPA"; // default
        let nrsIsRisk: boolean = false;

        this.analysisConfig ??= ConfigService.GetFormSettings('analysis');

        let analysisConfigError: string;
        if (this.analysisConfig) {
            if (this.analysisConfig.settings) {
                if (NitTools.IsArray(this.analysisConfig.settings.riskOverview)) {
                    const nrsConfig = this.analysisConfig.settings.riskOverview.find(o => o.field === "nrs");
                    if (nrsConfig?.questionnaireName)
                        nrsQuestionnaireName = nrsConfig?.questionnaireName;
                    else {
                        analysisConfigError = 'Config Error! in route:"analysis" -> settings -> "riskOverview" -> field: "nrs": no value for "questionnaireName" has been specified';
                    }
                } else {
                    analysisConfigError = "Config Error! in route:\"analysis\" -> settings : no \"riskOverview\" has been specified";
                }
            } else {
                analysisConfigError = "Config Error! in route:\"analysis\" no \"settings\" has been specified";
            }
        }
        else {
            analysisConfigError = "Config Error! no route:\"analysis\" found in setup.json";
        }

        if (analysisConfigError) {
            console.error("\n\n\
╔════════════════════════════════╗\n\
║     ANALYZER Warning!          ║\n\
╚════════════════════════════════╝\n"
                + analysisConfigError);
        }

        const nrsResponse = await QuestionnaireService.GetLatestResponseOfName(this.patient, nrsQuestionnaireName, [QuestionnaireResponseStatus.amended, QuestionnaireResponseStatus.completed]);
        nrsText1 += this.getWarningMessage(nrsResponse);
        if (nrsResponse) {
            nrsValidated = true;
            // NRS_107: Weiteres Screening während des Aufenthalts vornehmen (Nein: NRS_107_00, Ja: NRS_107_01)
            const NRS_107 = QuestionnaireResponse.GetResponseItemValueByLinkId(nrsResponse, "NRS_107", "NRS_107_00");

            // Felder die sichtbar sind, wenn NRS_107=Nein ist:
            // NRS_600: Gesamtwert (inkl. Screeningswert aus der Anamnese) (Zahl)
            let NRS_600 = parseInt(QuestionnaireResponse.GetResponseItemValueByLinkId(nrsResponse, "NRS_600", '0'));
            if (isNaN(NRS_600))
                NRS_600 = 0;

            // NRS_601: Gesamtergebnis (inkl. Screeningswert aus der Anamnese)
            const NRS_601 = QuestionnaireResponse.GetResponseItemValueByLinkId(nrsResponse, "NRS_601");

            // Felder die sichtbar sind, wenn NRS_107=Ja=NRS_107_01 ist:
            // NRS_205: "Berechnung". Wenn dieses Feld 0 ist, keine weitere Berechnung, ist die Summe der Gruppe
            const NRS_205 = parseInt(QuestionnaireResponse.GetResponseItemValueByLinkId(nrsResponse, "NRS_205", '0'));
            // Erst ab NRS_205 > 0 kommen die Felder NRS_400 und NRS_401 ins Spiel
            // NRS_400: Invernetionen -> Gesamtwert (Zahl)
            const NRS_400 = parseInt(QuestionnaireResponse.GetResponseItemValueByLinkId(nrsResponse, "NRS_400", '0'));
            // NRS_401: Invernetionen -> Gesamtergebnis (string)
            const NRS_401 = QuestionnaireResponse.GetResponseItemValueByLinkId(nrsResponse, "NRS_401");

            /* if (!NRS_107) {
                nrsText1 = "Feld NRS_107 nicht gefunden";
                return;
            } */

            if (NRS_107?.endsWith('_01')) { // Weiteres Screening -> Ja
                nrsText1 = NRS_205 > 0 ? `${NRS_401} (${NRS_400})` : this.i18n.tr('n_a'); // wenn > 0 dann den Wert aus dem Textfeld, sonst nichts
                nrsIsRisk = NRS_400 >= 3;
            } else {
                nrsText1 = `${NRS_601} (${NRS_600})`;
                nrsIsRisk = NRS_600 >= 3;
            }

            if (nrsIsRisk && NitTools.IsArray(this.analysisConfig?.settings?.nrs) && this.analysisConfig.settings.nrs[0]) {
                const [button, confirm] = this.createMailButtons(this.analysisConfig.settings.nrs[0]);
                if (button && confirm) {
                    btnSozialDienst += button;
                    btnSozialDienstMail += confirm;
                }

                nrsMailButton = `<table><tr><td style="vertical-align: middle">${button}</td><td style="vertical-align: middle">${confirm}</td></tr></table>`;
            }
        }
        //#endregion

        this.sempaResult = {
            dates: {
                anamnesis: _anamnesis?.authored,
                assessment: patient?.latestAssessment?.authored || '-'
            },
            ncsA: {
                color: ncsAColor,
                text: resultNcsA.analyse,
                title: this.i18n.tr("sempa_nursing_care_score"),
                tag: "NCS-A",
                overview: resultNcsA.overview,
                sum: resultNcsA.sum
            },
            ncsP: {
                color: resultNcsP.color,
                text: resultNcsP.text,
                tag: "NCS-P",
                overview: resultNcsP.overview,
                sum: resultNcsP.sum
            },
            pav: {
                title: this.i18n.tr("sempa_title_PAV"),
                text1: `${resultPAV.text} ${typeof resultPAV.sum === "number" && resultPAV.sum ? (" / " + String(resultPAV.sum) + " " + this.i18n.tr("points")) : ""}`,
                button: btnSozialDienst,
                text2: btnSozialDienstMail,
                color: resultPAV.color,
                tag: "PAV"
            },
            diabetes: {
                title: this.i18n.tr('sempa_diabetes_mellitus'),
                text1: diabetesDisplay,
                button: diabetesButton,
                text2: diabetesOutCome,
                color: dInfo && dInfo[3] ? dInfo[3] : 'black',
                tag: "Diabetes"
            },
            decu: {
                title: this.i18n.tr("sempa_title_decu"),
                text1: resultDecu.text,
                color: resultDecu.color,
                tag: "Decubitus"
            },
            fall: {
                title: this.i18n.tr("sempa_title_fall"),
                text1: resultFall.text,
                color: resultFall.color,
                tag: "Fall",
                isRisk: resultFall.showButton
            },
            pneumo: {
                title: this.i18n.tr("sempa_title_pneumo"),
                text1: resultPneumo.text,
                color: resultPneumo.color,
                tag: "Pneumo",
                isRisk: resultPneumo.isRisk
            },
            vdd: {
                title: this.i18n.tr("sempa_title_vdd"),
                text1: resultVDD.text,
                color: resultVDD.color,
                tag: "VDD",
                isRisk: resultVDD.isRisk
            },
            mna: {
                title: this.i18n.tr("sempa_title_mna"),
                text1: resultMNATextHtml,
                button: mnaButton,
                text2: mnaText || '',
                color: resultMnaBase.color,
                tag: "MNA",
                isRisk: resultMnaBase.isRisk
            },
            nrs: {
                title: this.i18n.tr('sempa_nrs_title'),
                text1: nrsText1,
                button: nrsButton,
                text2: nrsMailButton,
                color: 'blue',
                tag: "NRS",
                isRisk: nrsIsRisk
            },
            demmi: {
                title: this.i18n.tr("sempa_title_mobility"),
                text1: resultMobilityTextHtml,
                button: demmiButton,
                text2: demmiText,
                color: this.mobility.color,
                tag: "Mobility"
            },
            eat: {
                title: this.i18n.tr("sempa_title_dysphagie"),
                text1: resultDysphagieTextHtml,
                button: eatButton,
                text2: eatText,
                color: this.dysphagie.color,
                tag: "EAT",
                isRisk: this.dysphagie.isRisk
            },
            barthelIndex: {
                title: this.i18n.tr("sempa_title_bi"),
                text1: biSumText,
                button: biButton,
                tag: "BI"
            },
            barthelIndexEx: {
                title: this.i18n.tr("sempa_title_bi_ex"),
                text1: biExSumText,
                button: biExButton,
                tag: "BIEx"
            },
            continence: {
                title: this.i18n.tr("sempa_title_continence"),
                text1: resultKontinenz.text,
                color: resultKontinenz.color,
                tag: "Continence"
            },
            icds: {
                title: this.i18n.tr("sempa_title_icds"),
                text1: icdText,
                tag: "ICDs"
            },
            nba: {
                title: 'NBA',
                text1: this.i18n.tr('n_a'),
                isVisible: false,
                isValidated: false,
                tag: 'NBA',
                sum: NaN
            },
            title: this.i18n.tr("sempa_risk_overview")
        };

        //#region process careGrade / NBA
        const nbaResult = await this.calcCareGrade(patient, assessment);
        if (nbaResult) {
            if (nbaResult.validated) {
                this.sempaResult.nba.text1 = nbaResult.text;
                this.sempaResult.nba.sum = nbaResult.sum;
                this.sempaResult.nba.isValidated = true;
            } else {
                this.sempaResult.nba.text1 = this.i18n.tr("sempa_caregrade_fillout_form");
            }

            this.sempaResult.nba.isVisible = nbaResult.enabled;
            window['NBAClicked'] = () => this.createPopup(patient, assessment, nbaResult.questionnaireName, false,
                async () => {
                    const r = await this.calcCareGrade(patient, assessment);
                    PatientService.AddQuestionnaireResponse(patient, r.response, true);
                });
            this.sempaResult.nba.button = this.getButtonText('NBAClicked', 'NBA', 'CareIT_NBA');
        }

        //#endregion

        this._resultHtml = this.generateHtml(this.sempaResult);
        //---------------------------------------

        if (patient.currentRisks && updateRiskAssessment) {
            if (!patient.currentRisks.prediction) patient.currentRisks.prediction = [];

            // update Pain-Value, although it is not really a risk. Just take it 1:1 from the assessment
            if (questionnaireAssessment) {
                const responseNIT_SVAs_202_Value = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_202', 'NIT_SVAs_202_00');
                let sumPain = 0;
                if (responseNIT_SVAs_202_Value && responseNIT_SVAs_202_Value !== 'NIT_SVAs_202_00') {
                    const itemNIT_SVAs_202 = Fhir.Questionnaire.GetQuestionnaireItemByLinkId(questionnaireAssessment, 'NIT_SVAs_202');
                    if (itemNIT_SVAs_202 && !itemNIT_SVAs_202.option && itemNIT_SVAs_202["answerOption"]) // R4 fix
                        itemNIT_SVAs_202.option = itemNIT_SVAs_202["answerOption"];

                    if (itemNIT_SVAs_202 && itemNIT_SVAs_202.option) {
                        const selected = itemNIT_SVAs_202.option.find(o => o.valueCoding && o.valueCoding.code === responseNIT_SVAs_202_Value);
                        if (selected?.extension) {
                            const extension = selected.extension.find(o => o.url?.indexOf('/questionnaire-ordinalValue') > -1);
                            try {
                                if (extension?.valueDecimal || extension?.valueInteger) {
                                    sumPain = extension.valueDecimal || extension.valueInteger;
                                }
                            } catch (e) {
                                console.warn(e);
                            }
                        }
                    }
                }

                this.updateRisk(patient, 'risk_pain_sum', sumPain, String(sumPain));
            }

            //#region update risks
            if (this.sempaResult.nba.isValidated && this.sempaResult.nba.isVisible)
                this.updateRisk(patient, 'risk_nba', this.sempaResult.nba.text1, String(this.sempaResult.nba.sum));

            this.updateRisk(patient, 'risk_spi', resultSPI.isRisk ? 'true' : 'false', this.i18n.tr(resultSPI.isRisk ? 'yes' : 'no'));
            this.updateRisk(patient, 'risk_spi_sum', resultSPI.sum, Fhir.Tools.CareLevelToText(patient.careLevel));
            this.updateRisk(patient, 'risk_ncs', resultSPI.ncs_Sum, resultSPI.ncs_Text);
            this.updateRisk(patient, 'risk_mna_base', resultMnaBase.isRisk ? 'true' : 'false', this.i18n.tr(resultMnaBase.isRisk ? 'yes' : 'no'));
            this.updateRisk(patient, 'risk_mna_sum', resultMnaBase.sum, resultMnaBase.text);

            this.updateRisk(patient, resultDecu.field + '_sum', resultDecu.sum, resultDecu.text);
            this.updateRisk(patient, resultDecu.field, resultDecu.sum >= 4 ? 'true' : 'false', this.i18n.tr(resultDecu.sum >= 4 ? 'yes' : 'no'));

            this.updateRisk(patient, resultPneumo.field, resultPneumo.isRisk, this.i18n.tr(resultPneumo.isRisk ? 'yes' : 'no'));
            this.updateRisk(patient, resultPneumo.field + '_sum', resultPneumo.sum, resultPneumo.text);

            this.updateRisk(patient, resultFall.field, resultFall.isRisk ? 'true' : 'false', resultFall.text);
            this.updateRisk(patient, resultFall.field + '_sum', resultFall.sum, String(resultFall.sum));

            this.updateRisk(patient, resultVDD.field, resultVDD.isRisk ? 'true' : 'false', this.i18n.tr(resultVDD.isRisk ? 'yes' : 'no'));
            this.updateRisk(patient, resultVDD.sumField, resultVDD.sum, String(resultVDD.sum));

            this.updateRisk(patient, resultDysphagie.field, resultDysphagie.isRisk ? 'true' : 'false', resultDysphagie.text);
            this.updateRisk(patient, resultDysphagie.field + '_sum', resultDysphagie.sum, String(resultDysphagie.sum));

            this.updateRisk(patient, resultKontinenz.field, resultKontinenz.value, resultKontinenz.text);
            this.updateRisk(patient, 'risk_mobility', resultMobility.value >= 2 ? 'true' : 'false', this.i18n.tr(resultMobility.value >= 2 ? 'yes' : 'no'));
            this.updateRisk(patient, 'risk_mobility_sum', resultMobility.value, resultMobility.text);
            this.updateRisk(patient, 'risk_ncsa_analysis', resultNcsA.sum, resultNcsA.analyse);
            this.updateRisk(patient, 'risk_ncsa_overview', resultNcsA.sum, resultNcsA.overview);
            this.updateRisk(patient, 'risk_ncsa', resultNcsA.sum >= 17 ? 'true' : 'false', this.i18n.tr(resultNcsA.sum >= 17 ? 'yes' : 'no'));

            this.updateRisk(patient, 'risk_ncsp', resultNcsP.sum >= 23 ? 'true' : 'false', this.i18n.tr(resultNcsP.sum >= 23 ? 'yes' : 'no'));
            this.updateRisk(patient, 'risk_ncsp_sum', resultNcsP.sum, resultNcsP.text);

            this.updateRisk(patient, 'risk_pav', resultPAV.sum, resultPAV.text);
            this.updateRisk(patient, 'risk_pav_sum', resultPAV.sum >= 15, this.i18n.tr(resultPAV.sum >= 15 ? 'yes' : 'no'));

            this.updateRisk(patient, 'risk_bi', biSum, this.i18n.tr('barthelindex'));
            this.updateRisk(patient, 'risk_biEx', biExSum, this.i18n.tr('barthelindexex'));

            //#region write Diabetes Mellitus as risk
            const NIT_SVAn_806 = QuestionnaireResponse.GetResponseItemValueByLinkId(_anamnesis, 'NIT_SVAn_806');
            if (NIT_SVAn_806 === 'NIT_SVAn_806_00') {
                this.updateRisk(this.patient, "risk_diabetes", "false", this.i18n.tr("no"));
            } else if (NIT_SVAn_806 === 'NIT_SVAn_806_01') {
                // get the type of the diabetes
                let txt: string = undefined;
                const NIT_SVAn_809 = QuestionnaireResponse.GetResponseItemValueByLinkId(_anamnesis, 'NIT_SVAn_809');
                if (NIT_SVAn_809) {
                    const itemNIT_SVAn_809 = Questionnaire.GetQuestionnaireItemByLinkId(anamnesisQuestionnaire, 'NIT_SVAn_809');
                    txt = QuestionnaireResponse.GetOptionText(NIT_SVAn_809, itemNIT_SVAn_809);
                }

                if (!txt)
                    txt = this.i18n.tr("yes");

                this.updateRisk(this.patient, "risk_diabetes", "true", txt);
            } else {
                this.updateRisk(this.patient, "risk_diabetes", "unknown", this.i18n.tr('not_evaluable'));
            }
            //#endregion

            //#endregion

            await PatientItem.UpdateCareLevel(patient, resultNcsA.level, assessment, resultNcsA.overview, resultNcsA.color, resultNcsA.sum);
            this.updateCustomRisks(patient, assessment);

            const demmiResponse = await this.mapDemmiValues(patient, assessment, !storeRiskAssessment);

            if (storeRiskAssessment) {
                const oldLoading = RuntimeInfo.IsLoading;
                try {
                    RuntimeInfo.IsLoading = true;
                    const qBi = QuestionnaireService.GetQuestionnaireByNameDirect(this.barthelIndexName);
                    const bi = QuestionnaireService.GetLatestResponseOfType(patient, qBi.id);
                    const qBiEx = QuestionnaireService.GetQuestionnaireByNameDirect(this.barthelIndexEXName);
                    const biEx = QuestionnaireService.GetLatestResponseOfType(patient, qBiEx.id);

                    const bundleItems = [
                        bi, biEx,
                        patient.selectedAdditionalInfo,
                        patient.latestAssessment,
                        //patient.currentRisks, <-- raises a fhir error when included. Have to update it seperately, whyever.. Smile suxx sometimes
                        patient.flags
                    ];

                    if (demmiResponse) // may be undefined if status is not in-progress
                        bundleItems.push(demmiResponse);

                    // TODO: Consider to add other responses to the bundle
                    await this.fhirService.bundle(bundleItems, HTTPVerb.put, BundleType.transaction);
                    await this.fhirService.update(patient.currentRisks);

                    try {
                        if (!ConfigService.IsTest) {
                            await this.fhirService.tags.update(patient.encounter, {
                                system: 'nursit-institute.com/structureDefinitions/tags/SPI',
                                code: String(patient.SPI)// resultSPI && typeof resultSPI.sum !== "undefined" ? resultSPI.sum.toString() : '-1'
                            });
                        }
                    } catch (e) {
                        console.warn("Setting $META failed!", e.message || e);
                    }
                } catch (e) {
                    console.warn(e.message || e);
                } finally {
                    RuntimeInfo.IsLoading = oldLoading;
                }
            }
        }

        PatientChangeNotifier.Notify(patient, patient.flags);

        return Promise.resolve({
            pkmsIsRisk: false,
            pkmsSum: patient.SPI
        });
    }

    //#region Dekubitusrisiko (Braden)
    /**
     * Dekubitusrisiko (Braden)

     Berechnung des Braden-Wertes:
     Summe der ausgewählten Berechnungswerte für die Items aus Tabelle 1. Die möglichen Punktwerte der Braden-Skala liegen zwischen 6 und 23 Punkten. Durch die Braden-Skala erfolgt eine Bewertung des Dekubitus-Risikos in folgende standartmäßige Stufen;
     1. Risiko Unwahrscheinlich 19-23 Punkte
     2. Geringes Risiko 18-15 Punkte
     3. Mittleres Risiko 14-13 Punkte
     4. Hohes Risiko 12-10 Punkte
     5. Sehr hohes Risiko =< 9 Punkte
     * @param patient
     * @param assessment
     */
    public calculateRiskDecu(patient: PatientItem, assessment?: any) {
        if (!assessment) assessment = patient.latestAssessment;
        let sum = 0;
        //#region sensorische Wahrnehmung: NIT_SVAs_200
        const item_200_value = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_200');
        let value200: number = 0;
        switch (item_200_value) {
            default:
                break;
            case 'NIT_SVAs_200_00':
                value200 = 4;
                break;
            case 'NIT_SVAs_200_01':
                value200 = 3;
                break;
            case 'NIT_SVAs_200_02':
                value200 = 2;
                break;
            case 'NIT_SVAs_200_03':
                value200 = 1;
                break;
        }

        sum += value200;
        //#endregion

        //#region Feuchtigkeit der Haut
        const item_203_value = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_203');
        let value203: number = 0;
        switch (item_203_value) {
            default:
                break;
            case 'NIT_SVAs_203_00':
                value203 = 4;
                break;
            case 'NIT_SVAs_203_01':
                value203 = 3;
                break;
            case 'NIT_SVAs_203_02':
                value203 = 2;
                break;
            case 'NIT_SVAs_203_03':
                value203 = 1;
                break;
        }

        sum += value203;
        //#endregion

        //#region Mobiltät
        const item_100_value = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_100');
        let value100: number = 0;
        switch (item_100_value) {
            default:
                break;
            case 'NIT_SVAs_100_00':
                value100 = 4;
                break;
            case 'NIT_SVAs_100_01':
                value100 = 3;
                break;
            case 'NIT_SVAs_100_02':
                value100 = 2;
                break;
            case 'NIT_SVAs_100_03':
                value100 = 1;
                break;
        }

        sum += value100;
        //#endregion

        //#region Positionswechsel im Bett
        const item_103_value = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_103');
        let value103: number = 0;

        switch (item_103_value) {
            default:
                break;
            case 'NIT_SVAs_103_00':
                value103 = 4;
                break;
            case 'NIT_SVAs_103_01':
                value103 = 3;
                break;
            case 'NIT_SVAs_103_02':
                value103 = 2;
                break;
            case 'NIT_SVAs_103_03':
                value103 = 1;
                break;
        }
        sum += value103;
        //#endregion

        //#region Ernährungszustand

        const item_400_value = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_400');
        let value400: number = 0;
        switch (item_400_value) {
            default:
                break;
            case 'NIT_SVAs_400_00':
                value400 = 4;
                break;
            case 'NIT_SVAs_400_01':
                value400 = 3;
                break;
            case 'NIT_SVAs_400_02':
                value400 = 2;
                break;
            case 'NIT_SVAs_400_03':
                value400 = 1;
                break;
        }

        sum += value400;
        //#endregion

        //#region Halten einer stabilen Sitzposition
        const item_104_value = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_104');
        let value104: number = 0;
        switch (item_104_value) {
            default:
                break;
            case 'NIT_SVAs_104_00':
                value104 = 3;
                break;
            case 'NIT_SVAs_104_01':
                value104 = 2;
                break;
            case 'NIT_SVAs_104_02':
                value104 = 1;
                break;
            case 'NIT_SVAs_104_03':
                value104 = 1;
                break;
        }
        //#endregion

        //#region Umsetzen
        const item_105_value = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_105');
        let value105: number = 0;
        switch (item_105_value) {
            default:
                break;
            case 'NIT_SVAs_105_00':
                value105 = 3;
                break;
            case 'NIT_SVAs_105_01':
                value105 = 2;
                break;
            case 'NIT_SVAs_105_02':
                value105 = 1;
                break;
            case 'NIT_SVAs_105_03':
                value105 = 1;
                break;
        }
        //#endregion

        sum += Math.min(value104, value105);

        if (sum <= 9) { // hohes risiko
            sum = 5;
        } else if (sum >= 10 && sum <= 12) { // erhöhtes Risiko
            sum = 4;
        } else if (sum >= 13 && sum <= 14) { // erhöhtes Risiko
            sum = 3;
        } else if (sum >= 15 && sum <= 18) { // erhöhtes Risiko
            sum = 2;
        } else if (sum >= 19) { // erhöhtes Risiko
            sum = 1;
        } else sum = -1;

        let text = this.i18n.tr("not_available");
        let col = undefined;
        switch (sum) {
            default:
            case 1:
                text = `${this.i18n.tr("risk_unlikely")} / 19-23 ${this.i18n.tr('points')}`;
                break;
            case 2:
                text = `${this.i18n.tr("risk_low")} / 15-18 ${this.i18n.tr('points')}`;
                break;
            case 3:
                text = `${this.i18n.tr("risk_med")} / 13-14 ${this.i18n.tr('points')}`;
                col = "rgb(150,150,0)";
                break;
            case 4:
                text = `${this.i18n.tr("risk_high")} / 10-12 ${this.i18n.tr('points')}`;
                col = "orange";
                break;
            case 5:
                text = `${this.i18n.tr("risk_very_high")} / <= 9 ${this.i18n.tr('points')}`;
                col = "red";
                break;
        }

        this.braden = {
            sum: sum,
            field: 'risk_deku',
            text: `${text}`,
            color: col
        };

        return this.braden;
    }

    //#endregion

    /**
     Sturzrisiko

     Rechenregel:
     Normales Sturzrisiko: 0 oder 1 der Items positiv bewertet
     Normales Sturzrisiko: 2 Items Ohne Mobilitätsitems (1,2,3) positiv
     Erhöhtes Risiko: 2 Items Mit Mobilitätsitems positiv
     Erhöhtes Risiko: 3 oder mehr Items positiv bewertet
     * @param patient
     * @param assessment
     */
    public calculateFall(patient: PatientItem, assessment?: any) {
        if (!assessment) assessment = patient.latestAssessment;
        let isRisk = false;
        let sum = 0;

        //region Mobilität // disabled by request from S.E.
        /*let value = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_100');
        switch (value) {
            case 'NIT_SVAs_100_01':
                sum += 1;
                break;
            case 'NIT_SVAs_100_02':
                sum += 1;
                break;
            case 'NIT_SVAs_100_03':
                sum += 1;
                break;
        } */
        //endregion

        // 2. Gleichgewicht // disabled by request from S.E.
        //if (QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_107') === 'NIT_SVAs_107_01') sum += 1;

        // 3. Gangsicherheit // disabled by request from S.E.
        //if (QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_108') === 'NIT_SVAs_108_01') sum += 1;

        //#region 4. Sensorische Wahrnehmung
        switch (QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_200')) {
            default:
                sum += 0;
                break;
            case 'NIT_SVAs_200_02':
            case 'NIT_SVAs_200_03':
                sum += 1;
                break;
        }
        //#endregion

        //#region 5. Harnableitungssystem
        switch (QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_502')) {
            case 'NIT_SVAs_502_01':
                sum += 1;
                break;
        }
        //#endregion

        //#region 6. Verstehen von Aufforderungen
        switch (QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_1102')) {
            default:
                sum += 0;
                break;
            case 'NIT_SVAs_1102_02':
            case 'NIT_SVAs_1102_03':
                sum += 1;
                break;
        }
        //#endregion

        //#region 7. Verstehen von Sachverhalten und Informationen
        switch (QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_1103')) {
            default:
                sum += 0;
                break;
            case 'NIT_SVAs_1103_02':
                sum += 1;
                break;
            case 'NIT_SVAs_1103_03':
                sum += 1;
                break;
        }
        //#endregion

        //#region 8. Erkennen von Risiken und Gefahren
        switch (QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_1106')) {
            default:
                sum += 0;
                break;
            case 'NIT_SVAs_1106_02':
                sum += 1;
                break;
            case 'NIT_SVAs_1106_03':
                sum += 1;
                break;
        }
        //#endregion

        let countMobi = 0;
        // Mobilität (0 = NIT_SVAs_100_00)
        if (QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_100') != "NIT_SVAs_100_00") countMobi++;
        // Gleichgewicht -> Nein (NIT_SVAs_107_01)
        if (QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_107') == "NIT_SVAs_107_01") countMobi++;
        // Gangsicherheit -> Nein (NIT_SVAs_108_01)
        if (QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_108') == "NIT_SVAs_108_01") countMobi++;


        let textValue: string = '';
        isRisk = false;

        if (isNaN(sum))
            sum = -1;
        let col;
        let needsButton: boolean = false;
        switch (sum) {
            case -1:
                textValue = this.i18n.tr("not_predictable");
                break;
            case 0:
                if (countMobi >= 2) {
                    textValue = this.i18n.tr("risk_higher");
                    needsButton = true;
                    isRisk = true;
                    col = "red";
                } else {
                    textValue = this.i18n.tr("risk_normal");
                }
                break;
            case 1:
                if (countMobi >= 1) {
                    textValue = this.i18n.tr("risk_higher");
                    col = "red";
                    isRisk = true;
                    needsButton = true;
                } else {
                    textValue = this.i18n.tr("risk_normal");
                }
                break;
            case 2:
                if (countMobi == 0) {
                    textValue = this.i18n.tr("risk_normal");
                } else {
                    textValue = this.i18n.tr("risk_higher");
                    needsButton = true;
                    isRisk = true;
                    col = "red";
                }
                break;
            default:
            case 3:
                textValue = this.i18n.tr("risk_higher");
                needsButton = true;
                isRisk = true;
                col = "red";
                break;
        }

        // console.warn(this.i18n.getLocale() +  " -> textValue: " + textValue);

        return {
            text: textValue,
            isRisk: isRisk,
            field: 'risk_sturz',
            sum: sum,
            color: col,
            showButton: needsButton
        };
    }

    /**
     * Dysphagie-Risiko
     * @param patient
     * @param assessment
     */
    public calculateDysphagie(patient: PatientItem, assessment: any): any {
        const val406 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_406');
        this.dysphagie.isRisk = val406 == 'NIT_SVAs_406_01';

        this.dysphagie.text = this.i18n.tr('risk_unlikely');
        if (this.dysphagie.isRisk) {
            this.dysphagie.text = this.i18n.tr('yes'); // + ' (evtl. EAT-10 Bogen)';
        }

        this.dysphagie.color = this.dysphagie.isRisk;

        return this.dysphagie;
    }

    /***
     * Vormals Abklärungserfordernis Verwirrtheit, Delir, Demenz (VDD).\n
     Es wird ein Abklärungserfordernis ausgewiesen, sobald mindestens ZWEI aufgeführte Triggeritems mit den dargestellten Werten angegeben werden.
     * @param patient
     * @param assessment
     */
    public calculateNCD(patient: PatientItem, assessment?: any) {
        /*  */
        let sum = 0;  // aka n/a
        //region Orientierung beeinträchtigt
        let value = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_06');
        switch (value) {
            default:
                sum += 0;
                break;
            case 'NIT_SVAs_06_01':
                sum += 2;
                break;
        }
        //endregion

        //region Wie ist das Verhältnis zwischen Nacht- und Tagschlafes?
        value = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_702');
        switch (value) {
            default:
                sum += 0;
                break;
            case 'NIT_SVAs_702_02':
                sum += 2;
                break;
            case 'NIT_SVAs_702_03':
                sum += 3;
                break;
        }
        //endregion

        //region Sich beschäftigen
        value = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_800');
        switch (value) {
            default:
                sum += 0;
                break;
            case 'NIT_SVAs_800_02':
                sum += 2;
                break;
            case 'NIT_SVAs_800_03':
                sum += 3;
                break;
        }
        //endregion

        //region Gestaltung des Tagesablaufs und Anpassung an Veränderungen
        value = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_803');
        switch (value) {
            case 'NIT_SVAs_803_02':
                sum += 2;
                break;
            case 'NIT_SVAs_803_03':
                sum += 3;
                break;
        }
        //endregion

        //region Ist Nähe-Distanz-Empfinden krankheitsbedingt beeinflusst?
        value = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_902');
        switch (value) {
            case 'NIT_SVAs_902_02':
                sum += 2;
                break;
            case 'NIT_SVAs_902_03':
                sum += 3;
                break;
        }
        //endregion

        //region Einnahme von Medikamente
        value = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_1005');
        switch (value) {
            case 'NIT_SVAs_1005_01':
                sum += 2;
                break;
            case 'NIT_SVAs_1005_02':
                sum += 2;
                break;
            case 'NIT_SVAs_1005_03':
                sum += 3;
                break;
        }
        //endregion

        //region Verstehen von Aufforderungen
        value = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_1102');
        switch (value) {
            case 'NIT_SVAs_1102_02':
                sum += 2;
                break;
            case 'NIT_SVAs_1102_03':
                sum += 3;
                break;
        }
        //endregion

        //region Verstehen von Sachverhalten und Informationen
        value = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_1103');
        switch (value) {
            case 'NIT_SVAs_1103_02':
                sum += 2;
                break;
            case 'NIT_SVAs_1103_03':
                sum += 3;
                break;
        }
        //endregion

        //region Erinnern an wesentliche Ereignisse oder Beobachtungen
        value = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_1104');
        switch (value) {
            case 'NIT_SVAs_1104_02':
                sum += 2;
                break;
            case 'NIT_SVAs_1104_03':
                sum += 3;
                break;
        }
        //endregion

        //region Erkennen von Personen aus dem näheren Umfeld
        value = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_1105');
        switch (value) {
            case 'NIT_SVAs_1105_02':
                sum += 2;
                break;
            case 'NIT_SVAs_1105_03':
                sum += 3;
                break;
        }
        //endregion

        //region 11. Erkennen von Risiken und Gefahren
        value = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_1106');
        switch (value) {
            case 'NIT_SVAs_1106_02':
                sum += 2;
                break;
            case 'NIT_SVAs_1106_03':
                sum += 3;
                break;
        }
        //endregion

        //region 12. Mitteilen von elementaren Bedürfnissen
        value = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_1107');
        switch (value) {
            case 'NIT_SVAs_1107_02':
                sum += 2;
                break;
            case 'NIT_SVAs_1107_03':
                sum += 3;
                break;
        }
        //endregion

        //region 13. Steuern von mehrschrittigen Alltagshandlungen
        value = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_1108');
        switch (value) {
            case 'NIT_SVAs_1108_02':
                sum += 2;
                break;
            case 'NIT_SVAs_1108_03':
                sum += 3;
                break;
        }
        //endregion

        //region 14. Treffen von Entscheidungen im Alltagsleben
        value = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_1109');
        switch (value) {
            case 'NIT_SVAs_1109_02':
                sum += 2;
                break;
            case 'NIT_SVAs_1109_03':
                sum += 3;
                break;
        }
        //endregion

        this.ncd.isRisk = sum >= 2;
        if (sum <= 1) this.ncd.text = this.i18n.tr("risk_unlikely");
        else if (sum >= 4) {
            if (this.ncd.isRisk) {
                this.ncd.text = this.i18n.tr('sempa_ncd_risk_yes');
            } else {
                this.ncd.text = this.i18n.tr('no');
            }
        }

        this.ncd.sum = sum;
        this.ncd.color = this.ncd.isRisk ? true : undefined;

        // Sind Verhaltensauffälligkeiten oder psychische Problemlagen vorhanden?
        const NIT_SVAs_1111 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_1111', '');

        if (NIT_SVAs_1111 === 'NIT_SVAs_1111_01')
            this.ncd.text = this.i18n.tr("sempa_no_ncd_possible");
        // this.ncd.text += ", die Verhaltensauffälligkeiten und psychische Zustände mussten eruiert werden."

        return this.ncd;
    }

    public calculateVdd(patient: PatientItem, assessment?: any) {
        return this.calculateNCD(patient, assessment);
    }

    /**
     * Inkontinent-Profil
     * @param patient
     * @param assessment
     */
    public calculateIncontinenceProfile(patient: PatientItem, assessment?: any) {
        if (!assessment) assessment = patient.latestAssessment;

        const SVAs_500 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, "NIT_SVAs_500");
        const SVAs_501 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, "NIT_SVAs_501");
        const SVAs_502 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, "NIT_SVAs_502");
        const SVAs_11105 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, "NIT_SVAs_11105");
        const SVAs_11102 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, "NIT_SVAs_11102");
        const SVAs_11103 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, "NIT_SVAs_11103");
        const SVAs_11104 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, "NIT_SVAs_11104");
        const SVAs_11107 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, "NIT_SVAs_11107");
        const SVAs_11110 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, "NIT_SVAs_11110");
        let col = "orange";

        let inkontinenzText = this.i18n.tr("not_calculable");
        let contincenceValue = -1;
        if (SVAs_500 === 'NIT_SVAs_500_00' && SVAs_501 === 'NIT_SVAs_501_00' && SVAs_502 === 'NIT_SVAs_502_01') {
            contincenceValue = 1;
            const textValue = "unabhaengigerreichtekontinenz";
            inkontinenzText = this.i18n.tr(textValue);
            col = undefined;
        } else if (SVAs_500 === 'NIT_SVAs_500_00' && SVAs_501 === 'NIT_SVAs_501_00' && SVAs_502 === 'NIT_SVAs_502_00') {
            const textValue = "kontinenz";
            inkontinenzText = this.i18n.tr(textValue);
            contincenceValue = 2;
            col = undefined;
        } else if ((['NIT_SVAs_500_01', 'NIT_SVAs_500_02', 'NIT_SVAs_500_03'].indexOf(SVAs_500) > -1
            && SVAs_501 === 'NIT_SVAs_501_00'
            && ['NIT_SVAs_502_00', 'NIT_SVAs_502_01'].indexOf(SVAs_502) > -1)
            || (SVAs_500 === 'NIT_SVAs_500_02' && SVAs_501 === 'NIT_SVAs_501_01' && SVAs_502 === 'NIT_SVAs_502_01')
        ) {
            const textValue = "abhaengigerreichtekontinenz";
            inkontinenzText = this.i18n.tr(textValue);
            contincenceValue = 3;
        } else if (SVAs_500 === 'NIT_SVAs_500_00'
            && ['NIT_SVAs_501_01', 'NIT_SVAs_501_02', 'NIT_SVAs_501_03'].indexOf(SVAs_501) > -1
            && ['NIT_SVAs_502_00', 'NIT_SVAs_502_01'].indexOf(SVAs_502) > -1
        ) {
            const textValue = "unabhaengigkompensierteinkontinenz";
            inkontinenzText = this.i18n.tr(textValue);
            contincenceValue = 4;
        } else if (['NIT_SVAs_500_01', 'NIT_SVAs_500_02', 'NIT_SVAs_500_03'].indexOf(SVAs_500) > -1
            && ['NIT_SVAs_501_01', 'NIT_SVAs_501_02', 'NIT_SVAs_501_03'].indexOf(SVAs_501) > -1
            && ['NIT_SVAs_502_00', 'NIT_SVAs_502_01'].indexOf(SVAs_502) > -1
            && ['NIT_SVAs_11105_01', 'NIT_SVAs_11105_02', 'NIT_SVAs_11105_03'].indexOf(SVAs_11105) > -1
            && ['NIT_SVAs_11110_01', 'NIT_SVAs_11110_02', 'NIT_SVAs_11110_03'].indexOf(SVAs_11110) > -1
        ) {
            const textValue = "abhaengigkompensierteinkontinenz";
            inkontinenzText = this.i18n.tr(textValue);
            contincenceValue = 5;
        } else if (['NIT_SVAs_500_01', 'NIT_SVAs_500_02', 'NIT_SVAs_500_03'].indexOf(SVAs_500) > -1
            && ['NIT_SVAs_501_01', 'NIT_SVAs_501_02', 'NIT_SVAs_501_03'].indexOf(SVAs_501) > -1
            && ['NIT_SVAs_502_00', 'NIT_SVAs_502_01'].indexOf(SVAs_502) > -1
            && ['NIT_SVAs_11102_01', 'NIT_SVAs_11102_02', 'NIT_SVAs_11102_03'].indexOf(SVAs_11102) > -1
            && ['NIT_SVAs_11103_01', 'NIT_SVAs_11103_02', 'NIT_SVAs_11103_03'].indexOf(SVAs_11103) > -1
            && ['NIT_SVAs_11104_01', 'NIT_SVAs_11104_02', 'NIT_SVAs_11104_03'].indexOf(SVAs_11104) > -1
            && ['NIT_SVAs_11107_01', 'NIT_SVAs_11107_02', 'NIT_SVAs_11107_03'].indexOf(SVAs_11107) > -1
        ) {
            const textValue = "nichtkompensierteinkontinenz";
            inkontinenzText = this.i18n.tr(textValue);
            contincenceValue = 6;
        } else if (['NIT_SVAs_500_01', 'NIT_SVAs_500_02', 'NIT_SVAs_500_03'].indexOf(SVAs_500) > -1
            && ['NIT_SVAs_501_01', 'NIT_SVAs_501_02', 'NIT_SVAs_501_03'].indexOf(SVAs_501) > -1
            && ['NIT_SVAs_502_00', 'NIT_SVAs_502_01'].indexOf(SVAs_502) > -1) {
            const textValue = "abhaengigkompensierteinkontinenz";
            inkontinenzText = this.i18n.tr(textValue);
            contincenceValue = 5;
        }

        this.continence = {
            field: 'kontinenz',
            text: inkontinenzText,
            value: contincenceValue,
            color: col
        };

        return this.continence;
    }

    /**
     * Pneumonierisiko
     * @param patient
     * @param assessment
     */
    public calculatePneumo(patient: PatientItem, assessment?: any) {
        let sum = 0;
        // Bewusstseinslage
        sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_05', 2));

        //Mobilität
        sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_100', 2));

        // Atmung
        sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_201', 1));


        //Schluckstörung
        sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_406'));

        return {
            text: this.i18n.tr(sum === 0 ? "risk_unlikely" : "increased_risk_long"),
            field: 'risk_pneu',
            sum: sum,
            isRisk: sum >= 1,
            color: sum === 0 ? undefined : 'red'
        };
    }

    /**
     * Übernahmefähigkeit
     * @param patient
     * @param assessment
     */
    public calculateUebernahme(patient: PatientItem, assessment: any) {
        let result: IRiskItem = {
            sum: 0,
            textSum: '0',
            linkIdSum: 'uebernahme_index',
            textRisk: '',
            buttonText: '',
            title: '&Uuml;bernahme-Index',
            showButton: false
        };

        try {
            let sum = 0;
            // Übernahme / Hilfestellung durch Angehörige
            sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_11'));

            // Übernahme / Hilfestellung durch Angehörige
            sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_111'));

            // Übernahme / Hilfestellung durch Angehörige
            sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_204'));

            // Übernahme / Hilfestellung durch Angehörige
            sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_305'));

            // Übernahme / Hilfestellung durch Angehörige
            sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_409'));

            // Übernahme / Hilfestellung durch Angehörige
            sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_509'));

            // Übernahme / Hilfestellung durch Angehörige
            sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_602'));

            // Übernahme / Hilfestellung durch Angehörige
            sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_704'));

            // Übernahme / Hilfestellung durch Angehörige
            sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_804'));

            // Übernahme / Hilfestellung durch Angehörige
            sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_903'));

            // Übernahme / Hilfestellung durch Angehörige
            sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_1022'));

            // Übernahme / Hilfestellung durch Angehörige
            sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_1112'));

            // Übernahme / Hilfestellung durch Angehörige
            sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_1204'));

            result.sum = sum;
            result.careLevel = sum;

            if (sum == 0) {
                result.textSum = 'keine Übernahme';
                result.careLevelColor = '#00b050';
            } else if (sum >= 1 && sum <= 11) {
                result.textSum = 'leichte Übernahme';
                result.careLevelColor = '#de0000';
            } else if (sum >= 12 && sum <= 22) {
                result.textSum = 'erhöhte Übernahme';
                result.careLevelColor = '#ffd966';
            } else if (sum >= 23 && sum <= 33) {
                result.textSum = 'hohe Übernahme';
                result.careLevelColor = '#ffc000';
            } else if (sum >= 34 && sum <= 38) {
                result.textSum = 'sehr hohe Übernahme';
                result.careLevelColor = '#de0000';
            } else if (sum >= 39) {
                result.textSum = 'vollständige Übernahme';
                result.careLevelColor = '#820000';
            }
        } catch (e) {
            result.hasError = true;
            result.error = e.message;
        }

        return result;
    }

    /**
     * TODO: Mangelernährung
     * @param patient
     * @param assessment
     * @param qList
     * @param spi
     */
    public calculateNRS(patient: PatientItem, assessment: any, qList: IQuestionnaireList, spi: number) {
        console.warn('Hier NRS für Sempa einbauen');
        debugger;
        let anamnesis = QuestionnaireService.GetLatestResponseOfType(patient, qList.QAnamnesisId, [QuestionnaireResponseStatus.amended, QuestionnaireResponseStatus.completed]);
        let formSettings = ConfigService.GetFormSettings('analyse');

        try {
            if (!assessment) assessment = patient.latestAssessment;
            if (!assessment) {
                let msg = "No Assessment for Patient found";
                console.warn(msg);

                return undefined;
            }
        } catch (error) {
            console.warn(error.message);
            return undefined;
        }
    }

    public async calculateSpi(patient: PatientItem, assessment?: any): Promise<any> {
        return new Promise((resolve, reject) => {
            try {
                const ri = this.calculateSpiSync(patient, assessment);
                resolve(ri);
            } catch (e) {
                reject(e.message || e);
            }
        });
    }

    public calculateSpiSync(patient: PatientItem, assessment?: any): any {
        let riskItem = {
            name: "SPI",
            linkIdSum: "risk_spi_sum",
            linkIdIsRisk: "risk_spi",
            hasError: false,
            error: undefined,
            sum: undefined,
            textSum: '',
            textRisk: '',
            buttonText: this.i18n.tr("not_aviable"),
            title: this.i18n.tr("riskofpkms"),
            showButton: false,
            careLevel: undefined,
            versorgung: false,
            careLevelColor: '#FFFFFF',
            ncs_Sum: -1,
            ncs_Text: '',
            isRisk: false
        };

        try {
            if (!assessment) assessment = patient.latestAssessment;
            let sum = 0;

            //region Gruppe NIT_SVAs_g0
            // Hörfähigkeit, NIT_SVAs_00
            sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_00'));

            // Sehfähigkeit, NIT_SVAs_01
            sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_01'));

            // sich verständlich machen, NIT_SVAs_02
            sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_02'));

            // Fähigkeit, sich an einem Gespräch zu beteiligen, NIT_SVAs_03
            sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_03'));

            // Fähigkeit, andere zu verstehen, NIT_SVAs_04
            sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_04'));
            // Bewusstseinslage , NIT_SVAs_05
            sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_05'));

            const val_07 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_07', 0);
            const val_08 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_08', 0);
            const val_09 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_09', 0);
            const val_10 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_10', 0);

            // Orientierung beeinträchtigt, NIT_SVAs_06
            if (QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_06_00') === 'NIT_SVAs_06_01') {
                let sum06 = (val_07 + val_08 + val_09 + val_10) / 4;
                sum = this.addSum(sum, sum06);
            }
            //endregion

            //region Gruppe NIT_SVAs_g1

            // Mobilität, NIT_SVAs_100
            sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_100'));

            // Bewegung außerhalb des Hauses / der Wohnung, NIT_SVAs_101
            sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_101'));

            // Bewegung innerhalb des Wohnbereichs, NIT_SVAs_102
            sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_102'));

            // Positionswechsel im Bett, NIT_SVAs_103
            sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_103'));

            // Halten einer stabilen Sitzposition, NIT_SVAs_104
            sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_104'));

            // Umsetzen, NIT_SVAs_105
            sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_105'));

            // Treppensteigen, NIT_SVAs_106
            sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_106'));

            // Gleichgewicht, NIT_SVAs_107
            switch (QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_107')) {
                case 'NIT_SVAs_107_00':
                    break;
                case 'NIT_SVAs_107_01':
                    sum = this.addSum(sum, 3);
                    break;
            }

            // Gangsicherheit, NIT_SVAs_108
            switch (QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_108')) {
                case 'NIT_SVAs_108_00':
                    break;
                case 'NIT_SVAs_108_01':
                    sum = this.addSum(sum, 3);
                    break;
            }

            // Aktueller Sturz, NIT_SVAs_110
            switch (QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_110')) {
                case 'NIT_SVAs_110_00':
                    break;
                case 'NIT_SVAs_110_01':
                    sum = this.addSum(sum, 3);
                    break;
            }

            //endregion

            //region Gruppe NIT_SVAs_g2
            //Sensorische Wahrnehmung, NIT_SVAs_200
            sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_200'));

            // Atmung, NIT_SVAs_201
            sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_201'));

            // Schmerzen, NIT_SVAs_202
            sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_202'));

            // Feuchtigkeit der Haut, NIT_SVAs_203
            sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_203'));
            //endregion

            //region Gruppe NIT_SVAs_g3
            // Körperpflege OK, NIT_SVAs_300
            sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_300'));
            // Körperpflege UK, NIT_SVAs_301
            sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_301'));
            // Körperpflege im Bereich des Kopfes, NIT_SVAs_302
            sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_302'));
            // Waschen des Intimbereiches, NIT_SVAs_303
            sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_303'));
            // Fähigkeit ein Vollbad, Sitzbad, Duschbad inkl. Haarwäsche zu nehmen, NIT_SVAs_304
            sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_304'));
            //endregion

            //region Gruppe NIT_SVAs_g4
            //Ernährungszustand, NIT_SVAs_400
            sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_400'));

            // Essenszubereitung - Vorbereiten (auch der Zutaten), Kochen, Auftragen, NIT_SVAs_401
            sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_401'));

            // Mundgerechtes Zubereiten der Nahrung und Eingießen von Getränken, NIT_SVAs_402
            sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_402'));

            // Essen, NIT_SVAs_403
            switch (QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_403')) {
                case 'NIT_SVAs_403_00':
                    break;
                case 'NIT_SVAs_403_01':
                    sum = this.addSum(sum, 3);
                    break;
                case 'NIT_SVAs_403_02':
                    sum = this.addSum(sum, 6);
                    break;
                case 'NIT_SVAs_403_03':
                    sum = this.addSum(sum, 9);
                    break;
            }

            // Ernährung parenteral oder über Sonde, NIT_SVAs_404
            switch (QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_404')) {
                case 'NIT_SVAs_404_00':
                case 'NIT_SVAs_404_01':
                    break;
                case 'NIT_SVAs_404_02':
                    sum = this.addSum(sum, 6);
                    break;
                case 'NIT_SVAs_404_03':
                    sum = this.addSum(sum, 3);
                    break;
            }

            // Trinken - Flüssigkeitsaufnahme, NIT_SVAs_405
            switch (QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_405')) {
                case 'NIT_SVAs_405_00':
                    break;
                case 'NIT_SVAs_405_01':
                    sum = this.addSum(sum, 2);
                    break;
                case 'NIT_SVAs_405_02':
                    sum = this.addSum(sum, 4);
                    break;
                case 'NIT_SVAs_405_03':
                    sum = this.addSum(sum, 6);
                    break;
            }

            // Schluckstörung, NIT_SVAs_406
            switch (QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_406')) {
                case 'NIT_SVAs_406_00':
                    break;
                case 'NIT_SVAs_406_01':
                    sum = this.addSum(sum, 3);
            }

            // Übelkeit, NIT_SVAs_407
            switch (QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_407')) {
                case 'NIT_SVAs_407_00':
                    break;
                case 'NIT_SVAs_407_01':
                    sum = this.addSum(sum, 3);
                    break;
            }

            // Erbrechen, NIT_SVAs_408
            switch (QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_408')) {
                case 'NIT_SVAs_408_00':
                    break;
                case 'NIT_SVAs_408_01':
                    sum = this.addSum(sum, 3);
                    break;
            }
            //
            //endregion

            //region Gruppe NIT_SVAs_g5
            // Toilettenbenutzung (ggf. Toilettenstuhlbenutzung), NIT_SVAs_500
            switch (QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_500')) {
                case 'NIT_SVAs_500_00':
                    break;
                case 'NIT_SVAs_500_01':
                    sum = this.addSum(sum, 2);
                    break;
                case 'NIT_SVAs_500_02':
                    sum = this.addSum(sum, 4);
                    break;
                case 'NIT_SVAs_500_03':
                    sum = this.addSum(sum, 6);
                    break;
            }

            // Blasenkontrolle, NIT_SVAs_501
            sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_501'));

            // Harnableitungssystem, NIT_SVAs_502
            switch (QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_502')) {
                case 'NIT_SVAs_502_00':
                    break;
                case 'NIT_SVAs_502_01':
                    sum = this.addSum(sum, 3);
                    break;
            }

            // Umgang mit der Harnausscheidung, NIT_SVAs_503
            sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_503'));

            // Darmkontrolle, NIT_SVAs_504
            sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_504'));

            // Stuhlableitungssystem, NIT_SVAs_505
            switch (QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_505')) {
                case 'NIT_SVAs_505_00':
                    break;
                case 'NIT_SVAs_505_01':
                    sum = this.addSum(sum, 3);
                    break;
            }

            // Umgang mit der Stuhlausscheidung, NIT_SVAs_506
            sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_506'));

            // Obstipation, NIT_SVAs_507
            switch (QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_507')) {
                case 'NIT_SVAs_507_00':
                    break;
                case 'NIT_SVAs_507_01':
                    sum = this.addSum(sum, 3);
                    break;
            }

            //Diarrhoe, NIT_SVAs_508
            switch (QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_508')) {
                case 'NIT_SVAs_508_00':
                    break;
                case 'NIT_SVAs_508_01':
                    sum = this.addSum(sum, 3);
                    break;
            }

            //endregion

            //region Gruppe NIT_SVAs_g6
            // An- und Auskleiden Oberkörper, NIT_SVAs_600
            sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_600'));

            // An- und Auskleiden Unterkörper, NIT_SVAs_601
            sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_601'));

            //endregion

            //region Gruppe NIT_SVAs_g7
            // Ruhen und Schlafen, NIT_SVAs_700
            sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_700'));

            // Qualität des Schlafes, NIT_SVAs_701
            sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_701'));

            //Wie ist das Verhältnis zwischen Nacht- und Tagschlafes?, NIT_SVAs_702
            sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_702'));

            //Wie ist die Schlafhygiene?, NIT_SVAs_703
            sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_703'));

            //endregion

            //region Gruppe NIT_SVAs_g8
            // Sich beschäftigen, NIT_SVAs_800
            sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_800'));

            // Interaktion mit Personen im direkten Kontakt, NIT_SVAs_801
            sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_801'));

            // Kontaktpflege zu Personen außerhalb des direkten Umfeldesm, NIT_SVAs_802
            sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_802'));

            // Gestaltung des Tagesablaufs und Anpassung an Veränderungen, NIT_SVAs_803
            sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_803'));
            //endregion

            //region Gruppe NIT_SVAs_g9
            // Beschäftigt sich KundIn aktuell mit eigener Sexualität oder Selbstempfinden?, NIT_SVAs_900
            if (QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_900') === 'NIT_SVAs_900_01') sum = this.addSum(sum, 3);

            // Auswirkungen des veränderten Sexuellebens durch Alter oder andere Faktoren vorhanden?, NIT_SVAs_901
            if (QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_901') === 'NIT_SVAs_901_01') sum = this.addSum(sum, 3);

            // Ist Nähe-Distanz-Empfinden krankheitsbedingt beeinflusst?, NIT_SVAs_902
            sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_902'));

            //endregion

            //region Gruppe NIT_SVAs_g10
            // Einkaufen von Lebensmitteln und Haushaltswaren
            // NIT_SVAs_1000
            sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_1000'));

            // Hausarbeit
            // NIT_SVAs_1001
            sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_1001'));

            //Finanzielle Regelungen
            // NIT_SVAs_1002
            sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_1002'));

            //Telekommunikation
            // NIT_SVAs_1003
            sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_1003'));

            //Benützen öffentlicher Verkehrsmittel oder des eigenen Fortbewegungsmittels
            // NIT_SVAs_1004
            sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_1004'));

            //Einnahme von Medikamente - MULTISELECT!
            // NIT_SVAs_1005
            const answers1005 = QuestionnaireResponse.GetResponseItemByLinkId(assessment, 'NIT_SVAs_1005');
            if (typeof answers1005 !== "undefined") {
                for (const answer of answers1005.answer) {
                    if (answer.valueCoding && answer.valueCoding.code) {
                        switch (answer.valueCoding.code) {
                            case 'NIT_SVAs_1005_00':
                                break;
                            case 'NIT_SVAs_1005_01':
                                sum = this.addSum(sum, 2);
                                break;
                            case 'NIT_SVAs_1005_02':
                                sum = this.addSum(sum, 1);
                                break;
                            case 'NIT_SVAs_1005_03':
                                sum = this.addSum(sum, 2);
                                break;
                            case 'NIT_SVAs_1005_04':
                                sum = this.addSum(sum, 1);
                                break;
                        }
                    }
                }
            }

            //Einhaltung einer Diät und anderer krankheits- oder therapiebedingter Verhaltensvorschriften, und zwar
            // NIT_SVAs_1021
            sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_1021'));
            //endregion

            //region Gruppe NIT_SVAs_g11

            // Stimmungslage
            // NIT_SVAs_1100
            sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_1100'));

            //Soziale Rollen / Aktivitäten
            // NIT_SVAs_1101
            sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_1101'));

            //Verstehen von Aufforderungen
            // NIT_SVAs_1102
            sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_1102'));

            //Verstehen von Sachverhalten und Informationen
            // NIT_SVAs_1103
            sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_1103'));

            //Erinnern an wesentliche Ereignisse oder Beobachtungen
            // NIT_SVAs_1104
            sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_1104'));

            // Erkennen von Personen aus dem näheren Umfeld
            // NIT_SVAs_1105
            sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_1105'));

            //Erkennen von Risiken und Gefahren
            // NIT_SVAs_1106
            sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_1106'));

            //Mitteilen von elementaren Bedürfnissen
            // NIT_SVAs_1107
            sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_1107'));

            //Steuern von mehrschrittigen Alltagshandlungen
            // NIT_SVAs_1108
            sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_1108'));

            //Treffen von Entscheidungen im Alltagsleben
            // NIT_SVAs_1109
            sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_1109'));

            //Vornehmen von in die Zukunft gerichteten Planungen
            // NIT_SVAs_1110
            sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_1110'));

            //endregion

            //region Gruppe NIT_SVAs_g12
            // Isolation
            // NIT_SVAs_1200
            sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_1200'));

            //Sind Ressourcen beim Umgang mit existentieller Erfahrungen wie Isolation vorhanden?
            // NIT_SVAs_1201_00
            sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_1201_00'));

            //Wieweit kann KundIn eigene Ressourcen, mit der aktuellen Lebenssituation umzugehen, aktivieren?
            // NIT_SVAs_1202
            sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_1202'));

            // Bestehende Beziehungen aufrechterhalten (Bekannte, Verwandte)
            // NIT_SVAs_1203
            sum = this.addSum(sum, this.fieldToIntValue(assessment, 'NIT_SVAs_1203'));

            //endregion

            let score: number = 0;
            //region Auswertung
            if (sum < 25) score = 1;
            else if (sum >= 26 && sum <= 50) score = 2;
            else if (sum >= 51 && sum <= 75) score = 3;
            else if (sum >= 76 && sum <= 100) score = 4;
            else if (sum >= 101 && sum <= 125) score = 5;
            else if (sum >= 126 && sum <= 150) score = 6;
            else if (sum >= 151 && sum <= 175) score = 7;
            else if (sum >= 176 && sum <= 200) score = 8;
            else if (sum >= 201 && sum <= 225) score = 9;
            else if (sum >= 226 && sum <= 265) score = 10;

            riskItem.ncs_Sum = score;
            riskItem.ncs_Text = `NCS-A ${score}`;
            //endregion

            const colors = ['#00b050', '#92d050', '#c6e0b4', '#ffff00', '#ffd966',
                '#ffc000', '#ff9933', '#ff0000', '#cc0066', '#820000'];
            if (score > 0) riskItem.careLevelColor = colors[score - 1];

            riskItem.textSum = `NCS-A ${score}`;


            // level everything down to default max. 40 SPI-Value. CIW max. SPI has been 40. this new one has a max of 265. So, 265/40=6,625
            riskItem.sum = 40 - (sum > 0 ? Math.round(sum / 6.625) : 0);
            riskItem.isRisk = riskItem.sum >= 25;
            // we currently have max. 5 CareLevels. this has 10, so just do a /2
            riskItem.careLevel = Fhir.Tools.SpiToCareLevel(riskItem.sum);  // score > 0 ? Math.round(score/2) : 0;
            this.SPI = riskItem.sum;
        } catch (e) {
            riskItem.hasError = true;
            riskItem.error = e.message;
            if (ConfigService.IsTest)
                throw (e);
        }

        return riskItem;
    }

    public override async calculateMNA(patient: PatientItem, assessment: any) {
        const b = this.calcMNABase(patient, this._anamnesis)?.sum || 0;
        return await this.calcMNA(patient, assessment, this._anamnesis, b);
    }

    /**
     * Mangelernährung, MNA
     */
    public calcMNABase(patient: PatientItem, anamnesis: any): { sum: number, text: string, isRisk: boolean, color: any } {
        const result = {
            sum: -1,
            text: this.i18n.tr('nrs_risk_na'),
            isRisk: false,
            color: undefined
        };

        if (!patient || !anamnesis) return result;

        let sum = 0;
        switch (QuestionnaireResponse.GetResponseItemValueByLinkId(anamnesis, 'NIT_SVAn_02')) {
            case 'NIT_SVAn_02_00':
                sum += 2;
                break;
            case 'NIT_SVAn_02_01':
                sum += 1;
                break;
            default:
            case 'NIT_SVAn_02_02':
                break;
        }

        switch (QuestionnaireResponse.GetResponseItemValueByLinkId(anamnesis, 'NIT_SVAn_803')) {
            case 'NIT_SVAn_803_00':
                sum += 2;
                break;
            case 'NIT_SVAn_803_01':
                sum += 1;
                break;
            default:
            case 'NIT_SVAn_803_02':
                break;
        }

        switch (QuestionnaireResponse.GetResponseItemValueByLinkId(anamnesis, 'NIT_SVAn_804')) {
            case 'NIT_SVAn_804_00':
                sum += 3;
                break;
            case 'NIT_SVAn_804_01':
                sum += 2;
                break;
            default:
            case 'NIT_SVAn_804_02':
                break;
            case 'NIT_SVAn_804_03':
                sum += 1;
                break;
        }

        switch (QuestionnaireResponse.GetResponseItemValueByLinkId(anamnesis, 'NIT_SVAn_900')) {
            case 'NIT_SVAn_900_00':
                sum += 2;
                break;
            default:
            case 'NIT_SVAn_900_01':
                break;
        }

        switch (QuestionnaireResponse.GetResponseItemValueByLinkId(anamnesis, 'NIT_SVAn_903')) {
            case 'NIT_SVAn_903_00':
                sum += 2;
                break;
            case 'NIT_SVAn_903_01':
                sum += 1;
                break;
            default:
            case 'NIT_SVAn_903_02':
                break;
        }

        const bmiValue = QuestionnaireResponse.GetResponseItemValueByLinkId(anamnesis, 'NIT_SVAn_802');
        const bmi: number = typeof bmiValue === "number" ? <number>bmiValue : Number(String(bmiValue));
        if (!isNaN(bmi)) {
            if (bmi >= 23) sum += 3;
            else if (bmi < 23 && bmi >= 21) sum += 2;
            else if (bmi >= 19 && bmi < 21) sum += 1;
        } else {
            sum = NaN;
        }

        if (!isNaN(sum)) {
            result.sum = sum;

            if (sum <= 7) {
                result.text = this.i18n.tr('sempa_risk_malnutrition');
                result.isRisk = true;
                result.color = "orange";
            } else if (sum >= 8 && sum <= 11) {
                result.text = this.i18n.tr('sempa_malnutrition_is_risk');
                result.isRisk = true;
                result.color = "red";
            } else if (sum >= 12) {
                result.text = this.i18n.tr('sempa_malnutrition_normal');
                result.isRisk = false;
            }

            result.text = `${result.text}`;
        }

        return result;
    }

    /**
     * Fill the MNA-Response with mapped values from the Assessment
     * @param patient
     * @param mnaResponse
     * @param assessment
     * @param anamnesis
     * @param mapResponseFields
     * @private
     */
    private mapMNAResponseScreeningGroup(patient: PatientItem, mnaResponse: any, assessment: any,
        anamnesis: any, mapResponseFields: boolean = true): { sum: number, text: string } {
        let sum = 0;

        const updateFields = mapResponseFields && (mnaResponse && mnaResponse.status === 'in-progress');

        //#region pre-get the Assessment and Anamnesis fields
        const NIT_SVAn_02 = Fhir.QuestionnaireResponse.GetResponseItemValueByLinkId(anamnesis, 'NIT_SVAn_02', undefined);
        const NIT_SVAn_804 = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(anamnesis, 'NIT_SVAn_804', undefined);
        const NIT_SVAn_803 = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(anamnesis, 'NIT_SVAn_803', undefined);
        const NIT_SVAn_900 = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(anamnesis, 'NIT_SVAn_900', undefined);
        const NIT_SVAn_903 = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(anamnesis, 'NIT_SVAn_903', undefined);
        const bmiValue = parseFloat(Fhir.QuestionnaireResponse.GetResponseItemValueByLinkId(anamnesis, 'NIT_SVAn_802', undefined));
        //#endregion

        //#region pre-get the MNA-Response-Fields
        const NIT_MNA_100 = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(mnaResponse, 'NIT_MNA_100', true);
        const NIT_MNA_101 = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(mnaResponse, 'NIT_MNA_101', true);
        const NIT_MNA_102 = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(mnaResponse, 'NIT_MNA_102', true);
        const NIT_MNA_103 = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(mnaResponse, 'NIT_MNA_103', true);
        const NIT_MNA_104 = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(mnaResponse, 'NIT_MNA_104', true);
        const NIT_MNA_105 = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(mnaResponse, 'NIT_MNA_105', true);
        const NIT_MNA_106 = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(mnaResponse, 'NIT_MNA_106', true);
        //#endregion

        //#region without display, just using the value of NIT_SVAn_02, "PatientIn ist ..."
        /*  Has been set twice? see planio #2315
         switch (NIT_SVAn_02) {
            case 'NIT_SVAn_02_00':
                sum += 2;
                break;
            case 'NIT_SVAn_02_01':
                sum += 1;
                break;
            case 'NIT_SVAn_02_02':
            default:
                break;
        } */
        //#endregion

        //#region from NIT_SVAn_803 -> NIT_MNA_100, "Hat PatientIn während der letzten 3 Monate wegen Appetitverlust..."
        if (NIT_SVAn_803 && NIT_SVAn_803.answer && NIT_SVAn_803.answer[0] && NIT_SVAn_803.answer[0].valueCoding && NIT_SVAn_803.answer[0].valueCoding.code) {
            let val = 0;
            switch (NIT_SVAn_803.answer[0].valueCoding.code) {
                case 'NIT_SVAn_803_00':
                    val = 2;
                    break;
                case 'NIT_SVAn_803_01':
                    val = 1;
                    break;
                default:
                    break;
            }

            sum += val;

            if (updateFields && NIT_MNA_100 && NIT_SVAn_803 && NIT_SVAn_803.answer[0].valueCoding) {
                NIT_MNA_100.answer = [{
                    // valueInteger: valNIT_SVAn_803
                    valueString: NIT_SVAn_803.answer[0].valueCoding.display || NIT_SVAn_803.answer[0].valueCoding.code,
                    extension: [{ url: SystemHeaders.vendorBase + 'ordinal-value', valueInteger: val }]
                }];
            }
        }
        //#endregion

        //#region from NIT_SVAn_804 -> NIT_MNA_101, "Gewichtsverlust in den letzten 3 Monaten"
        if (NIT_SVAn_804 && NIT_SVAn_804.answer && NIT_SVAn_804.answer[0] && NIT_SVAn_804.answer[0].valueCoding && NIT_SVAn_804.answer[0].valueCoding.code) {
            let val: number = 0;
            switch (NIT_SVAn_804.answer[0].valueCoding.code) {
                case 'NIT_SVAn_804_00':
                    val = 3;
                    break;
                case 'NIT_SVAn_804_01':
                    val = 2;
                    break;
                case 'NIT_SVAn_804_03':
                    val = 1;
                    break;
                case 'NIT_SVAn_804_02':
                default:
                    // 0
                    break;
            }

            sum += val;
            if (updateFields && NIT_MNA_101) {
                NIT_MNA_101.answer = [
                    {
                        valueString: NIT_SVAn_804.answer[0].valueCoding.display || NIT_SVAn_804.answer[0].valueCoding.code,
                        extension: [{ url: SystemHeaders.vendorBase + 'ordinal-value', valueInteger: val }]
                    }
                ];
            }
        }
        //#endregion

        //#region from NIT_SVAn_02 -> NIT_MNA_102 (new, PlanIO #2295)
        const responseItem_NIT_SVAn_02 = QuestionnaireResponse.GetResponseItemByLinkId(anamnesis, 'NIT_SVAn_02');
        if (NIT_SVAn_02) {
            let val = -1;
            let txt = QuestionnaireResponse.GetResponseItemDisplay(responseItem_NIT_SVAn_02);
            switch (NIT_SVAn_02) {
                case "NIT_SVAn_02_00":
                    val = 2;
                    break;
                case "NIT_SVAn_02_01":
                    val = 1;
                    break;
                case "NIT_SVAn_02_02":
                    val = 0;
                    break;
            }

            if (updateFields && NIT_MNA_102) {
                NIT_MNA_102.answer = [{
                    valueString: this.i18n.tr(txt),
                    extension: [{ url: SystemHeaders.vendorBase + 'ordinal-value', valueInteger: val }]
                }];
            }

            if (val > 0) {
                sum += val;
            }
        }
        //#endregion

        //#region from NIT_SVAn_900 -> NIT_MNA_103, "Akute Krankheit oder psychischer Stress.."
        if (NIT_SVAn_900 && NIT_SVAn_900.answer && NIT_SVAn_900.answer[0] && NIT_SVAn_900.answer[0].valueCoding && NIT_SVAn_900.answer[0].valueCoding.code) {
            let val = 0;
            switch (NIT_SVAn_900.answer[0].valueCoding.code) {
                case 'NIT_SVAn_900_00':
                    val = 2;
                    break;
                case 'NIT_SVAn_900_01':
                    // 0
                    break;
            }

            sum += val;

            if (updateFields && NIT_MNA_103) {
                NIT_MNA_103.answer = [
                    {
                        valueString: NIT_SVAn_900.answer[0].valueCoding.display || NIT_SVAn_900.answer[0].valueCoding.code,
                        extension: [{ url: SystemHeaders.vendorBase + 'ordinal-value', valueInteger: val }]
                    }
                ];
            }
        }
        //#endregion

        //#region from NIT_SVAn_903 -> NIT_MNA_104, "Neuro-psychologische Probleme"
        if (NIT_SVAn_903 && NIT_SVAn_903.answer && NIT_SVAn_903.answer[0] && NIT_SVAn_903.answer[0].valueCoding && NIT_SVAn_903.answer[0].valueCoding.code) {
            switch (NIT_SVAn_903.answer[0].valueCoding.code) {
                case 'NIT_SVAn_903_00':
                    sum += 2;
                    break;
                case 'NIT_SVAn_903_01':
                    sum += 1;
                    break;
                case 'NIT_SVAn_903_02':
                    sum += 0;
                    break;
            }

            if (updateFields && NIT_MNA_104) {
                NIT_MNA_104.answer = [{ valueString: NIT_SVAn_903.answer[0].valueCoding.display || NIT_SVAn_903.answer[0].valueCoding.code }];
            }
        }
        //#endregion

        //#region from NIT_SVAn_802 -> NIT_MNA_105, "Body Mass index (BMI) Score"
        if (!isNaN(bmiValue)) {
            let bmiScore = 0;
            let txt = '?';
            if (bmiValue > 23) {
                bmiScore = 3;
                txt = '≥ 23';
            } else if (bmiValue >= 21 && bmiValue <= 23) {
                bmiScore = 2;
                txt = '21 - 22.9';
            } else if (bmiValue >= 19 && bmiValue < 21) {
                bmiScore = 1;
                txt = '19 - 20.9';
            } else {
                bmiScore = 0;
                txt = '< 19';
            }

            sum += bmiScore;

            if (updateFields && NIT_MNA_105) {
                NIT_MNA_105.answer = [
                    {
                        valueDecimal: bmiValue,
                        extension: [
                            { url: SystemHeaders.vendorBase + 'score-value', valueInteger: bmiScore },
                            { url: SystemHeaders.vendorBase + 'text-value', valueString: txt }
                        ]
                    }];
            }
        }
        //#endregion

        //#region resulting score -> NIT_MNA_106
        if (NIT_MNA_106) {
            NIT_MNA_106.answer = [{
                valueInteger: sum
            }];
        }
        //#endregion

        const result = { sum: sum, text: '' };

        if (result.sum <= 7)
            result.text = this.i18n.tr('sempa_risk_malnutrition');
        else if (result.sum >= 8 && result.sum <= 11)
            result.text = this.i18n.tr('sempa_malnutrition_is_risk');
        else if (result.sum >= 12)
            result.text = this.i18n.tr('sempa_malnutrition_normal');

        return result;
    }

    private async calcMNA(patient: PatientItem, assessment: any, anamnesis: any, sumMNA: number) {
        // store to the mna questionnaire
        const mnaSettings = ConfigService.GetFormSettings('CareIT_MNA', patient);
        if (!mnaSettings) {
            throw `No Config-Setting for route 'CareIT_MNA' found. Aborting!`;
        }

        const mnaQuestionnaire = QuestionnaireService.GetQuestionnaireByNameDirect(mnaSettings.questionnaireName);
        let mnaResponse;
        try {
            if (mnaQuestionnaire) {
                //#region get the latest anamnesis if none provided
                if (!anamnesis) {
                    const anamnesisSetting = ConfigService.GetFormSettings('anamnesis', patient);
                    if (anamnesisSetting?.questionnaireName) {
                        const questionnaireAnamanesis = QuestionnaireService.GetQuestionnaireByNameDirect(anamnesisSetting?.questionnaireName);
                        if (questionnaireAnamanesis?.id) {
                            anamnesis = QuestionnaireService.GetLatestResponseOfType(patient, questionnaireAnamanesis?.id, [QuestionnaireResponseStatus.amended, QuestionnaireResponseStatus.completed]);
                        }
                    }
                }
                //#endregion

                // get the mna response linked in the anamnesis
                const mnaLinkExtension = anamnesis?.extension?.find(o => o.url.endsWith(`questionnaireLink/${mnaQuestionnaire.name}`));
                if (mnaLinkExtension?.valueReference?.reference) {
                    mnaResponse = await Fhir.Rest.Get(mnaLinkExtension.valueReference.reference);
                } else {
                    throw `No MNA-Response found. Have you set in the form-settings->anamnesis the property autoGenerateNewResponses[] correctly and included the "${mnaQuestionnaire.name}" Questionnaire?`;
                }
            } else {
                throw `No Questionnaire with name "${mnaSettings.questionnaireName}" found!`;
            }
        } catch (e) {
            console.warn(e);

            return e;
        }

        if (mnaQuestionnaire && anamnesis) {
            if (!mnaResponse) {
                mnaResponse = Fhir.Tools.SubstituteDefaultQRSkeleton(patient, mnaQuestionnaire.id, 'in-progress');
                QuestionnaireResponse.LinkResponsesMutual(patient, anamnesis, mnaResponse);

                if (patient.latestAssessment && patient.latestAssessment.id == assessment.id)
                    patient.latestAssessment = assessment;

                if (ConfigService.Debug && !ConfigService.IsTest)
                    console.debug('Creating MNA Response');
            }

            if (mnaResponse.status === 'in-progress') {
                if (!mnaResponse.questionnaire) {
                    if (FhirService.FhirVersion >= 4) {
                        mnaResponse.questionnaire = `${QuestionnaireService.NitQuestionnairesUrl}/${mnaQuestionnaire.name}`;
                    } else {
                        mnaResponse.questionnaire = {
                            reference: 'Questionnaire/' + mnaQuestionnaire.id
                        };
                    }
                }

                const oldResponse = JSON.stringify(mnaResponse.item);
                Fhir.Questionnaire.EnsureStructuredResponse(mnaQuestionnaire, mnaResponse);
                QuestionnaireResponse.SetDefaultValues(mnaResponse);
                QuestionnaireResponse.EnsureStructuralHullIntegrity(mnaQuestionnaire, mnaResponse);

                this.mapMNAResponseScreeningGroup(patient, mnaResponse, assessment, anamnesis);

                let sumMNA = 0;
                if (QuestionnaireResponse.GetResponseItemValueByLinkId(mnaResponse, 'NIT_MNA_00') === 'NIT_MNA_00_00') {
                    sumMNA += 1;
                }

                if (QuestionnaireResponse.GetResponseItemValueByLinkId(mnaResponse, 'NIT_MNA_01') === 'NIT_MNA_01_00') {
                    sumMNA += 1;
                }

                if (QuestionnaireResponse.GetResponseItemValueByLinkId(mnaResponse, 'NIT_MNA_02') === 'NIT_MNA_02_00') {
                    sumMNA += 1;
                }

                if (QuestionnaireResponse.GetResponseItemValueByLinkId(mnaResponse, 'NIT_MNA_03') === 'NIT_MNA_03_01')
                    sumMNA += 1;
                else if (QuestionnaireResponse.GetResponseItemValueByLinkId(mnaResponse, 'NIT_MNA_03') === 'NIT_MNA_03_02')
                    sumMNA += 2;

                sumMNA += this.addSum(sumMNA, QuestionnaireResponse.GetResponseItemValueByLinkId(mnaResponse, 'NIT_MNA_08', 0));

                if (QuestionnaireResponse.GetResponseItemValueByLinkId(mnaResponse, 'NIT_MNA_09') === 'NIT_MNA_09_00') {
                    sumMNA += 1;
                }

                const mna10 = QuestionnaireResponse.GetResponseItemValueByLinkId(mnaResponse, 'NIT_MNA_10');
                switch (mna10) {
                    default:
                    case 'NIT_MNA_10_00':
                        break;
                    case 'NIT_MNA_10_01':
                        sumMNA += 0.5;
                        break;
                    case 'NIT_MNA_10_02':
                        sumMNA += 1;
                        break;
                }

                const mna11 = QuestionnaireResponse.GetResponseItemValueByLinkId(mnaResponse, 'NIT_MNA_11');
                switch (mna11) {
                    default:
                    case 'NIT_MNA_11_00':
                        break;
                    case 'NIT_MNA_11_01':
                        sumMNA += 1;
                        break;
                    case 'NIT_MNA_10_02':
                        sumMNA += 2;
                        break;
                }

                const mna12 = QuestionnaireResponse.GetResponseItemValueByLinkId(mnaResponse, 'NIT_MNA_12');
                switch (mna12) {
                    default:
                    case 'NIT_MNA_12_00':
                        break;
                    case 'NIT_MNA_12_01':
                        sumMNA += 1.0;
                        break;
                    case 'NIT_MNA_12_02':
                        sumMNA += 2;
                        break;
                }

                const mna13 = QuestionnaireResponse.GetResponseItemValueByLinkId(mnaResponse, 'NIT_MNA_13');
                switch (mna13) {
                    default:
                    case 'NIT_MNA_10_00':
                        break;
                    case 'NIT_MNA_13_01':
                        sumMNA += 0.5;
                        break;
                    case 'NIT_MNA_10_02':
                        sumMNA += 1;
                        break;
                    case 'NIT_MNA_13_03':
                        sumMNA += 2;
                        break;
                }

                const mna14 = QuestionnaireResponse.GetResponseItemValueByLinkId(mnaResponse, 'NIT_MNA_14');
                switch (mna14) {
                    default:
                    case 'NIT_MNA_14_00':
                        break;
                    case 'NIT_MNA_14_01':
                        sumMNA += 0.5;
                        break;
                    case 'NIT_MNA_14_02':
                        sumMNA += 1;
                        break;
                }

                if (QuestionnaireResponse.GetResponseItemValueByLinkId(mnaResponse, 'NIT_MNA_15') === 'NIT_MNA_15_01')
                    sumMNA += 1;

                const NIT_MNA_16 = QuestionnaireResponse.GetResponseItemByLinkId(mnaResponse, 'NIT_MNA_16', true);
                if (NIT_MNA_16)
                    NIT_MNA_16.answer = [{ valueDecimal: sumMNA }];

                const NIT_MNA_200 = QuestionnaireResponse.GetResponseItemByLinkId(mnaResponse, 'NIT_MNA_200', true);
                const sumMNA200 = parseFloat(String(sumMNA == -1 ? 0 : sumMNA)) + parseFloat(String(sumMNA));

                const qMNA_200 = Fhir.Questionnaire.GetQuestionnaireItemByLinkId(mnaQuestionnaire, 'NIT_MNA_200');
                if (qMNA_200 && NIT_MNA_200) {
                    if (!isNaN(sumMNA)) {
                        if (qMNA_200.type === 'integer')
                            NIT_MNA_200.answer = [{ valueInteger: sumMNA200 }];
                        else if (qMNA_200.type === 'decimal')
                            NIT_MNA_200.answer = [{ valueDecimal: sumMNA200 }];
                        else if (qMNA_200.type === 'string' || qMNA_200.type === 'text')
                            NIT_MNA_200.answer = [{ valueString: String(sumMNA200) }];
                    } else {
                        NIT_MNA_200.answer = [];
                    }
                }

                const NIT_MNA_201 = QuestionnaireResponse.GetResponseItemByLinkId(mnaResponse, 'NIT_MNA_201', true);
                let outputText = '';

                if (sumMNA > -1) {
                    if (sumMNA200 >= 24) outputText = `${this.i18n.tr("sempa_default_nutrition")}`;
                    else if (sumMNA200 >= 17 && sumMNA200 <= 23.5) outputText = `${this.i18n.tr("sempa_malnutrition_is_risk")}`;
                    else if (sumMNA200 <= 17) outputText = `${this.i18n.tr("sempa_risk_malnutrition")}`;
                } else {
                    outputText = ' - ';
                }

                if (NIT_MNA_201)
                    NIT_MNA_201.answer = [{ valueString: outputText }];

                if (oldResponse != JSON.stringify(mnaResponse.item)) {
                    mnaResponse.questionnaire = FhirService.FhirVersion >= 4 ? `${QuestionnaireService.NitQuestionnairesUrl}/${mnaQuestionnaire.name}` : { reference: `Questionnaire/${mnaQuestionnaire.id}` };
                    await this.fhirService.update(mnaResponse);

                    PatientService.AddQuestionnaireResponse(patient, mnaResponse, true);
                }

                this.mapMNAResponseScreeningGroup(patient, mnaResponse, assessment, anamnesis);

                return outputText;
            } else {
                return QuestionnaireResponse.GetResponseItemValueByLinkId(mnaResponse, 'NIT_MNA_201', '');
            }
        }
    }

    /***
     * calculate the Demmi / Mobility Index
     * @param patient the patient to get the mobility index for
     * @param assessment the assessment to use
     */
    public calcMobility(patient: PatientItem, assessment: any) {
        // always reset to default values
        this.mobility.text = this.i18n.tr("n_a");
        this.mobility.color = undefined;
        this.mobility.value = -1;

        // do some checking
        if (!assessment && patient.latestAssessment) assessment = patient.latestAssessment;
        if (!assessment) return;

        // Assessment -> Bewegung -> Mobilität (NIT_SVAs_100) is the only item to be recognized for this
        switch (QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_100')) {
            case 'NIT_SVAs_100_00': // Selbstständig
                this.mobility.text = this.i18n.tr('mobility_independent');
                this.mobility.value = 0;
                break;
            case 'NIT_SVAs_100_01': // Überwiegend selbstständig
                this.mobility.text = this.i18n.tr('sempa_mobility_mostly_independent');
                this.mobility.value = 1;
                break;
            case 'NIT_SVAs_100_02': // Überwiegend unselbstständig
                this.mobility.text = this.i18n.tr('sempa_mobility_mostly_dependent');
                this.mobility.value = 2;
                this.mobility.color = "orange";
                break;
            case 'NIT_SVAs_100_03': // Unselbstständig
                this.mobility.text = this.i18n.tr('sempa_mobility_dependent');
                this.mobility.value = 3;
                this.mobility.color = "red";
                break;
            default:
                this.mobility.text = this.i18n.tr('not_predictable');
                this.mobility.value = -1;
                break;
        }

        return this.mobility;
    }

    /**
     * NCS-A
     * @param i18n
     * @param patient
     * @param assessment
     */
    public calculateNCSA(patient: PatientItem, assessment?: any): INcsRow {
        if (!patient) return;
        if (!assessment) assessment = patient.latestAssessment;
        if (!assessment) return;
        let sum = 0;

        //#region NIT_SVAs_300 und NIT_SVAs_301
        let valNIT_SVAs_300 = 0;
        const NIT_SVAs_300 = Fhir.QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_300', undefined);
        switch (NIT_SVAs_300) {
            case 'NIT_SVAs_300_01':
                valNIT_SVAs_300 = 1;
                break;
            case 'NIT_SVAs_300_02':
                valNIT_SVAs_300 = 2;
                break;
            case 'NIT_SVAs_300_03':
                valNIT_SVAs_300 = 3;
                break;
        }

        const NIT_SVAs_301 = Fhir.QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_301', undefined);
        let valNIT_SVAs_301 = 0;
        switch (NIT_SVAs_301) {
            case 'NIT_SVAs_301_01':
                valNIT_SVAs_301 = 1;
                break;
            case 'NIT_SVAs_301_02':
                valNIT_SVAs_301 = 2;
                break;
            case 'NIT_SVAs_301_03':
                valNIT_SVAs_301 = 3;
                break;
        }
        //#endregion
        sum += Math.max(valNIT_SVAs_300, valNIT_SVAs_301);

        //#region NIT_SVAs_100
        const NIT_SVAs_100 = Fhir.QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_100', undefined);
        let sumNIT_SVAs_100 = 0;
        switch (NIT_SVAs_100) {
            case 'NIT_SVAs_100_00':
                sumNIT_SVAs_100 = 0;
                break;
            case 'NIT_SVAs_100_01':
                sumNIT_SVAs_100 = 1;
                break;
            case 'NIT_SVAs_100_02':
                sumNIT_SVAs_100 = 2;
                break;
            case 'NIT_SVAs_100_03':
                sumNIT_SVAs_100 = 3;
                break;
        }
        //#endregion
        sum += sumNIT_SVAs_100;

        //#region NIT_SVAs_600 und NIT_SVAs_601
        const NIT_SVAs_600 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_600', undefined);
        const NIT_SVAs_601 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_601', undefined);

        // An- und Auskleiden Oberkörper (600)
        let sumNIT_SVAs_600 = 0;
        switch (NIT_SVAs_600) {
            case 'NIT_SVAs_600_01':
                sumNIT_SVAs_600 = 1;
                break;
            case 'NIT_SVAs_600_02':
                // sumNIT_SVAs_600 = 3;
                sumNIT_SVAs_600 = 2;
                break;
            case 'NIT_SVAs_600_03':
                // sumNIT_SVAs_600 = 5;
                sumNIT_SVAs_600 = 3;
                break;
        }

        // An- und Auskleiden Unterkörper (601)
        let sumNIT_SVAs_601 = 0;
        switch (NIT_SVAs_601) {
            case 'NIT_SVAs_601_01':
                sumNIT_SVAs_601 = 1;
                break;
            case 'NIT_SVAs_601_02':
                // sumNIT_SVAs_601 = 3;
                sumNIT_SVAs_601 = 2;
                break;
            case 'NIT_SVAs_601_03':
                // sumNIT_SVAs_601 = 5;
                sumNIT_SVAs_601 = 3;
                break;
        }
        //#endregion
        sum += Math.max(sumNIT_SVAs_600, sumNIT_SVAs_601);

        //#region NIT_SVAs_202
        const NIT_SVAs_202 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_202', undefined);
        let sumNIT_SVAs_202 = 0;
        switch (NIT_SVAs_202) {
            case 'NIT_SVAs_202_01':
            case 'NIT_SVAs_202_02':
            case 'NIT_SVAs_202_03':
                sumNIT_SVAs_202 = 1;
                break;
            case 'NIT_SVAs_202_04':
            case 'NIT_SVAs_202_05':
            case 'NIT_SVAs_202_06':
                sumNIT_SVAs_202 = 2;
                break;
            case 'NIT_SVAs_202_07':
            case 'NIT_SVAs_202_08':
            case 'NIT_SVAs_202_09':
            case 'NIT_SVAs_202_10':
                sumNIT_SVAs_202 = 3;
                break;
        }
        //#endregion
        sum += sumNIT_SVAs_202;

        //#region NIT_SVAs_400 und NIT_SVAs_403 und NIT_SVAs_405
        const NIT_SVAs_400 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_400', undefined);
        const NIT_SVAs_403 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_403', undefined);
        const NIT_SVAs_405 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_405', undefined);

        let sumNIT_SVAs_400 = 0;
        switch (NIT_SVAs_400) {
            case 'sumNIT_SVAs_401':
                sumNIT_SVAs_400 = 1;
                break;
            case 'sumNIT_SVAs_402':
                sumNIT_SVAs_400 = 2;
                break;
            case 'sumNIT_SVAs_403':
                sumNIT_SVAs_400 = 3;
                break;
        }

        let sumNIT_SVAs_403 = 0;
        switch (NIT_SVAs_403) {
            case 'NIT_SVAs_403_01':
                sumNIT_SVAs_403 = 1;
                break;
            case 'NIT_SVAs_403_02':
                sumNIT_SVAs_403 = 2;
                break;
            case 'NIT_SVAs_403_03':
                sumNIT_SVAs_403 = 3;
                break;
        }

        let sumNIT_SVAs_405 = 0;
        switch (NIT_SVAs_405) {
            case 'NIT_SVAs_405_01':
                sumNIT_SVAs_405 = 1;
                break;
            case 'NIT_SVAs_405_02':
                sumNIT_SVAs_405 = 2;
                break;
            case 'NIT_SVAs_405_03':
                sumNIT_SVAs_405 = 3;
                break;
        }
        //#endregion
        sum += Math.max(sumNIT_SVAs_400, sumNIT_SVAs_403, sumNIT_SVAs_405);

        //#region NIT_SVAs_500 und NIT_SVAs_503 und NIT_SVAs_506
        const NIT_SVAs_500 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_500', undefined);
        const NIT_SVAs_503 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_503', undefined);
        const NIT_SVAs_506 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_506', undefined);

        let sumNIT_SVAs_500 = 0;
        switch (NIT_SVAs_500) {
            case 'NIT_SVAs_500_01':
                sumNIT_SVAs_500 = 1;
                break;
            case 'NIT_SVAs_500_02':
                sumNIT_SVAs_500 = 2;
                break;
            case 'NIT_SVAs_500_03':
                sumNIT_SVAs_500 = 3;
                break;
        }

        let sumNIT_SVAs_503 = 0;
        switch (NIT_SVAs_503) {
            case 'NIT_SVAs_503_01':
                sumNIT_SVAs_503 = 1;
                break;
            case 'NIT_SVAs_503_02':
                sumNIT_SVAs_503 = 2;
                break;
            case 'NIT_SVAs_503_03':
                sumNIT_SVAs_503 = 3;
                break;
        }

        let sumNIT_SVAs_506 = 0;
        switch (NIT_SVAs_506) {
            case 'NIT_SVAs_506_01':
                sumNIT_SVAs_506 = 1;
                break;
            case 'NIT_SVAs_506_02':
                sumNIT_SVAs_506 = 2;
                break;
            case 'NIT_SVAs_506_03':
                sumNIT_SVAs_506 = 3;
                break;
        }
        //#endregion
        sum += Math.max(sumNIT_SVAs_500, sumNIT_SVAs_503, sumNIT_SVAs_506);

        //#region NIT_SVAs_201
        const NIT_SVAs_201 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_201', undefined);
        let sumNIT_SVAs_201 = 0;
        switch (NIT_SVAs_201) {
            case 'NIT_SVAs_201_01':
                sumNIT_SVAs_201 = 1;
                break;
            case 'NIT_SVAs_201_02':
                sumNIT_SVAs_201 = 2;
                break;
            case 'NIT_SVAs_201_03':
                sumNIT_SVAs_201 = 3;
                break;
        }
        //#endregion
        sum += sumNIT_SVAs_201;

        //#region NIT_SVAs_700 und NIT_SVAs_702 und NIT_SVAs_703
        const NIT_SVAs_700 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_700', undefined);
        const NIT_SVAs_702 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_702', undefined);
        const NIT_SVAs_703 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_703', undefined);

        let sumNIT_SVAs_700 = 0;
        switch (NIT_SVAs_700) {
            case 'NIT_SVAs_700_01':
                sumNIT_SVAs_700 = 1;
                break;
            case 'NIT_SVAs_700_02':
                sumNIT_SVAs_700 = 2;
                break;
            case 'NIT_SVAs_700_03':
                sumNIT_SVAs_700 = 3;
                break;
        }

        let sumNIT_SVAs_702 = 0;
        switch (NIT_SVAs_702) {
            case 'NIT_SVAs_702_01':
                sumNIT_SVAs_702 = 1;
                break;
            case 'NIT_SVAs_702_02':
                sumNIT_SVAs_702 = 2;
                break;
            case 'NIT_SVAs_702_03':
                sumNIT_SVAs_702 = 3;
                break;
        }

        let sumNIT_SVAs_703 = 0;
        switch (NIT_SVAs_703) {
            case 'NIT_SVAs_703_01':
                sumNIT_SVAs_703 = 1;
                break;
            case 'NIT_SVAs_703_02':
                sumNIT_SVAs_703 = 2;
                break;
            case 'NIT_SVAs_703_03':
                sumNIT_SVAs_703 = 3;
                break;
        }
        //#endregion
        sum += Math.max(sumNIT_SVAs_700, sumNIT_SVAs_702, sumNIT_SVAs_703);

        //#region NIT_SVAs_00 und NIT_SVAs_01 und NIT_SVAs_03
        const NIT_SVAs_00 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_00', undefined);
        const NIT_SVAs_01 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_01', undefined);
        const NIT_SVAs_03 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_03', undefined);

        let sumNIT_SVAs_00 = 0;
        switch (NIT_SVAs_00) {
            case 'NIT_SVAs_00_01':
                sumNIT_SVAs_00 = 1;
                break;
            case 'NIT_SVAs_00_02':
                sumNIT_SVAs_00 = 2;
                break;
            case 'NIT_SVAs_00_03':
                sumNIT_SVAs_00 = 3;
                break;
        }

        let sumNIT_SVAs_01 = 0;
        switch (NIT_SVAs_01) {
            case 'NIT_SVAs_01_01':
                sumNIT_SVAs_01 = 1;
                break;
            case 'NIT_SVAs_01_02':
                sumNIT_SVAs_01 = 2;
                break;
            case 'NIT_SVAs_01_03':
                sumNIT_SVAs_01 = 3;
                break;
        }

        let sumNIT_SVAs_03 = 0;
        switch (NIT_SVAs_03) {
            case 'NIT_SVAs_03_01':
                sumNIT_SVAs_03 = 1;
                break;
            case 'NIT_SVAs_03_02':
                sumNIT_SVAs_03 = 2;
                break;
            case 'NIT_SVAs_03_03':
                sumNIT_SVAs_03 = 3;
                break;
        }
        //endregion
        sum += Math.max(sumNIT_SVAs_00, sumNIT_SVAs_01, sumNIT_SVAs_03);

        //#region NIT_SVAs_902 und NIT_SVAs_1101 und NIT_SVAs_1203
        const NIT_SVAs_902 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_902', undefined);
        const NIT_SVAs_1101 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_1101', undefined);
        const NIT_SVAs_1203 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_1203', undefined);

        let sumNIT_SVAs_902 = 0;
        switch (NIT_SVAs_902) {
            case 'NIT_SVAs_902_01':
                sumNIT_SVAs_902 = 1;
                break;
            case 'NIT_SVAs_902_02':
                sumNIT_SVAs_902 = 2;
                break;
            case 'NIT_SVAs_902_03':
                sumNIT_SVAs_902 = 3;
                break;
        }

        let sumNIT_SVAs_1101 = 0;
        switch (NIT_SVAs_1101) {
            case 'NIT_SVAs_1101_01':
                sumNIT_SVAs_1101 = 1;
                break;
            case 'NIT_SVAs_1101_02':
                sumNIT_SVAs_1101 = 2;
                break;
            case 'NIT_SVAs_1101_03':
                sumNIT_SVAs_1101 = 3;
                break;
        }

        let sumNIT_SVAs_1203 = 0;
        switch (NIT_SVAs_1203) {
            case 'NIT_SVAs_1203_01':
                sumNIT_SVAs_1203 = 1;
                break;
            case 'NIT_SVAs_1203_02':
                sumNIT_SVAs_1203 = 2;
                break;
            case 'NIT_SVAs_1203_03':
                sumNIT_SVAs_1203 = 3;
                break;
        }
        //#endregion
        sum += Math.max(sumNIT_SVAs_902, sumNIT_SVAs_1101, sumNIT_SVAs_1203);

        const result = {
            analyse: '',
            overview: '',
            color: 'transparent',
            sum: sum,
            level: -1
        };

        if (sum === -1) {
            result.analyse = this.i18n.tr('n_a');
            result.overview = this.i18n.tr('n_a');
        } else if (sum === 0) {
            result.analyse = this.i18n.tr('sempa_ncsa_analysis_intensity_none');
            result.overview = this.i18n.tr('sempa_ncsa_overview_no_need');
            result.color = "#c9c9c9";
            result.level = 0;
        } else if (sum >= 1 && sum <= 7) {
            result.analyse = this.i18n.tr('sempa_ncsa_analysis_intensity_easy');
            result.overview = this.i18n.tr('sempa_ncsa_overview_easy');
            result.color = 'green';
            result.level = 1;
        } else if (sum >= 8 && sum <= 16) {
            result.analyse = this.i18n.tr('sempa_ncsa_analysis_intensity_increased');
            result.overview = this.i18n.tr('sempa_ncsa_overview_increased');
            result.color = 'rgb(150,150,0)';
            result.level = 2;
        } else if (sum >= 17 && sum <= 25) {
            result.analyse = this.i18n.tr('sempa_ncsa_analysis_intensity_high');
            result.overview = this.i18n.tr('sempa_ncsa_overview_high');
            result.color = 'rgb(223,112,0)';
            result.level = 3;
        } else if (sum >= 26) {
            result.analyse = this.i18n.tr('sempa_ncsa_analysis_intensity_very_high');
            result.overview = this.i18n.tr('sempa_ncsa_overview_very_high');
            result.color = 'rgb(240,0,0)';
            result.level = 4;
        }

        return result;
    }

    /**
     * NCS-P
     * @param patient
     * @param assessment
     */
    public calculateNCSP(patient: PatientItem, assessment?): INcsRow {
        let sum = 0;
        if (!assessment && patient && patient.latestAssessment) assessment = patient.latestAssessment;
        if (!assessment) return;

        //#region NIT_SVAs_11100
        const NIT_SVAs_11100 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_11100', undefined);
        let sumNIT_SVAs_11100 = 0;
        switch (NIT_SVAs_11100) {
            case 'NIT_SVAs_11100_01':
                sumNIT_SVAs_11100 = 1;
                break;
            case 'NIT_SVAs_11100_02':
                sumNIT_SVAs_11100 = 3;
                break;
            case 'NIT_SVAs_11100_03':
                sumNIT_SVAs_11100 = 5;
                break;
        }
        //#endregion
        sum = this.addSum(sum, sumNIT_SVAs_11100);

        //#region NIT_SVAs_11101
        const NIT_SVAs_11101 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_11101', undefined);
        let sumNIT_SVAs_11101 = 0;
        switch (NIT_SVAs_11101) {
            case 'NIT_SVAs_11101_01':
                sumNIT_SVAs_11101 = 1;
                break;
            case 'NIT_SVAs_11101_02':
                sumNIT_SVAs_11101 = 3;
                break;
            case 'NIT_SVAs_11101_03':
                sumNIT_SVAs_11101 = 5;
                break;
        }
        //#endregion
        sum = this.addSum(sum, sumNIT_SVAs_11101);

        //#region NIT_SVAs_11102
        const NIT_SVAs_11102 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_11102', undefined);
        let sumNIT_SVAs_11102 = 0;
        switch (NIT_SVAs_11102) {
            case 'NIT_SVAs_11102_01':
                sumNIT_SVAs_11102 = 1;
                break;
            case 'NIT_SVAs_11102_02':
                sumNIT_SVAs_11102 = 3;
                break;
            case 'NIT_SVAs_11102_03':
                sumNIT_SVAs_11102 = 5;
                break;
        }
        //#endregion
        sum = this.addSum(sum, sumNIT_SVAs_11102);

        //#region NIT_SVAs_11103
        const NIT_SVAs_11103 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_11103', undefined);
        let sumNIT_SVAs_11103 = 0;
        switch (NIT_SVAs_11103) {
            case 'NIT_SVAs_11103_01':
                sumNIT_SVAs_11103 = 1;
                break;
            case 'NIT_SVAs_11103_02':
                sumNIT_SVAs_11103 = 3;
                break;
            case 'NIT_SVAs_11103_03':
                sumNIT_SVAs_11103 = 5;
                break;
        }
        //#endregion
        sum = this.addSum(sum, sumNIT_SVAs_11103);

        //#region NIT_SVAs_11104
        const NIT_SVAs_11104 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_11104', undefined);
        let sumNIT_SVAs_11104 = 0;
        switch (NIT_SVAs_11104) {
            case 'NIT_SVAs_11104_01':
                sumNIT_SVAs_11104 = 1;
                break;
            case 'NIT_SVAs_11104_02':
                sumNIT_SVAs_11104 = 3;
                break;
            case 'NIT_SVAs_11104_03':
                sumNIT_SVAs_11104 = 5;
                break;
        }
        //#endregion
        sum = this.addSum(sum, sumNIT_SVAs_11104);

        //#region NIT_SVAs_11105
        const NIT_SVAs_11105 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_11105', undefined);
        let sumNIT_SVAs_11105 = 0;
        switch (NIT_SVAs_11105) {
            case 'NIT_SVAs_11105_01':
                sumNIT_SVAs_11105 = 1;
                break;
            case 'NIT_SVAs_11105_02':
                sumNIT_SVAs_11105 = 3;
                break;
            case 'NIT_SVAs_11105_03':
                sumNIT_SVAs_11105 = 5;
                break;
        }
        //#endregion
        sum = this.addSum(sum, sumNIT_SVAs_11105);

        //#region NIT_SVAs_11106
        const NIT_SVAs_11106 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_11106', undefined);
        let sumNIT_SVAs_11106 = 0;
        switch (NIT_SVAs_11106) {
            case 'NIT_SVAs_11106_01':
                sumNIT_SVAs_11106 = 1;
                break;
            case 'NIT_SVAs_11106_02':
                sumNIT_SVAs_11106 = 3;
                break;
            case 'NIT_SVAs_11106_03':
                sumNIT_SVAs_11106 = 5;
                break;
        }
        //#endregion
        sum = this.addSum(sum, sumNIT_SVAs_11106);

        //#region NIT_SVAs_11107
        const NIT_SVAs_11107 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_11107', undefined);
        let sumNIT_SVAs_11107 = 0;
        switch (NIT_SVAs_11107) {
            case 'NIT_SVAs_11107_01':
                sumNIT_SVAs_11107 = 1;
                break;
            case 'NIT_SVAs_11107_02':
                sumNIT_SVAs_11107 = 3;
                break;
            case 'NIT_SVAs_11107_03':
                sumNIT_SVAs_11107 = 5;
                break;
        }
        //#endregion
        sum = this.addSum(sum, sumNIT_SVAs_11107);

        //#region NIT_SVAs_11108
        const NIT_SVAs_11108 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_11108', undefined);
        let sumNIT_SVAs_11108 = 0;
        switch (NIT_SVAs_11108) {
            case 'NIT_SVAs_11108_01':
                sumNIT_SVAs_11108 = 1;
                break;
            case 'NIT_SVAs_11108_02':
                sumNIT_SVAs_11108 = 3;
                break;
            case 'NIT_SVAs_11108_03':
                sumNIT_SVAs_11108 = 5;
                break;
        }
        //#endregion
        sum = this.addSum(sum, sumNIT_SVAs_11108);

        //#region NIT_SVAs_11109
        const NIT_SVAs_11109 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_11109', undefined);
        let sumNIT_SVAs_11109 = 0;
        switch (NIT_SVAs_11109) {
            case 'NIT_SVAs_11109_01':
                sumNIT_SVAs_11109 = 1;
                break;
            case 'NIT_SVAs_11109_02':
                sumNIT_SVAs_11109 = 3;
                break;
            case 'NIT_SVAs_11109_03':
                sumNIT_SVAs_11109 = 5;
                break;
        }
        //#endregion
        sum = this.addSum(sum, sumNIT_SVAs_11109);

        //#region NIT_SVAs_11110
        const NIT_SVAs_11110 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_11110', undefined);
        let sumNIT_SVAs_11110 = 0;
        switch (NIT_SVAs_11110) {
            case 'NIT_SVAs_11110_01':
                sumNIT_SVAs_11110 = 1;
                break;
            case 'NIT_SVAs_11110_02':
                sumNIT_SVAs_11110 = 3;
                break;
            case 'NIT_SVAs_11110_03':
                sumNIT_SVAs_11110 = 5;
                break;
        }
        //#endregion
        sum = this.addSum(sum, sumNIT_SVAs_11110);

        //#region NIT_SVAs_11111
        const NIT_SVAs_11111 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_11111', undefined);
        let sumNIT_SVAs_11111 = 0;
        switch (NIT_SVAs_11111) {
            case 'NIT_SVAs_11111_01':
                sumNIT_SVAs_11111 = 1;
                break;
            case 'NIT_SVAs_11111_02':
                sumNIT_SVAs_11111 = 3;
                break;
            case 'NIT_SVAs_11111_03':
                sumNIT_SVAs_11111 = 5;
                break;
        }
        //#endregion
        sum = this.addSum(sum, sumNIT_SVAs_11111);

        //#region NIT_SVAs_11112
        const NIT_SVAs_11112 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_11112', undefined);
        let sumNIT_SVAs_11112 = 0;
        switch (NIT_SVAs_11112) {
            case 'NIT_SVAs_11112_01':
                sumNIT_SVAs_11112 = 1;
                break;
            case 'NIT_SVAs_11112_02':
                sumNIT_SVAs_11112 = 3;
                break;
            case 'NIT_SVAs_11112_03':
                sumNIT_SVAs_11112 = 5;
                break;
        }
        //#endregion
        sum = this.addSum(sum, sumNIT_SVAs_11112);

        const result: INcsRow = {
            sum: sum,
            text: this.i18n.tr('n_a'),
            color: 'black',
            overview: this.i18n.tr('n_a')
        };

        if (sum === 0) {
            result.text = this.i18n.tr('sempa_ncsp_analysis_intensity_none');
            result.overview = this.i18n.tr('sempa_ncsp_overview_intensity_none');
        } else if (sum >= 1 && sum <= 10) {
            result.text = this.i18n.tr('sempa_ncsp_analysis_intensity_easy');
            result.overview = this.i18n.tr('sempa_ncsp_overview_intensity_easy');
            result.color = 'green';
        } else if (sum >= 11 && sum <= 22) {
            result.text = this.i18n.tr('sempa_ncsp_analysis_intensity_increased');
            result.overview = this.i18n.tr('sempa_ncsp_overview_intensity_increased');
            result.color = 'rgb(150,150,0)';
        } else if (sum >= 23 && sum <= 35) {
            result.text = this.i18n.tr('sempa_ncsp_analysis_intensity_high');
            result.overview = this.i18n.tr('sempa_ncsp_overview_intensity_high');
            result.color = 'rgb(223,112,0)';
        } else if (sum >= 36 && sum <= 65) {
            result.text = this.i18n.tr('sempa_ncsp_analysis_intensity_very_high');
            result.overview = this.i18n.tr('sempa_ncsp_overview_intensity_very_high');
            result.color = 'rgb(240,0,0)';
        }

        return result;
    }

    /**
     * PAV
     */
    public async calculatePAV(patient: PatientItem): Promise<{ sum: number, text: string, color: string }> {
        await ConfigService.LoadConfigOverride(patient?.ward, patient);
        const cfg = ConfigService.GetFormSettings('anamnesis');
        if (cfg.questionnaireName) this.anamnesisName = cfg.questionnaireName;
        if (!this.anamnesisName) return undefined;

        const q = await QuestionnaireService.GetQuestionnaireByName(this.anamnesisName);
        if (!q) return;

        const anamnesis = QuestionnaireService.GetLatestResponseOfType(patient, q.id);
        let sum = 0;

        //#region NIT_PAV_100, Anzahl aktiver medizinischer Probleme bei Aufnahme
        const sumNIT_PAV_100 = this.fieldToIntValue(anamnesis, 'NIT_PAV_100', 0);
        sum += sumNIT_PAV_100;
        //#endregion

        //#region NIT_PAV_101, Lebt PatientIn mit jemandem im gleichen Haushalt
        const NIT_PAV_101 = QuestionnaireResponse.GetResponseItemValueByLinkId(anamnesis, 'NIT_PAV_101');
        sum += NIT_PAV_101 === "NIT_PAV_101_01" ? 4 : 0;
        //#endregion

        //#region NIT_PAV_102, Ist PatientIn zu Hause in allen Bereichen selbstständig
        const NIT_PAV_102 = QuestionnaireResponse.GetResponseItemValueByLinkId(anamnesis, 'NIT_PAV_102');
        if (NIT_PAV_102 != 'NIT_PAV_102_00') {
            // be aware that NIT_PAV_103 should be a multi select!
            const NIT_PAV_103 = QuestionnaireResponse.GetResponseItemByLinkId(anamnesis, 'NIT_PAV_103');
            if (NIT_PAV_103 && NIT_PAV_103.answer)
                sum += NIT_PAV_103.answer.length;
        }
        //#endregion

        //#region NIT_PAV_104, "Alter in Jahren"
        const NIT_PAV_104 = this.fieldToIntValue(anamnesis, 'NIT_PAV_104');
        sum += NIT_PAV_104;
        //#endregion

        const result = {
            text: '',
            sum: sum,
            color: undefined
        };

        if (sum < 8)
            result.text = this.i18n.tr('sempa_pav_low_risk');
        else if (sum >= 8 && sum <= 15) {
            result.text = this.i18n.tr('sempa_pav_increased_risk');
            result.color = "orange";
        } else if (sum > 15) {
            result.text = this.i18n.tr('sempa_pav_high_risk');
            result.color = "red";
        }

        return result;
    }

    /***
     * Calculates the result-fields of a demmi response (NIT_DEMMI_500 and NIT_DEMMI_501)
     * @param demmiResponse
     */
    public calculateDemmiResultFields(demmiResponse: any) {
        if (!demmiResponse || demmiResponse.status !== 'in-progress')
            return;

        const fields = ['NIT_DEMMI_402', 'NIT_DEMMI_401', 'NIT_DEMMI_400', 'NIT_DEMMI_301', 'NIT_DEMMI_300', 'NIT_DEMMI_203', 'NIT_DEMMI_202', 'NIT_DEMMI_201', 'NIT_DEMMI_200', 'NIT_DEMMI_102', 'NIT_DEMMI_101', 'NIT_DEMMI_100', 'NIT_DEMMI_02', 'NIT_DEMMI_01', 'NIT_DEMMI_00'];
        let calculateDemmiValuesNeeded = true;
        let sum = 0;

        // take a look, if the current demmi Questionnaire has a calculated field on 500..
        const demmiQuestionnaire = QuestionnaireService.GetQuestionnaireDirect(demmiResponse?.questionnaire);
        if (demmiQuestionnaire) {
            const item500 = Questionnaire.GetQuestionnaireItemByLinkId(demmiQuestionnaire, 'NIT_DEMMI_500');
            if (item500?.extension) {
                const calcExt = item500.extension.find(o => o.url.endsWith('/questionnaire-calculated-field'));
                if (calcExt?.valueString !== "") {
                    calculateDemmiValuesNeeded = false;
                    // check if all the fields we use for calculation have an ordinal value
                    for (const field in fields) {
                        const item = Questionnaire.GetQuestionnaireItemByLinkId(demmiQuestionnaire, field); // get the questionnaire item ..
                        if (item?.option) { // check the options of the item if any
                            for (const option of item.option) {
                                // check for ordinal value extension existence..
                                if (typeof option.extension.find(o => o.url.endsWith('questionnaire-ordinalValue')) === "undefined") {
                                    // .. no ordinal value extension has been found, so check if the option id ends with "_nil" (not having an ordinalValue on that is ok)
                                    if (option.valueCoding?.code && !option.valueCoding?.code.toUpperCase().endsWith('_NIL')) {
                                        if (ConfigService.Debug)
                                            console.debug(`Field "${field}" does not have a valid ordinal value for option "${item.linkId}". Using default processing of demmi`);
                                    } else {
                                        // .. nope, this option does not end with _nil, so the questionnaire is invalid for automatic calcing
                                        calculateDemmiValuesNeeded = true;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        if (ConfigService.Debug && calculateDemmiValuesNeeded) {
            if (!AnalyzerSEMPA.demmiWarningDisplayed)
                console.warn("Demmi Questionnaire does not use correct ordinal values in the options! Please consider updating the Questionnaire on the server!");

            AnalyzerSEMPA.demmiWarningDisplayed = true;
        }

        const getOrdVal = (itemLinkId) => {
            const responseValue = QuestionnaireResponse.GetResponseItemValueByLinkId(demmiResponse, itemLinkId, `${itemLinkId}_NIL`);
            if (!responseValue)
                return 0;

            const item = Questionnaire.GetQuestionnaireItemByLinkId(demmiQuestionnaire, itemLinkId);
            return QuestionnaireResponse.GetOrdinalValue(responseValue, item, 0);
        };

        if (!calculateDemmiValuesNeeded) {
            // new method - take the values from the ordinal values
            for (const field of fields) {
                sum += getOrdVal(field);
            }
        } else {
            //#region calculate the sum the old style ..
            const NIT_DEMMI_00 = QuestionnaireResponse.GetResponseItemValueByLinkId(demmiResponse, 'NIT_DEMMI_00', 0);
            const NIT_DEMMI_01 = QuestionnaireResponse.GetResponseItemValueByLinkId(demmiResponse, 'NIT_DEMMI_01', 0);
            const NIT_DEMMI_02 = QuestionnaireResponse.GetResponseItemValueByLinkId(demmiResponse, 'NIT_DEMMI_02', 0);
            const NIT_DEMMI_100 = QuestionnaireResponse.GetResponseItemValueByLinkId(demmiResponse, 'NIT_DEMMI_100', 0);
            const NIT_DEMMI_101 = QuestionnaireResponse.GetResponseItemValueByLinkId(demmiResponse, 'NIT_DEMMI_101', 0);
            const NIT_DEMMI_102 = QuestionnaireResponse.GetResponseItemValueByLinkId(demmiResponse, 'NIT_DEMMI_102', 0);
            const NIT_DEMMI_200 = QuestionnaireResponse.GetResponseItemValueByLinkId(demmiResponse, 'NIT_DEMMI_200', 0);
            const NIT_DEMMI_201 = QuestionnaireResponse.GetResponseItemValueByLinkId(demmiResponse, 'NIT_DEMMI_201', 0);
            const NIT_DEMMI_202 = QuestionnaireResponse.GetResponseItemValueByLinkId(demmiResponse, 'NIT_DEMMI_202', 0);
            const NIT_DEMMI_203 = QuestionnaireResponse.GetResponseItemValueByLinkId(demmiResponse, 'NIT_DEMMI_203', 0);
            const NIT_DEMMI_300 = QuestionnaireResponse.GetResponseItemValueByLinkId(demmiResponse, 'NIT_DEMMI_300', 0);
            const NIT_DEMMI_301 = QuestionnaireResponse.GetResponseItemValueByLinkId(demmiResponse, 'NIT_DEMMI_301', 0);
            const NIT_DEMMI_400 = QuestionnaireResponse.GetResponseItemValueByLinkId(demmiResponse, 'NIT_DEMMI_400', 0);
            const NIT_DEMMI_401 = QuestionnaireResponse.GetResponseItemValueByLinkId(demmiResponse, 'NIT_DEMMI_401', 0);
            const NIT_DEMMI_402 = QuestionnaireResponse.GetResponseItemValueByLinkId(demmiResponse, 'NIT_DEMMI_402', 0);

            switch (NIT_DEMMI_00) { // Brücke
                case 'NIT_DEMMI_00_00':
                    sum += 0;
                    break;
                case 'NIT_DEMMI_00_01':
                    sum += 1;
                    break;
            }

            switch (NIT_DEMMI_01) { // Auf die Seite rollen
                case 'NIT_DEMMI_01_00':
                    sum += 0;
                    break;
                case 'NIT_DEMMI_01_01':
                    sum += 1;
                    break;
            }

            switch (NIT_DEMMI_02) { // Vom Liegen zum Sitzen
                case 'NIT_DEMMI_02_00':
                    sum += 0;
                    break;
                case 'NIT_DEMMI_02_01':
                case 'NIT_DEMMI_02_02':
                    sum += 1;
                    break;
                case 'NIT_DEMMI_02_03':
                    sum += 2;
                    break;
            }

            switch (NIT_DEMMI_100) {  // Sitzen im Stuhl ohne Unterstützung
                case 'NIT_DEMMI_100_00':
                    sum += 0;
                    break;
                case 'NIT_DEMMI_100_01':
                    sum += 1;
                    break;
            }

            switch (NIT_DEMMI_101) { // Aus dem Stuhl aufstehen
                case 'NIT_DEMMI_101_00':
                    sum += 0;
                    break;
                case 'NIT_DEMMI_101_01':
                case 'NIT_DEMMI_101_02':
                    sum += 1;
                    break;
                case 'NIT_DEMMI_101_03':
                    sum += 2;
                    break;
            }

            switch (NIT_DEMMI_102) { // Aus dem Stuhl aufstehen, ohne die Arme zu Hilfe zu nehmen
                case 'NIT_DEMMI_102_00':
                    sum += 0;
                    break;
                case 'NIT_DEMMI_102_01':
                    sum += 1;
                    break;
            }

            switch (NIT_DEMMI_200) { // Ohne Unterstützung stehen
                case 'NIT_DEMMI_200_00':
                    sum += 0;
                    break;
                case 'NIT_DEMMI_200_01':
                    sum += 1;
                    break;
            }

            switch (NIT_DEMMI_201) { // Stehen mit geschlossenen Füßen
                case 'NIT_DEMMI_201_00':
                    sum += 0;
                    break;
                case 'NIT_DEMMI_201_01':
                    sum += 1;
                    break;
            }

            switch (NIT_DEMMI_202) { // Auf den Fußspitzen stehen
                case 'NIT_DEMMI_202_00':
                    sum += 0;
                    break;
                case 'NIT_DEMMI_202_01':
                    sum += 1;
                    break;
            }

            switch (NIT_DEMMI_203) { // Im Tandemstand mit geschlossenen Augen stehen
                case 'NIT_DEMMI_203_00':
                    sum += 0;
                    break;
                case 'NIT_DEMMI_203_01':
                    sum += 1;
                    break;
            }

            switch (NIT_DEMMI_300) { // Wegstrecke +/- Gehhilfe Gehhilfe (kennzeichnen):  keine / Gehbock / Stock / Rollator / andere
                case 'NIT_DEMMI_300_00':
                case 'NIT_DEMMI_300_01':
                    sum += 0;
                    break;
                case 'NIT_DEMMI_300_02':
                case 'NIT_DEMMI_300_03':
                    sum += 1;
                    break;
                case 'NIT_DEMMI_300_04':
                    sum += 2;
                    break;
            }

            switch (NIT_DEMMI_301) { // Selbständiges Gehen
                case 'NIT_DEMMI_301_00':
                case 'NIT_DEMMI_301_01':
                case 'NIT_DEMMI_301_02':
                    sum += 0;
                    break;
                case 'NIT_DEMMI_301_03':
                    sum += 1;
                    break;
                case 'NIT_DEMMI_301_04':
                    sum += 2;
                    break;
            }

            switch (NIT_DEMMI_400) { // Stift vom Boden aufheben
                case 'NIT_DEMMI_400_00':
                    sum += 0;
                    break;
                case 'NIT_DEMMI_400_01':
                    sum += 1;
                    break;
            }

            switch (NIT_DEMMI_401) { // vier Schritte rückwärts gehen
                case 'NIT_DEMMI_401_00':
                    sum += 0;
                    break;
                case 'NIT_DEMMI_401_01':
                    sum += 1;
                    break;
            }

            switch (NIT_DEMMI_402) { // Springen
                case 'NIT_DEMMI_402_00':
                    sum += 0;
                    break;
                case 'NIT_DEMMI_402_01':
                    sum += 1;
                    break;
            }
            //#endregion
        }

        //#region .. store sum to demmi_500 and get the result from given table:
        const demmi_500 = QuestionnaireResponse.GetResponseItemByLinkId(demmiResponse, 'NIT_DEMMI_500');
        if (demmi_500) {
            demmi_500.answer = [
                { valueString: `${sum} / 19` }
            ];
        }

        const demmiScoreTable = [
            /*00*/ 0, /*01*/ 8, /*02*/15, /*03*/20, /*04*/24, /*05*/27,
            /*06*/30, /*07*/33, /*08*/36, /*09*/39, /*10*/41, /*11*/44,
            /*12*/48, /*13*/53, /*14*/57, /*15*/62, /*16*/67, /*17*/74,
            /*18*/85, /*19*/100
        ];

        if (sum < 0) sum = 0;
        else if (sum > 19) sum = 19;

        let sum501 = demmiScoreTable[sum];
        const demmi_501 = QuestionnaireResponse.GetResponseItemByLinkId(demmiResponse, 'NIT_DEMMI_501');
        if (demmi_501) {
            const demmi501Result = `${sum501} / 100`;
            demmi_501.answer = [
                { valueString: demmi501Result }
            ];

            if (this.sempaResult.demmi) {
                this.sempaResult.demmi.text2 = demmi501Result;
            }
        }
        //#endregion
    }

    private async mapDemmiValues(patient: PatientItem, assessment?: any, updateOnFhir?: boolean): Promise<any> {
        let demmiNeedsUpdate = false;
        let assessmentNeedsUpdate = false;
        const demmiQuestionnaire = await QuestionnaireService.GetQuestionnaireByName(this.demmiName, false);
        if (!demmiQuestionnaire) console.warn(`Questionnaire "${this.demmiName}" not found!`);

        if (!patient || !assessment || !patient.latestAssessment || !demmiQuestionnaire) return;
        if (!assessment)
            assessment = patient.latestAssessment;

        let demmiResponse: any;
        try {
            demmiResponse = await Fhir.QuestionnaireResponse.GetAttachedResponse(patient, assessment, this.demmiName);
        } catch (e) {
            console.warn(e);
        }

        if (!demmiResponse) {
            demmiResponse = Fhir.Tools.SubstituteDefaultQRSkeleton(patient, demmiQuestionnaire.id, QuestionnaireResponseStatus.inProgress);
            // link between demmi <-> assessment
            Fhir.QuestionnaireResponse.SetAttachedResponse(patient, assessment, demmiResponse);
            Fhir.QuestionnaireResponse.SetAttachedResponse(patient, demmiResponse, assessment);

            PatientService.AddQuestionnaireResponse(patient, demmiResponse, true);
            PatientService.AddQuestionnaireResponse(patient, assessment, true);

            assessmentNeedsUpdate = true;
            demmiNeedsUpdate = true;
        }

        if (demmiResponse && demmiResponse.status !== 'in-progress') return undefined;

        const mappings: IDemmiMapping[] = [
            //#region NIT_SVAs_101
            {
                source: 'NIT_SVAs_101', mappings: [
                    { sourceValue: 'NIT_SVAs_101_00', targets: [{ field: 'NIT_DEMMI_300', value: 'NIT_DEMMI_300_04' }] },
                    { sourceValue: 'NIT_SVAs_101_01', targets: [{ field: 'NIT_DEMMI_300', value: 'NIT_DEMMI_300_03' }] },
                    { sourceValue: 'NIT_SVAs_101_02', targets: [{ field: 'NIT_DEMMI_300', value: 'NIT_DEMMI_300_02' }] },
                    { sourceValue: 'NIT_SVAs_101_03', targets: [{ field: 'NIT_DEMMI_300', value: 'NIT_DEMMI_300_00' }] }
                ]
            },
            //#endregion
            //#region NIT_SVAs_103
            {
                source: 'NIT_SVAs_103', mappings: [
                    {
                        sourceValue: 'NIT_SVAs_103_00', targets: [
                            { field: 'NIT_DEMMI_00', value: 'NIT_DEMMI_00_01' },
                            { field: 'NIT_DEMMI_01', value: 'NIT_DEMMI_01_01' },
                            { field: 'NIT_DEMMI_02', value: 'NIT_DEMMI_02_03' }
                        ]
                    },
                    {
                        sourceValue: 'NIT_SVAs_103_01', targets: [
                            { field: 'NIT_DEMMI_00', value: 'NIT_DEMMI_00_01' },
                            { field: 'NIT_DEMMI_01', value: 'NIT_DEMMI_01_01' },
                            { field: 'NIT_DEMMI_02', value: 'NIT_DEMMI_02_01' }
                        ]
                    },
                    {
                        sourceValue: 'NIT_SVAs_103_02', targets: [
                            { field: 'NIT_DEMMI_00', value: 'NIT_DEMMI_00_00' },
                            { field: 'NIT_DEMMI_01', value: 'NIT_DEMMI_01_00' },
                            { field: 'NIT_DEMMI_02', value: 'NIT_DEMMI_02_00' }
                        ]
                    },
                    {
                        sourceValue: 'NIT_SVAs_103_03', targets: [
                            { field: 'NIT_DEMMI_00', value: 'NIT_DEMMI_00_00' },
                            { field: 'NIT_DEMMI_01', value: 'NIT_DEMMI_01_00' },
                            { field: 'NIT_DEMMI_02', value: 'NIT_DEMMI_02_00' }
                        ]
                    }
                ]
            },
            //#endregion
            //#region NIT_SVAs_104
            {
                source: 'NIT_SVAs_104',
                mappings: [
                    { sourceValue: 'NIT_SVAs_104_00', targets: [{ field: 'NIT_DEMMI_100', value: 'NIT_DEMMI_100_01' }] },
                    { sourceValue: 'NIT_SVAs_104_01', targets: [{ field: 'NIT_DEMMI_100', value: 'NIT_DEMMI_100_01' }] },
                    { sourceValue: 'NIT_SVAs_104_02', targets: [{ field: 'NIT_DEMMI_100', value: 'NIT_DEMMI_100_00' }] },
                    { sourceValue: 'NIT_SVAs_104_03', targets: [{ field: 'NIT_DEMMI_100', value: 'NIT_DEMMI_100_00' }] }
                ]
            },
            //#endregion
            //#region NIT_SVAs_105
            {
                source: 'NIT_SVAs_105',
                mappings: [
                    {
                        sourceValue: 'NIT_SVAs_105_00', targets: [
                            { field: 'NIT_DEMMI_101', value: 'NIT_DEMMI_101_03' },
                            { field: 'NIT_DEMMI_102', value: 'NIT_DEMMI_102_01' }
                        ]
                    },
                    {
                        sourceValue: 'NIT_SVAs_105_01', targets: [
                            { field: 'NIT_DEMMI_101', value: 'NIT_DEMMI_101_01' },
                            { field: 'NIT_DEMMI_102', value: 'NIT_DEMMI_102_01' }
                        ]
                    },
                    {
                        sourceValue: 'NIT_SVAs_105_02', targets: [
                            { field: 'NIT_DEMMI_101', value: 'NIT_DEMMI_101_00' },
                            { field: 'NIT_DEMMI_102', value: 'NIT_DEMMI_102_00' }
                        ]
                    },
                    {
                        sourceValue: 'NIT_SVAs_105_03', targets: [
                            { field: 'NIT_DEMMI_101', value: 'NIT_DEMMI_101_00' },
                            { field: 'NIT_DEMMI_102', value: 'NIT_DEMMI_102_00' }
                        ]
                    }
                ]
            },
            //#endregion
            //#region NIT_SVAs_107
            {
                source: 'NIT_SVAs_107',
                mappings: [
                    {
                        sourceValue: 'NIT_SVAs_107_00', targets: [
                            { field: 'NIT_DEMMI_200', value: 'NIT_DEMMI_200_01' },
                            { field: 'NIT_DEMMI_201', value: 'NIT_DEMMI_201_01' },
                            { field: 'NIT_DEMMI_202', value: 'NIT_DEMMI_202_01' },
                            { field: 'NIT_DEMMI_203', value: 'NIT_DEMMI_203_01' }
                        ]
                    },
                    {
                        sourceValue: 'NIT_SVAs_107_01', targets: [
                            { field: 'NIT_DEMMI_200', value: 'NIT_DEMMI_200_00' },
                            { field: 'NIT_DEMMI_201', value: 'NIT_DEMMI_201_00' },
                            { field: 'NIT_DEMMI_202', value: 'NIT_DEMMI_202_00' },
                            { field: 'NIT_DEMMI_203', value: 'NIT_DEMMI_203_00' }
                        ]
                    }
                ]
            },
            //#endregion
            //#region NIT_SVAs_108
            {
                source: 'NIT_SVAs_108',
                mappings: [
                    {
                        sourceValue: 'NIT_SVAs_108_00',
                        targets: [
                            { field: 'NIT_DEMMI_400', value: 'NIT_DEMMI_400_01' },
                            { field: 'NIT_DEMMI_401', value: 'NIT_DEMMI_401_01' },
                            { field: 'NIT_DEMMI_402', value: 'NIT_DEMMI_402_01' },
                        ]
                    },
                    {
                        sourceValue: 'NIT_SVAs_108_01',
                        targets: [
                            { field: 'NIT_DEMMI_400', value: 'NIT_DEMMI_400_00' },
                            { field: 'NIT_DEMMI_401', value: 'NIT_DEMMI_401_00' },
                            { field: 'NIT_DEMMI_402', value: 'NIT_DEMMI_402_00' },
                        ]
                    }
                ]
            },
            //#endregion
            //#region NIT_SVAs_100 - keep it at last place to fulfill 1991, 05.09.2022
            {
                source: 'NIT_SVAs_100', mappings: [
                    { sourceValue: 'NIT_SVAs_100_00', targets: [{ field: 'NIT_DEMMI_301', value: 'NIT_DEMMI_301_04' }] },
                    { sourceValue: 'NIT_SVAs_100_01', targets: [{ field: 'NIT_DEMMI_301', value: 'NIT_DEMMI_301_03' }] },
                    { sourceValue: 'NIT_SVAs_100_02', targets: [{ field: 'NIT_DEMMI_301', value: 'NIT_DEMMI_301_01' }] },
                    {
                        sourceValue: 'NIT_SVAs_100_03', targets: [ // updated for #1991: "Änderung für DEMMI Verknüpfung zu überprüfen! Porz", drawio file
                            { field: 'NIT_DEMMI_300', value: 'NIT_DEMMI_300_00' },
                            { field: 'NIT_DEMMI_301', value: 'NIT_DEMMI_301_00' },
                            { field: 'NIT_DEMMI_400', value: 'NIT_DEMMI_400_00' },
                            { field: 'NIT_DEMMI_401', value: 'NIT_DEMMI_401_00' },
                            { field: 'NIT_DEMMI_402', value: 'NIT_DEMMI_402_00' },
                            { field: 'NIT_DEMMI_200', value: 'NIT_DEMMI_200_00' },
                            { field: 'NIT_DEMMI_201', value: 'NIT_DEMMI_201_00' },
                            { field: 'NIT_DEMMI_202', value: 'NIT_DEMMI_202_00' },
                            { field: 'NIT_DEMMI_203', value: 'NIT_DEMMI_203_00' }
                        ]
                    }
                ]
            },
            //#endregion
        ];

        Fhir.Questionnaire.EnsureStructuredResponse(demmiQuestionnaire, demmiResponse);
        Fhir.Questionnaire.SetDefaultValues(demmiQuestionnaire, demmiResponse, undefined);

        //#region iterate through all mappings..
        for (const mapping of mappings) {
            try {
                // step 1: get the value of mapping.source from the assessment..
                const sourceValue = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, mapping.source, undefined);

                if (sourceValue) {
                    // step 2: find the mapping pointing to the source value..
                    const map = mapping.mappings.find(o => o.sourceValue === String(sourceValue));
                    if (map) {
                        // step 3: if one exists, iterate through the target values
                        for (const target of map.targets) {
                            // get the display of the target value:
                            const questionnaireItem = Fhir.Questionnaire.GetQuestionnaireItemByLinkId(demmiQuestionnaire, target.field);
                            let displayText: string = undefined;
                            if (questionnaireItem) {
                                const option = questionnaireItem.option.find(o => o.valueCoding && o.valueCoding.code === target.value);
                                if (option && option.valueCoding && option.valueCoding.display)
                                    displayText = option.valueCoding.display;
                            }

                            // create a new answer for the target
                            const responseField = QuestionnaireResponse.GetResponseItemByLinkId(demmiResponse, target.field, false);
                            const newAnswerArray: any[] = [{
                                valueCoding: {
                                    code: target.value,
                                    display: displayText
                                }
                            }];

                            if (responseField && JSON.stringify(responseField.answer) !== JSON.stringify(newAnswerArray)) {
                                responseField.answer = newAnswerArray;
                                demmiNeedsUpdate = true;
                            }
                        }
                    }
                }
            } catch (e) {
                console.warn(e);
            }
        }
        //#endregion

        this.calculateDemmiResultFields(demmiResponse);

        if (updateOnFhir && demmiNeedsUpdate) {
            const items = [demmiResponse];
            if (assessmentNeedsUpdate)
                items.push(assessment);
            await this.fhirService.bundle(items, fhirEnums.HTTPVerb.put, BundleType.transaction);
        }

        return demmiResponse;
    }

    protected async mapBarthelIndexResponse(patient: PatientItem, assessment?: any): Promise<number> {
        let sum: number = 0;

        if (!assessment && patient?.latestAssessment)
            assessment = patient.latestAssessment;

        if (!assessment) return -1;

        /***
         compares the string s1 to _each_ of the strings in s2 (array) and returns true if a match is found
         */
        const eq = function (s1: string, ...s2: string[]): boolean {
            for (const s2Sub of s2) {
                if (String(s1).toUpperCase() === String(s2Sub).toUpperCase()) {
                    return true;
                }
            }

            return false;
        };

        const qBI = await QuestionnaireService.GetQuestionnaireByName(this.barthelIndexName);
        if (!qBI) return -1;

        let createOnFhir = false;
        let biResponse = QuestionnaireService.GetLatestResponseOfType(patient, qBI.id);
        if (!biResponse) {
            biResponse = Fhir.Tools.SubstituteDefaultQRSkeleton(patient, qBI.id, QuestionnaireResponseStatus.inProgress);
            QuestionnaireResponse.SetDefaultValues(biResponse);
            createOnFhir = true;
        }

        // only map values if not completed or amended
        if (!eq(biResponse.status, 'in-progress')) {
            return QuestionnaireResponse.GetResponseItemValueByLinkId(biResponse, 'NIT_BI_10', false);
        }

        QuestionnaireResponse.EnsureStructuralHullIntegrity(qBI, biResponse);

        const questionnaireAssessment = QuestionnaireService.GetQuestionnaireDirect(assessment?.questionnaire);
        const checkFieldExistenceAndGetValue = function (assessment, linkId: string, defaultValue: any) {
            let result: any = undefined;
            if (questionnaireAssessment) {
                const questionnaireField = Questionnaire.GetQuestionnaireItemByLinkId(questionnaireAssessment, linkId);
                if (!questionnaireField) {
                    console.warn(`FIELD "${linkId}" NOT FOUND IN QUESTIONNAIRE "${questionnaireAssessment.name}"! ANALYSIS MAY BE FAULTY!\nPLEASE CORRECT THE QUESTIONNAIRE TO CONTAIN THE FIELD!`);
                } else {
                    result = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, linkId, defaultValue);
                    if (typeof result === 'undefined') {
                        console.warn(`FIELD "${linkId}" NOT FOUND IN RESPONSE for QUESTIONNAIRE "${questionnaireAssessment.name}"! ANALYSIS MAY BE FAULTY!\nPLEASE CORRECT THE QUESTIONNAIRE TO CONTAIN A DEFAULT VALUE FOR THE FIELD!`);
                    }
                }
            }

            return result;
        };

        //#region Const values from the assessment
        /** Bewegung - Mobilität */
        const NIT_SVAs_100 = String(checkFieldExistenceAndGetValue(assessment, 'NIT_SVAs_100', 'NIT_SVAs_100_00')).toUpperCase(); // QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_100', 'NIT_SVAs_100_00');

        /** Bewegung - Bewegung innerhalb des derzeitigen Pflegebereichs */
        const NIT_SVAs_102 = String(checkFieldExistenceAndGetValue(assessment, 'NIT_SVAs_102', 'NIT_SVAs_102_00')).toUpperCase();

        /** Bewegung - Umsetzen */
        const NIT_SVAs_105 = String(checkFieldExistenceAndGetValue(assessment, 'NIT_SVAs_105', 'NIT_SVAs_105_00')).toUpperCase();

        /** Bewegung - Treppensteigen */
        const NIT_SVAs_106 = String(checkFieldExistenceAndGetValue(assessment, 'NIT_SVAs_106', 'NIT_SVAs_106_00')).toUpperCase();

        /** Körperpflege - Körperpflege Oberkörper */
        const NIT_SVAs_300 = String(checkFieldExistenceAndGetValue(assessment, 'NIT_SVAs_300', 'NIT_SVAs_300_00')).toUpperCase();

        /** Körperpflege - Körperpflege Unterkörper */
        const NIT_SVAs_301 = String(checkFieldExistenceAndGetValue(assessment, 'NIT_SVAs_301', 'NIT_SVAs_301_00')).toUpperCase();

        /** Körperpflege - Körperpflege im Bereich des Kopfes */
        const NIT_SVAs_302 = String(checkFieldExistenceAndGetValue(assessment, 'NIT_SVAs_302', 'NIT_SVAs_302_00')).toUpperCase();

        /** Körperpflege - Waschen des Intimbereiches */
        const NIT_SVAs_303 = String(checkFieldExistenceAndGetValue(assessment, 'NIT_SVAs_303', 'NIT_SVAs_303_00')).toUpperCase();

        /** Körperpflege - Fähigkeit ein Vollbad, Sitzbad, Duschbad in.. */
        const NIT_SVAs_304 = String(checkFieldExistenceAndGetValue(assessment, 'NIT_SVAs_304', 'NIT_SVAs_304_00')).toUpperCase();

        /** Nahrungs- und Flüssigkeitsaufnahme - Mundgerechtes Zubereiten der Nahrung und Ei.. */
        const NIT_SVAs_402 = String(checkFieldExistenceAndGetValue(assessment, 'NIT_SVAs_402', 'NIT_SVAs_402_00')).toUpperCase();

        /** Nahrungs- und Flüssigkeitsaufnahme - Essen */
        const NIT_SVAs_403 = String(checkFieldExistenceAndGetValue(assessment, 'NIT_SVAs_403', 'NIT_SVAs_403_00')).toUpperCase();

        /** Nahrungs- und Flüssigkeitsaufnahme - Ernährung parenteral oder über Sonde */
        const NIT_SVAs_404 = String(checkFieldExistenceAndGetValue(assessment, 'NIT_SVAs_404', 'NIT_SVAs_404_00')).toUpperCase();

        /** Nahrungs- und Flüssigkeitsaufnahme - Magensonde / PEG Versorgung / parenterale E.. */
        const NIT_SVAs_410 = String(checkFieldExistenceAndGetValue(assessment, 'NIT_SVAs_410', 'NIT_SVAs_410_00')).toUpperCase();

        /** Harn- und Stuhlausscheidung - Toilettenbenutzung (ggf. Toilettenstuhlbenu.. */
        const NIT_SVAs_500 = String(checkFieldExistenceAndGetValue(assessment, 'NIT_SVAs_500', 'NIT_SVAs_500_00')).toUpperCase();

        /** Harn- und Stuhlausscheidung - Blasenkontrolle */
        const NIT_SVAs_501 = String(checkFieldExistenceAndGetValue(assessment, 'NIT_SVAs_501', 'NIT_SVAs_501_00')).toUpperCase();

        /** Harn- und Stuhlausscheidung - Harnableitungssystem */
        const NIT_SVAs_502 = String(checkFieldExistenceAndGetValue(assessment, 'NIT_SVAs_502', 'NIT_SVAs_502_00')).toUpperCase();

        /** Harn- und Stuhlausscheidung - Umgang mit der Harnausscheidung */
        const NIT_SVAs_503 = String(checkFieldExistenceAndGetValue(assessment, 'NIT_SVAs_503', 'NIT_SVAs_503_00')).toUpperCase();

        /** Harn- und Stuhlausscheidung - Darmkontrolle */
        const NIT_SVAs_504 = String(checkFieldExistenceAndGetValue(assessment, 'NIT_SVAs_504', 'NIT_SVAs_504_00')).toUpperCase();

        /** Harn- und Stuhlausscheidung - Stuhlableitungssystem */
        const NIT_SVAs_505 = String(checkFieldExistenceAndGetValue(assessment, 'NIT_SVAs_505', 'NIT_SVAs_505_00')).toUpperCase();

        /** Harn- und Stuhlausscheidung - Umgang mit der Stuhlausscheidung */
        const NIT_SVAs_506 = String(checkFieldExistenceAndGetValue(assessment, 'NIT_SVAs_506', 'NIT_SVAs_506_00')).toUpperCase();

        /** An- und Auskleiden - An- und Auskleiden Oberkörper */
        const NIT_SVAs_600 = String(checkFieldExistenceAndGetValue(assessment, 'NIT_SVAs_600', 'NIT_SVAs_600_00')).toUpperCase();

        /** An- und Auskleiden - An- und Auskleiden Unterkörper */
        const NIT_SVAs_601 = String(checkFieldExistenceAndGetValue(assessment, 'NIT_SVAs_601', 'NIT_SVAs_601_00')).toUpperCase();

        /** ANAMNESIS! - Heil- und Hilfsmittel - Rollator */
        const NIT_SVAn_607 = String(QuestionnaireResponse.GetResponseItemValueByLinkId(this._anamnesis, 'NIT_SVAn_607', 'NIT_SVAn_607_01')).toUpperCase();

        /** ANAMNESIS! - Heil- und Hilfsmittel - Rollstuhl */
        const NIT_SVAn_608 = String(QuestionnaireResponse.GetResponseItemValueByLinkId(this._anamnesis, 'NIT_SVAn_608', 'NIT_SVAn_608_01')).toUpperCase();
        //#endregion

        //#region BI_00, Essen
        const itemBi00 = Fhir.Questionnaire.GetQuestionnaireItemByLinkId(qBI, 'NIT_BI_00');
        const bi00Answer = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(biResponse, 'NIT_BI_00');

        if (itemBi00 && bi00Answer) {
            const bi00_value10 = itemBi00.option.find(o => eq(o.valueCoding?.code, 'NIT_BI_00_00')); // 10
            const bi00_value05 = itemBi00.option.find(o => eq(o.valueCoding?.code, 'NIT_BI_00_01')); //  5
            const bi00_value00 = itemBi00.option.find(o => eq(o.valueCoding?.code, 'NIT_BI_00_02')); //  0

            //default to 0
            bi00Answer.answer = [bi00_value00];
            if (eq(NIT_SVAs_410, 'NIT_SVAs_410_00') // “Magensonde / PEG Versorgung / parenterale Ernährung”, "Nein" (0)
                // “Essen” = 0  ODER "Mundgerechtes Zubereiten der Nahrung und Eingießen von Getränken” = 0
                && (eq(NIT_SVAs_403, 'NIT_SVAs_403_00') || eq(NIT_SVAs_402, 'NIT_SVAs_402_00'))
                // ABER NICHT "Mungerechtes Zubereiten der Nahrung und Eingießen von Getränken" 1 oder 2
                && !eq(NIT_SVAs_402, 'NIT_SVAs_402_01', 'NIT_SVAs_402_02')
                // UND NICHT "Essen" 1 2 oder 3
                && !eq(NIT_SVAs_403, 'NIT_SVAs_403_01', 'NIT_SVAs_403_02', 'NIT_SVAs_403_03')
            ) {
                sum += 10;
                bi00Answer.answer = [bi00_value10];
            } else if (eq(NIT_SVAs_410, "NIT_SVAs_410_01") // "Magensonde / PEG Versorgung / parenterale Ernährung", "Ja"
                && eq(NIT_SVAs_404, 'NIT_SVAs_404_00') // "Ernährung parenteral oder über Sonde", "0"
            ) {
                sum += 10;
                bi00Answer.answer = [bi00_value10];
            } else if ((eq(NIT_SVAs_402, 'NIT_SVAs_402_01', 'NIT_SVAs_402_02')) // "Mundgerechtes Zubereiten der Nahrung und Eingießen von Getränken", 1 ODER 2
                && (eq(NIT_SVAs_403, 'NIT_SVAs_403_00', 'NIT_SVAs_403_01')) // "Essen", 0 ODER 1
            ) {
                sum += 5;
                bi00Answer.answer = [bi00_value05];
            } else if (eq(NIT_SVAs_410, 'NIT_SVAs_410_01') // "Magensonde / PEG Versorgung / parenterale Ernährung", "Ja" (01)
                && eq(NIT_SVAs_404, 'NIT_SVAs_404_01', 'NIT_SVAs_404_02', 'NIT_SVAs_404_03') // Ernährung parenteral oder über Sonde, 1 ODER 2 ODER 3
            ) {
                sum += 5;
                bi00Answer.answer = [bi00_value05];
            }
        }
        //#endregion

        //#region BI_01, Aufsetzen/Umsetzen
        const bi01Answer = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(biResponse, 'NIT_BI_01');
        const itemBi01 = Fhir.Questionnaire.GetQuestionnaireItemByLinkId(qBI, 'NIT_BI_01');
        if (bi01Answer && itemBi01) {
            const bi01_00 = itemBi01.option.find(o => eq(o.valueCoding?.code, 'NIT_BI_01_00'));
            const bi01_01 = itemBi01.option.find(o => eq(o.valueCoding?.code, 'NIT_BI_01_01'));
            const bi01_02 = itemBi01.option.find(o => eq(o.valueCoding?.code, 'NIT_BI_01_02'));
            const bi01_03 = itemBi01.option.find(o => eq(o.valueCoding?.code, 'NIT_BI_01_03'));
            if (eq(NIT_SVAs_105, 'NIT_SVAS_105_00')) {
                sum += 15;
                bi01Answer.answer = [{ valueCoding: bi01_00.valueCoding }];
            } else if (eq(NIT_SVAs_105, 'NIT_SVAS_105_01')) {
                sum += 10;
                bi01Answer.answer = [{ valueCoding: bi01_01.valueCoding }];
            } else if (eq(NIT_SVAs_105, 'NIT_SVAS_105_02')) {
                sum += 5;
                bi01Answer.answer = [{ valueCoding: bi01_02.valueCoding }];
            } else if (eq(NIT_SVAs_105, 'NIT_SVAS_105_03')) {
                bi01Answer.answer = [{ valueCoding: bi01_03.valueCoding }];
            }
        }
        //#endregion

        //#region BI_02, Waschen
        const bi02Answer = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(biResponse, 'NIT_BI_02');
        const itemBi02 = Fhir.Questionnaire.GetQuestionnaireItemByLinkId(qBI, 'NIT_BI_02');
        if (bi02Answer && itemBi02) {
            const bi02_00 = itemBi02.option.find(o => eq(o.valueCoding?.code, 'NIT_BI_02_00'));
            const bi02_01 = itemBi02.option.find(o => eq(o.valueCoding?.code, 'NIT_BI_02_01'));

            if (eq(NIT_SVAs_300, 'NIT_SVAs_300_00')
                && eq(NIT_SVAs_301, 'NIT_SVAs_301_00')
                && eq(NIT_SVAs_302, 'NIT_SVAs_302_00')
                && eq(NIT_SVAs_303, 'NIT_SVAs_303_00')
            ) {
                sum += 5;
                bi02Answer.answer = [{ valueCoding: bi02_00.valueCoding }];
            } else if (
                eq(NIT_SVAs_300, 'NIT_SVAs_300_01', 'NIT_SVAs_300_02', 'NIT_SVAs_300_03')
                && eq(NIT_SVAs_301, 'NIT_SVAs_301_01', 'NIT_SVAs_301_02', 'NIT_SVAs_301_03')
                && eq(NIT_SVAs_302, 'NIT_SVAs_302_01', 'NIT_SVAs_302_02', 'NIT_SVAs_302_03')
                && eq(NIT_SVAs_303, 'NIT_SVAs_303_01', 'NIT_SVAs_303_02', 'NIT_SVAs_303_03')
            ) {
                bi02Answer.answer = [{ valueCoding: bi02_01.valueCoding }];
                sum += 0;
            }
        }
        //#endregion

        //#region BI_03, Toilette
        const bi03Answer = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(biResponse, 'NIT_BI_03');
        const itemBi03 = Fhir.Questionnaire.GetQuestionnaireItemByLinkId(qBI, 'NIT_BI_03');
        if (bi03Answer && itemBi03) {
            const bi03_value10 = itemBi03.option.find(o => eq(o.valueCoding?.code, 'NIT_BI_03_00'));
            const bi03_value05 = itemBi03.option.find(o => eq(o.valueCoding?.code, 'NIT_BI_03_01'));
            const bi03_value00 = itemBi03.option.find(o => eq(o.valueCoding?.code, 'NIT_BI_03_02'));

            // default to 0
            bi03Answer.answer = [bi03_value00];

            if (eq(NIT_SVAs_500, 'NIT_SVAs_500_00')) {
                bi03Answer.answer = [bi03_value10];
                sum += 10;
            } else if (eq(NIT_SVAs_500, 'NIT_SVAs_500_01', 'NIT_SVAs_500_02')) {
                sum += 5;
                bi03Answer.answer = [{ valueCoding: bi03_value05.valueCoding }];
            }
        }
        //#endregion

        //#region BI_04, Baden/Duschen, updated due to planio issue #2891
        const bi04Answer = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(biResponse, 'NIT_BI_04');
        const itemBi04 = Fhir.Questionnaire.GetQuestionnaireItemByLinkId(qBI, 'NIT_BI_04');
        if (bi04Answer && itemBi04) {
            const bi04_value05 = itemBi04.option.find(o => eq(o.valueCoding?.code, 'NIT_BI_04_00'));
            const bi04_value00 = itemBi04.option.find(o => eq(o.valueCoding?.code, 'NIT_BI_04_01'));

            // default to 00
            bi04Answer.answer = [bi04_value00];
            if (eq(NIT_SVAs_304, 'NIT_SVAs_304_00')) {
                sum += 5;
                bi04Answer.answer = [bi04_value05];
            }
        }
        //#endregion

        //#region BI_05 Aufstehen/Gehen, updated due to planio issue #2892
        const bi05Answer = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(biResponse, 'NIT_BI_05');
        const itemBi05 = Fhir.Questionnaire.GetQuestionnaireItemByLinkId(qBI, 'NIT_BI_05');
        if (bi05Answer && itemBi05) {
            const bi05_value15 = itemBi05.option.find(o => eq(o.valueCoding?.code, 'NIT_BI_05_00'));
            const bi05_value10 = itemBi05.option.find(o => eq(o.valueCoding?.code, 'NIT_BI_05_01'));
            const bi05_value05 = itemBi05.option.find(o => eq(o.valueCoding?.code, 'NIT_BI_05_02'));
            const bi05_value00 = itemBi05.option.find(o => eq(o.valueCoding?.code, 'NIT_BI_05_03'));

            // default to 0
            bi05Answer.answer = [bi05_value00];

            if (eq(NIT_SVAs_102, 'NIT_SVAs_102_00') // “Bewegung innerhalb des derzeitigen Pflegebereiches”, "0"
                && eq(NIT_SVAn_607, 'NIT_SVAn_607_01') // "Rollator", "Nein" (01)
                && eq(NIT_SVAs_105, 'NIT_SVAs_105_00') // “Umsetzen”, "0"
                && eq(NIT_SVAs_100, 'NIT_SVAs_100_00') // “Mobilität”, "0"
                && !eq(NIT_SVAn_608, 'NIT_SVAn_608_02') // Nicht "Rollstuhl" vorhanden
            ) {
                sum += 15;
                bi05Answer.answer = [bi05_value15];
            } else if (eq(NIT_SVAs_102, 'NIT_SVAs_102_00', 'NIT_SVAs_102_01') // “Bewegung innerhalb des derzeitigen Pflegebereiches”, "0" oder "1"
                && eq(NIT_SVAn_607, 'NIT_SVAn_607_02') // Rollator, "vorhanden" (02)
                && eq(NIT_SVAs_105, 'NIT_SVAs_105_00') // Umsetzen, 0
                && eq(NIT_SVAs_100, 'NIT_SVAs_100_01') // Mobilität, 1
            ) {
                sum += 10;
                bi05Answer.answer = [bi05_value10];
            } else if (eq(NIT_SVAs_102, 'NIT_SVAs_102_01') // Bewegung innerhalb des derzeitigen Pflegebereiches, "1"
                && (eq(NIT_SVAn_607, 'NIT_SVAn_607_01', 'NIT_SVAn_607_02', 'NIT_SVAn_607_03')) // // Rollator: "Nein" (01) ODER "Vorhanden" (02) ODER "Fehlt" (03)
                && eq(NIT_SVAs_105, 'NIT_SVAs_105_01') // Umsetzen, 1
                && eq(NIT_SVAs_100, 'NIT_SVAs_100_01', 'NIT_SVAs_100_02') // Mobilität 1 ODER 2
            ) {
                sum += 5;
                bi05Answer.answer = [bi05_value05];
            } else if (eq(NIT_SVAn_608, 'NIT_SVAn_608_02') // Rollstuhl, vorhanden
                && eq(NIT_SVAs_102, 'NIT_SVAs_102_00')// Bewegung innerhalb des derzeitigen Pflegebereiches, 0
            ) {
                sum += 5;
                bi05Answer.answer = [bi05_value05];
            }
        }
        //#endregion

        //#region BI_06, Treppensteigen, updated due to planio issue #2893
        const bi06Answer = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(biResponse, 'NIT_BI_06');
        const itemBi06 = Fhir.Questionnaire.GetQuestionnaireItemByLinkId(qBI, 'NIT_BI_06');
        if (bi06Answer && itemBi06) {
            const bi06_value10 = itemBi06.option.find(o => eq(o.valueCoding?.code, 'NIT_BI_06_00'));
            const bi06_value05 = itemBi06.option.find(o => eq(o.valueCoding?.code, 'NIT_BI_06_01'));
            const bi06_value00 = itemBi06.option.find(o => eq(o.valueCoding?.code, 'NIT_BI_06_02'));
            //default to 0
            bi06Answer.answer = [bi06_value00];

            if (eq(NIT_SVAs_106, 'NIT_SVAs_106_00')) { // “Treppensteigen”, Ausprägung "0", 10 Punkte
                sum += 10;
                bi06Answer.answer = [bi06_value10];
            } else if (eq(NIT_SVAs_106, 'NIT_SVAs_106_01')) { // “Treppensteigen”, Ausprägung "1", 5 Punkte
                sum += 5;
                bi06Answer.answer = [bi06_value05];
            }
        }
        //#endregion

        //#region BI_07, An- und Auskleiden
        const bi07Answer = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(biResponse, 'NIT_BI_07');
        const itemBi07 = Fhir.Questionnaire.GetQuestionnaireItemByLinkId(qBI, 'NIT_BI_07');
        if (bi07Answer && itemBi07) {
            const bi07_value10 = itemBi07.option.find(o => o.valueCoding && o.valueCoding.code === 'NIT_BI_07_00');
            const bi07_value05 = itemBi07.option.find(o => o.valueCoding && o.valueCoding.code === 'NIT_BI_07_01');
            const bi07_value00 = itemBi07.option.find(o => o.valueCoding && o.valueCoding.code === 'NIT_BI_07_02');

            // default to 0
            bi07Answer.answer = [bi07_value00];

            if (eq(NIT_SVAs_600, 'NIT_SVAs_600_00')
                && eq(NIT_SVAs_601, 'NIT_SVAs_601_00')
            ) {
                bi07Answer.answer = [bi07_value10];
                sum += 10;
            } else if (eq(NIT_SVAs_600, 'NIT_SVAs_600_00', 'NIT_SVAs_600_01')
                && eq(NIT_SVAs_601, 'NIT_SVAs_601_01', 'NIT_SVAs_601_02', 'NIT_SVAs_601_03')
            ) {
                bi07Answer.answer = [bi07_value05];
                sum += 5;
            }
        }
        //#endregion

        //#region BI_08, Stuhlkontinenz
        const bi08Answer = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(biResponse, 'NIT_BI_08');
        const itemBi08 = Fhir.Questionnaire.GetQuestionnaireItemByLinkId(qBI, 'NIT_BI_08');
        if (bi08Answer && itemBi08) {
            const hasStuhlableitungssystem = eq(NIT_SVAs_505, 'NIT_SVAs_505_01');
            const bi08_value10 = itemBi08.option.find(o => eq(o.valueCoding?.code, 'NIT_BI_08_00')); // BI 10
            const bi08_value05 = itemBi08.option.find(o => eq(o.valueCoding?.code, 'NIT_BI_08_01')); // BI  5
            const bi08_value00 = itemBi08.option.find(o => eq(o.valueCoding?.code, 'NIT_BI_08_02')); // BI  0

            // default to 0
            bi08Answer.answer = [bi08_value00];

            if (!hasStuhlableitungssystem) {
                // kein Stuhlableitungssystem
                if (eq(NIT_SVAs_504, 'NIT_SVAs_504_00')) {  // “Darmkontrolle”, Ausprägung “0”, 10 Punkte
                    sum += 10;
                    bi08Answer.answer = [bi08_value10];
                } else if (eq(NIT_SVAs_504, 'NIT_SVAs_504_01')) {  // “Darmkontrolle”, Ausprägung “1”, 5 Punkte
                    sum += 5;
                    bi08Answer.answer = [bi08_value05];
                }
            }
            else {
                // Stuhlableitungssystem vorhanden
                if (eq(NIT_SVAs_506, 'NIT_SVAs_506_00')) { // “Umgang mit der Stuhlausscheidung”, Ausprägung “0”, 10 Punkte
                    bi08Answer.answer = [bi08_value10];
                    sum += 10;
                } else if (eq(NIT_SVAs_506, 'NIT_SVAs_506_01', 'NIT_SVAs_506_02', 'NIT_SVAs_506_03')) { // “Umgang mit der Stuhlausscheidung”, Ausprägung “1”, 5 Punkte
                    bi08Answer.answer = [bi08_value05];
                    sum += 5;
                }
            }
        }
        //#endregion

        //#region BI_09, Harnkontinent, updated due to planio issue #2894
        const bi09Answer = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(biResponse, 'NIT_BI_09');
        const itemBi09 = Fhir.Questionnaire.GetQuestionnaireItemByLinkId(qBI, 'NIT_BI_09');
        if (bi09Answer && itemBi09) {
            const bi09_value10 = itemBi09.option.find(o => eq(o.valueCoding?.code, 'NIT_BI_09_00')); //BI 10
            const bi09_value05 = itemBi09.option.find(o => eq(o.valueCoding?.code, 'NIT_BI_09_01')); //BI  5
            const bi09_value00 = itemBi09.option.find(o => eq(o.valueCoding?.code, 'NIT_BI_09_02')); //BI  0
            const hasHarnableitung = eq(NIT_SVAs_502, 'NIT_SVAS_502_01');

            // set default value to 0
            bi09Answer.answer = [bi09_value00];

            if (!hasHarnableitung) { // KEINE Harnableitung
                // 10 Punkte
                if (eq(NIT_SVAs_501, 'NIT_SVAs_501_00')) {
                    sum += 10;
                    bi09Answer.answer = [bi09_value10];
                }
                // 5 Punke
                else if (eq(NIT_SVAs_501, 'NIT_SVAs_501_01')) {
                    sum += 5;
                    bi09Answer.answer = [bi09_value05];
                }
                // 0 Punkte
                else if (eq(NIT_SVAs_501, 'NIT_SVAs_501_02', 'NIT_SVAs_501_03')) {
                    bi09Answer.answer = [bi09_value00];
                }
            } else { // Harnableitung vorhanden
                // 10 Punkte
                if (eq(NIT_SVAs_503, 'NIT_SVAs_503_00')) {
                    sum += 10;
                    bi09Answer.answer = [bi09_value10];
                }
                // 5 Punkte
                else if (eq(NIT_SVAs_503, 'NIT_SVAs_503_01', 'NIT_SVAs_503_02', 'NIT_SVAs_503_03')) {
                    sum += 5;
                    bi09Answer.answer = [bi09_value05];
                } else
                // 0 Punkte, fallback - should not appear!
                {
                    console.warn(`Unexpected value for NIT_SVAs_503: "${NIT_SVAs_503}"`);
                    bi09Answer.answer = [bi09_value00];
                }
            }
        }
        //#endregion

        PatientService.AddQuestionnaireResponse(patient, biResponse, true);

        if (createOnFhir) {
            await Fhir.Rest.Update(biResponse);
        }

        let attached: any;
        try {
            attached = await Fhir.QuestionnaireResponse.GetAttachedResponse(patient, assessment, this.barthelIndexName);
        } catch (e) {
            if (ConfigService.Debug) console.warn(e.message || e);
            attached = undefined;
        }

        if (!attached || !attached.id || attached.id && attached.id != biResponse.id) {
            Fhir.QuestionnaireResponse.SetAttachedResponse(patient, assessment, biResponse);
            PatientService.AddQuestionnaireResponse(patient, assessment, true);
        }

        const NIT_BI_10 = QuestionnaireResponse.GetResponseItemByLinkId(biResponse, 'NIT_BI_10', true);
        if (NIT_BI_10) {
            NIT_BI_10.answer = [{ valueInteger: sum }];
        }

        /*** calls getCurrentValue to get the current ordinal value of the responseItem (iE bi02Answer)
         * and if it is not NaN adds it to sum */
        const calcNewSum = (responseItem) => {
            let val = this.getCurrentValue(qBI, responseItem);
            if (!isNaN(val))
                sum += val;
        };

        /*** function to get the values from the questionnaireresponse items rather than the calculated ones.
         * Doing it this way, questionnaire default values are although included, when some mappings do not happen.
         */
        const calcSums = () => {
            sum = 0;
            calcNewSum(bi00Answer);
            calcNewSum(bi01Answer);
            calcNewSum(bi02Answer);
            calcNewSum(bi03Answer);
            calcNewSum(bi04Answer);
            calcNewSum(bi05Answer);
            calcNewSum(bi06Answer);
            calcNewSum(bi07Answer);
            calcNewSum(bi08Answer);
            calcNewSum(bi09Answer);
        };

        calcSums();

        return sum;
    }

    protected async mapBarthelIndexExResponse(patient: PatientItem, assessment?: any): Promise<number> {
        const qBiEx = QuestionnaireService.GetQuestionnaireByNameDirect(this.barthelIndexEXName);
        if (!qBiEx) return -1;

        let sum: number = 0;

        if (!assessment && patient && patient.latestAssessment)
            assessment = patient.latestAssessment;

        if (!assessment) return -1;

        let biExResponse = QuestionnaireService.GetLatestResponseOfType(patient, qBiEx.id);
        if (!biExResponse) {
            biExResponse = Fhir.Tools.SubstituteDefaultQRSkeleton(patient, qBiEx.id, QuestionnaireResponseStatus.inProgress);
            QuestionnaireResponse.SetDefaultValues(biExResponse);
        }

        // only map values if not completed or amended
        if (String(biExResponse.status) != 'in-progress') {
            return QuestionnaireResponse.GetResponseItemValueByLinkId(biExResponse, 'NIT_EBI_06', undefined);
        }

        QuestionnaireResponse.EnsureStructuralHullIntegrity(qBiEx, biExResponse);
        const NIT_SVAs_11104 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_11104', 'NIT_SVAs_11104_00');
        const NIT_SVAs_11105 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_11105', 'NIT_SVAs_11105_00');
        const NIT_SVAs_11106 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_11106', 'NIT_SVAs_11106_00');
        const NIT_SVAs_11107 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_11107', 'NIT_SVAs_11107_00');
        const NIT_SVAs_11108 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_11108', 'NIT_SVAs_11108_00');
        const NIT_SVAs_11111 = QuestionnaireResponse.GetResponseItemValueByLinkId(assessment, 'NIT_SVAs_11111', 'NIT_SVAs_11111_00');

        const NIT_EBI_00 = QuestionnaireResponse.GetResponseItemByLinkId(biExResponse, 'NIT_EBI_00', true);
        const NIT_EBI_01 = QuestionnaireResponse.GetResponseItemByLinkId(biExResponse, 'NIT_EBI_01', true);
        const NIT_EBI_02 = QuestionnaireResponse.GetResponseItemByLinkId(biExResponse, 'NIT_EBI_02', true);
        const NIT_EBI_03 = QuestionnaireResponse.GetResponseItemByLinkId(biExResponse, 'NIT_EBI_03', true);
        const NIT_EBI_04 = QuestionnaireResponse.GetResponseItemByLinkId(biExResponse, 'NIT_EBI_04', true);
        const NIT_EBI_05 = QuestionnaireResponse.GetResponseItemByLinkId(biExResponse, 'NIT_EBI_05', true);
        const NIT_EBI_06 = QuestionnaireResponse.GetResponseItemByLinkId(biExResponse, 'NIT_EBI_06', true);

        //#region EBI 00, Verstehen
        if (NIT_EBI_00) {
            const itemEbi00 = Fhir.Questionnaire.GetQuestionnaireItemByLinkId(qBiEx, 'NIT_EBI_00');
            const ebi00_00 = itemEbi00.option.find(o => o.valueCoding && o.valueCoding.code === 'NIT_EBI_00_00');
            const ebi00_01 = itemEbi00.option.find(o => o.valueCoding && o.valueCoding.code === 'NIT_EBI_00_01');
            const ebi00_02 = itemEbi00.option.find(o => o.valueCoding && o.valueCoding.code === 'NIT_EBI_00_02');
            const ebi00_03 = itemEbi00.option.find(o => o.valueCoding && o.valueCoding.code === 'NIT_EBI_00_03');

            let sum00 = this.fieldToIntValue(assessment, 'NIT_SVAs_1102');
            sum00 = this.addSum(sum00, this.fieldToIntValue(assessment, 'NIT_SVAs_1103'));
            if (sum00 == 0) {
                NIT_EBI_00.answer = [{ valueCoding: ebi00_00.valueCoding }];
                sum += 15;
            } else if (sum00 == 1 || sum00 == 2) {
                NIT_EBI_00.answer = [{ valueCoding: ebi00_01.valueCoding }];
                sum += 10;
            } else if (sum00 == 3 || sum00 == 4) {
                NIT_EBI_00.answer = [{ valueCoding: ebi00_02.valueCoding }];
                sum += 5;
            } else if (sum00 == 5 || sum00 == 6) {
                NIT_EBI_00.answer = [{ valueCoding: ebi00_03.valueCoding }];
                sum += 0;
            }
        }
        //#endregion

        //#region EBI 01, verständlich machen
        if (NIT_EBI_01) {
            const itemEbi01 = Fhir.Questionnaire.GetQuestionnaireItemByLinkId(qBiEx, 'NIT_EBI_01');
            const ebi01_00 = itemEbi01.option.find(o => o.valueCoding && o.valueCoding.code === 'NIT_EBI_01_00');
            const ebi01_01 = itemEbi01.option.find(o => o.valueCoding && o.valueCoding.code === 'NIT_EBI_01_01');
            const ebi01_02 = itemEbi01.option.find(o => o.valueCoding && o.valueCoding.code === 'NIT_EBI_01_02');

            let sum01 = this.fieldToIntValue(assessment, 'NIT_SVAs_1107');
            sum01 = this.addSum(sum01, this.fieldToIntValue(assessment, 'NIT_SVAs_03'));
            switch (sum01) {
                case 0:
                case 1:
                    NIT_EBI_01.answer = [{ valueCoding: ebi01_00.valueCoding }];
                    sum += 15;
                    break;
                case 2:
                case 3:
                case 4:
                    NIT_EBI_01.answer = [{ valueCoding: ebi01_01.valueCoding }];
                    sum += 5;
                    break;
                case 5:
                case 6:
                    NIT_EBI_01.answer = [{ valueCoding: ebi01_02.valueCoding }];
                    sum += 0;
                    break;
            }
        }
        //#endregion

        //#region EBI 02, soziale Interaktion
        if (NIT_EBI_02) {
            const itemEbi02 = Fhir.Questionnaire.GetQuestionnaireItemByLinkId(qBiEx, 'NIT_EBI_02');
            const ebi02_00 = itemEbi02.option.find(o => o.valueCoding && o.valueCoding.code === 'NIT_EBI_02_00');
            const ebi02_01 = itemEbi02.option.find(o => o.valueCoding && o.valueCoding.code === 'NIT_EBI_02_01');
            const ebi02_02 = itemEbi02.option.find(o => o.valueCoding && o.valueCoding.code === 'NIT_EBI_02_02');

            let sum02 = 0;
            switch (NIT_SVAs_11104) {
                case 'NIT_SVAs_11104_00':
                    sum02 += 0;
                    break;
                case 'NIT_SVAs_11104_01':
                    sum02 += 1;
                    break;
                case 'NIT_SVAs_11104_02':
                    sum02 += 3;
                    break;
                case 'NIT_SVAs_11104_03':
                    sum02 += 5;
                    break;
            }

            switch (NIT_SVAs_11105) {
                case 'NIT_SVAs_11105_00':
                    sum02 += 0;
                    break;
                case 'NIT_SVAs_11105_01':
                    sum02 += 1;
                    break;
                case 'NIT_SVAs_11105_02':
                    sum02 += 3;
                    break;
                case 'NIT_SVAs_11105_03':
                    sum02 += 5;
                    break;
            }

            switch (NIT_SVAs_11106) {
                case 'NIT_SVAs_11106_00':
                    sum02 += 0;
                    break;
                case 'NIT_SVAs_11106_01':
                    sum02 += 1;
                    break;
                case 'NIT_SVAs_11106_02':
                    sum02 += 3;
                    break;
                case 'NIT_SVAs_11106_03':
                    sum02 += 5;
                    break;
            }

            switch (NIT_SVAs_11107) {
                case 'NIT_SVAs_11107_00':
                    sum02 += 0;
                    break;
                case 'NIT_SVAs_11107_01':
                    sum02 += 1;
                    break;
                case 'NIT_SVAs_11107_02':
                    sum02 += 3;
                    break;
                case 'NIT_SVAs_11107_03':
                    sum02 += 5;
                    break;
            }

            switch (NIT_SVAs_11108) {
                case 'NIT_SVAs_11108_00':
                    sum02 += 0;
                    break;
                case 'NIT_SVAs_11108_01':
                    sum02 += 1;
                    break;
                case 'NIT_SVAs_11108_02':
                    sum02 += 3;
                    break;
                case 'NIT_SVAs_11108_03':
                    sum02 += 5;
                    break;
            }

            switch (NIT_SVAs_11111) {
                case 'NIT_SVAs_11111_00':
                    sum02 += 0;
                    break;
                case 'NIT_SVAs_11111_01':
                    sum02 += 1;
                    break;
                case 'NIT_SVAs_11111_02':
                    sum02 += 3;
                    break;
                case 'NIT_SVAs_11111_03':
                    sum02 += 5;
                    break;
            }

            if (sum02 == 0) sum02 = 0;
            else if (sum02 >= 1 && sum02 <= 6) sum02 = 1;
            else if (sum02 >= 7 && sum02 <= 18) sum02 = 2;
            else if (sum02 >= 19 && sum02 <= 30) sum02 = 3;

            sum02 = this.addSum(sum02, this.fieldToIntValue(assessment, 'NIT_SVAs_902'));

            if (sum02 === 0 || sum02 === 1) {
                NIT_EBI_02.answer = [{ valueCoding: ebi02_00.valueCoding }];
                sum += 15;
            } else if (sum02 === 2 || sum02 === 3 || sum02 === 4) {
                NIT_EBI_02.answer = [{ valueCoding: ebi02_01.valueCoding }];
                sum += 5;
            } else if (sum02 === 5 || sum02 === 6) {
                sum += 0;
                NIT_EBI_02.answer = [{ valueCoding: ebi02_02.valueCoding }];
            }
        }
        //#endregion

        //#region EBI 03, Alltagsprobleme lösen
        if (NIT_EBI_03) {
            let sum03 = this.fieldToIntValue(assessment, 'NIT_SVAs_1108');
            sum03 = this.addSum(sum03, this.fieldToIntValue(assessment, 'NIT_SVAs_1109'));
            const itemEbi03 = Fhir.Questionnaire.GetQuestionnaireItemByLinkId(qBiEx, 'NIT_EBI_03');
            const ebi03_00 = itemEbi03.option.find(o => o.valueCoding && o.valueCoding.code === 'NIT_EBI_03_00');
            const ebi03_01 = itemEbi03.option.find(o => o.valueCoding && o.valueCoding.code === 'NIT_EBI_03_01');
            const ebi03_02 = itemEbi03.option.find(o => o.valueCoding && o.valueCoding.code === 'NIT_EBI_03_02');

            if (sum03 === 0 || sum03 === 1) {
                NIT_EBI_03.answer = [{ valueCoding: ebi03_00.valueCoding }];
                sum += 15;
            } else if (sum03 >= 2 && sum03 <= 4) {
                NIT_EBI_03.answer = [{ valueCoding: ebi03_01.valueCoding }];
                sum += 5;
            } else if (sum03 === 5 || sum03 === 6) {
                NIT_EBI_03.answer = [{ valueCoding: ebi03_02.valueCoding }];
                sum += 0;
            }
        }
        //#endregion

        //#region EBI 04, Gedächtnis
        if (NIT_EBI_04) {
            const itemEbi04 = Fhir.Questionnaire.GetQuestionnaireItemByLinkId(qBiEx, 'NIT_EBI_04');
            const ebi04_00 = itemEbi04.option.find(o => o.valueCoding && o.valueCoding.code === 'NIT_EBI_04_00');
            const ebi04_01 = itemEbi04.option.find(o => o.valueCoding && o.valueCoding.code === 'NIT_EBI_04_01');
            const ebi04_02 = itemEbi04.option.find(o => o.valueCoding && o.valueCoding.code === 'NIT_EBI_04_02');
            const ebi04_03 = itemEbi04.option.find(o => o.valueCoding && o.valueCoding.code === 'NIT_EBI_04_03');

            let sum04 = this.fieldToIntValue(assessment, 'NIT_SVAs_1104', 0);
            sum04 = this.addSum(sum04, this.fieldToIntValue(assessment, 'NIT_SVAs_1105', 0));
            sum04 = this.addSum(sum04, this.fieldToIntValue(assessment, 'NIT_SVAs_07', 0));
            sum04 = this.addSum(sum04, this.fieldToIntValue(assessment, 'NIT_SVAs_08', 0));
            sum04 = this.addSum(sum04, this.fieldToIntValue(assessment, 'NIT_SVAs_09', 0));
            sum04 = this.addSum(sum04, this.fieldToIntValue(assessment, 'NIT_SVAs_10', 0));

            if (sum04 === 0) {
                NIT_EBI_04.answer = [{ valueCoding: ebi04_00.valueCoding }];
                sum += 15;
            } else if (sum04 >= 1 && sum04 <= 6) {
                NIT_EBI_04.answer = [{ valueCoding: ebi04_01.valueCoding }];
                sum += 10;
            } else if (sum04 >= 7 && sum04 <= 12) {
                NIT_EBI_04.answer = [{ valueCoding: ebi04_02.valueCoding }];
                sum += 5;
            } else if (sum04 >= 13 && sum04 <= 18) {
                NIT_EBI_04.answer = [{ valueCoding: ebi04_03.valueCoding }];
                sum += 0;
            }
        }
        //#endregion

        //#region EBI 05
        if (NIT_EBI_05) {
            const itemEbi05 = Fhir.Questionnaire.GetQuestionnaireItemByLinkId(qBiEx, 'NIT_EBI_05');
            const ebi05_00 = itemEbi05.option.find(o => o.valueCoding && o.valueCoding.code === 'NIT_EBI_05_00');
            const ebi05_01 = itemEbi05.option.find(o => o.valueCoding && o.valueCoding.code === 'NIT_EBI_05_01');
            const ebi05_02 = itemEbi05.option.find(o => o.valueCoding && o.valueCoding.code === 'NIT_EBI_05_02');
            const ebi05_03 = itemEbi05.option.find(o => o.valueCoding && o.valueCoding.code === 'NIT_EBI_05_03');

            const sum05 = this.fieldToIntValue(assessment, 'NIT_SVAs_01');
            switch (sum05) {
                case 0:
                    NIT_EBI_05.answer = [{ valueCoding: ebi05_00.valueCoding }];
                    sum += 15;
                    break;
                case 1:
                    NIT_EBI_05.answer = [{ valueCoding: ebi05_01.valueCoding }];
                    sum += 10;
                    break;
                case 2:
                    NIT_EBI_05.answer = [{ valueCoding: ebi05_02.valueCoding }];
                    sum += 5;
                    break;
                case 3:
                    NIT_EBI_05.answer = [{ valueCoding: ebi05_03.valueCoding }];
                    sum += 0;
                    break;
            }
        }
        //#endregion

        if (NIT_EBI_06) {
            NIT_EBI_06.answer = [{ valueInteger: sum }];
        }

        QuestionnaireResponse.SetAttachedResponse(patient, assessment, biExResponse);
        PatientService.AddQuestionnaireResponse(patient, biExResponse, true);
        PatientService.AddQuestionnaireResponse(patient, assessment, true);


        sum = 0;
        /*** calls getCurrentValue to get the current ordinal value of the responseItem (iE bi02Answer)
         * and if it is not NaN adds it to sum */
        const calcNewSum = (responseItem: any) => {
            let val = this.getCurrentValue(qBiEx, responseItem);
            if (!isNaN(val))
                sum += val;
        };

        /*** function to get the values from the questionnaireresponse items rather than the calculated ones.
         * Doing it this way, questionnaire default values are although included, when some mappings do not happen.
         */
        const calcSums = () => {
            sum = 0;
            calcNewSum(NIT_EBI_00);
            calcNewSum(NIT_EBI_01);
            calcNewSum(NIT_EBI_02);
            calcNewSum(NIT_EBI_03);
            calcNewSum(NIT_EBI_04);
            calcNewSum(NIT_EBI_05);
        };

        calcSums();
        return sum;
    }

    // #region ********* HELPERS *******************************
    private idToIntValue(id: string | any, minValue: number = 0): number {
        if (!id) return 0;
        if (typeof id === "object" && id["valueCoding"] && id["valueCoding"]["code"]) {
            id = id["valueCoding"]["code"];
        }

        const arr = String(id).split('_');
        try {
            let parseIt = arr[arr.length - 1];
            if (parseIt.toUpperCase() === 'NIL') return 0;

            const result = parseInt(parseIt);
            if (isNaN(result)) {
                if (!ConfigService.IsTest)
                    console.warn('WERT KONNTE NICHT GEPARSED WERDEN: "' + id + '"');
                return 0;
            } else return result >= minValue ? result : 0;
        } catch (e) {
            console.warn(e.message);
            return 0;
        }
    }

    protected fieldToIntValue(qr: any, id: string, minValue: number = 0): number {
        try {
            if (!id || !qr)
                return minValue || 0;

            const val = QuestionnaireResponse.GetResponseItemValueByLinkId(qr, id, null);
            if (!val)
                return minValue || 0;

            return this.idToIntValue(val, minValue);
        } catch (e) {
            console.warn(e.message);
            return minValue || 0;
        }
    }

    protected addSum(sum: number, add: number): number {
        if (isNaN(sum)) sum = 0;
        if (!isNaN(add)) sum += add;

        return sum;
    }

    // #endregion ************************************************

    private showPopupForm(questionnaireResponse: any, questionnaire: any, patient: PatientItem,
        updateFlag5: boolean = false, preFieldCalculationFunction?: any, afterClose?: Function) {
        const enc = patient.encounter;
        const dialogSettings = {
            grouplist: false,
            response: questionnaireResponse,
            questionnaire: questionnaire,
            encounter: enc,
            tooold: false,
            haschanges: false,
            dialogService: this.dialogService,
            preFieldCalculationFunction: preFieldCalculationFunction,
            status: questionnaireResponse?.status === fhirEnums.QuestionnaireResponseStatus.inProgress ? this.i18n.tr("in-progress") : this.i18n.tr('validated'),
            saveText: this.i18n.tr("validate"),
            abortText: this.i18n.tr("abort"),
            patientService: PatientService,
            patient: this.patient,
            allowAddNew: false,
            showSelection: false,
            showToolbar: false,
            removeNoToolbarWindow: false,
            data: {
                title: questionnaire.title || questionnaire.name
            }
        };

        return this.dialogService
            .open({ viewModel: QuestionnaireDialogContainer, model: dialogSettings, lock: true })
            .whenClosed(async result => {
                // BasicForm.pageTitle = currentTitle;
                if (!result.wasCancelled) {  // user clicked on "Validate"
                    RuntimeInfo.IsLoading = true;

                    const response = result.output;
                    if (!response.meta) {
                        response.meta = {};
                    }

                    if (!response.meta.lastUpdated) {
                        response.meta.lastUpdated = new Date().toJSON();
                    }

                    if (!response.questionnaire) {
                        if (FhirService.FhirVersion >= 4) {
                            response.questionnaire = `${QuestionnaireService.NitQuestionnairesUrl}/${questionnaire.name}`;
                        } else {
                            response.questionnaire = {
                                reference: `Questionnaire/${questionnaire.id}`
                            };
                        }
                    }

                    try {
                        const resources: any[] = [response];
                        if (updateFlag5) {
                            await PatientItem.EnsureFlags(patient);
                            await PatientItem.ValidateMark5(patient, false);
                            resources.push(patient.flags);
                            this.patientChangeNotifier.notify(patient, patient.flags);
                        }

                        await this.fhirService.bundle(resources, HTTPVerb.put, BundleType.transaction);
                        PatientService.AddQuestionnaireResponse(patient, response, true);
                        await this.analyse(patient, this.currentAssessment, true, true);
                    } finally {
                        RuntimeInfo.IsLoading = false;
                    }

                    // update the response in the result!
                    PatientService.AddQuestionnaireResponse(patient, result.output, true);
                    if (typeof afterClose === 'function')
                        afterClose(result.output);
                }

                BasicForm.pageTitle = this.i18n.tr('analysis');
            })
            .then(() => {
                BasicForm.pageTitle = this.i18n.tr('analysis');
            })
            .catch(e => {
                console.warn(e);
            });
    }

    protected updateRisk(patient: PatientItem, riskName: string, codeValue: any, codeDisplay: string) {
        let system = NitTools.ExcludeTrailingSlash(environment.nursItStructureDefinition);
        system += `/${NitTools.ExcludeLeadingSlash(riskName)}`;
        let existing = patient && patient.currentRisks && patient.currentRisks.prediction ? patient.currentRisks.prediction.find(o => o.outcome && o.outcome.coding && o.outcome.coding[0] && o.outcome.coding[0].system.endsWith(NitTools.ExcludeLeadingSlash(riskName))) : undefined;
        if (!existing) {
            existing = { outcome: {} };
            patient.currentRisks.prediction.push(existing);
        }

        existing.outcome.coding = [{
            system: system,
            code: String(codeValue),
            display: codeDisplay
        }];
    }

    protected async createPopup(patient: PatientItem, linkedToResponse: any, questionnaireName: string,
        updateFlag5: boolean = false, preFieldCalculationFunction?: any, afterClose?: Function) {
        const questionnaire = QuestionnaireService.GetQuestionnaireByNameDirect(questionnaireName);

        let response: any;
        try {
            if (linkedToResponse)
                response = await Fhir.QuestionnaireResponse.GetAttachedResponse(patient, linkedToResponse, questionnaireName);
            else
                response = QuestionnaireService.GetLatestResponseOfType(patient, questionnaire.id, [QuestionnaireResponseStatus.amended, QuestionnaireResponseStatus.completed, QuestionnaireResponseStatus.inProgress]);
        } catch (e) {
            console.warn(e);
        }

        if (!response && questionnaire) {
            response = Fhir.Tools.SubstituteDefaultQRSkeleton(patient, questionnaire.id, QuestionnaireResponseStatus.inProgress);
            // link between demmi <-> assessment
            Fhir.QuestionnaireResponse.LinkResponsesMutual(patient, response, linkedToResponse);

            PatientService.AddQuestionnaireResponse(patient, response, true);
            PatientService.AddQuestionnaireResponse(patient, linkedToResponse, true);

            const resources: any[] = [response, linkedToResponse];
            await this.fhirService.bundle(resources, fhirEnums.HTTPVerb.put, BundleType.transaction);
        }

        await this.showPopupForm(response, questionnaire, patient, updateFlag5, preFieldCalculationFunction, afterClose);
    }

    protected static async reloadSVGImage(svgFile: string) {
        /*        if (!RuntimeInfo.IsIE && !RuntimeInfo.Embedded && this._svgImage) // when this is running on IE we have to load *always* the svg from the server
                    return this._svgImage;
        */
        const httpResponseMessage = await new HttpClient().get(`images/${svgFile}?_=${(new Date()).valueOf()}`);
        const parser = new DOMParser();
        const svgDoc = parser.parseFromString(httpResponseMessage.response, "image/svg+xml");
        this._svgImage = <SVGImageElement>(svgDoc.firstElementChild || svgDoc.documentElement); //  innerHTML = svgDoc.body.innerHTML;

        return this._svgImage;
    }

    protected calculatePoints(assessment: any, svg: SVGImageElement, targetPathId: string) {
        let targetPoints = [];

        const linkIds = {
            /** Pflege Oberkörper, default NIT_SVAs_300 */
            bodyCareUpper: "NIT_SVAs_300",
            /** Pflege Unterkörper, default NIT_SVAs_301 */
            bodyCareLower: "NIT_SVAs_301",
            /** Bewegung, default NIT_SVAs_100 */
            movement: "NIT_SVAs_100",
            /** Ankleiden Oberkörper, default NIT_SVAs_600 */
            dressUpper: "NIT_SVAs_600",
            /** Ankleide Unterkörper, default NIT_SVAs_601 */
            dressLower: "NIT_SVAs_601",
            /** Schmerzen, default NIT_SVAs_202 */
            pain: "NIT_SVAs_202",
            /** Ernährungsstatus, default NIT_SVAs_400 */
            nutritionalState: "NIT_SVAs_400",
            /** Essen, default NIT_SVAs_403 */
            eating: "NIT_SVAs_403",
            /** Trinken, default NIT_SVAs_405 */
            drinking: "NIT_SVAs_405",
            /** Toilettenbenutzung, default NIT_SVAs_500 */
            toiletUse: "NIT_SVAs_500",
            /** Umgang mit Harnausscheidung, default NIT_SVAs_503 */
            handlingUrineExcretion: "NIT_SVAs_503",
            /** Umgang mit Stuhlausscheidung, default NIT_SVAs_506 */
            handlingStoolExcretion: "NIT_SVAs_506",
            /** Atmung, default NIT_SVAs_201 */
            breathing: "NIT_SVAs_201",
            /** Ruhe- und Schlafverhalten, default NIT_SVAs_700 */
            restingAndSleep: "NIT_SVAs_700",
            /** Verhältnis Tag-/Nacht Schlaf, default NIT_SVAs_702 */
            dayNightSleepRatio: "NIT_SVAs_702",
            /** Schlafhygiene, default NIT_SVAs_703 */
            sleepingHygiene: "NIT_SVAs_703",
            /** Hörfähigkeit, default NIT_SVAs_00 */
            hearingAbility: "NIT_SVAs_00",
            /** Sehfähigkeit, default NIT_SVAs_01 */
            visualAbility: "NIT_SVAs_01",
            /** Fähigkeit, sich an einem Gespräch zu beteiligen, default NIT_SVAs_03 */
            communication: "NIT_SVAs_03",
            /** Nähe- und Distanzempfinden, default NIT_SVAs_902 */
            closeAndDistance: "NIT_SVAs_902",
            /** Umgang mit anderen Personen, default NIT_SVAs_1101 */
            interactionWithOthers: "NIT_SVAs_1101",
            /** Bestehende Beziehungen aufrechterhalten, default NIT_SVAs_1203*/
            maintainExistingRelationShips: "NIT_SVAs_1203"
        }

        const cfgLinkIds = ConfigService.cfg?.features?.spider?.linkIds;
        if (cfgLinkIds) {
            for (const key of Object.keys(cfgLinkIds)) {
                linkIds[key] = cfgLinkIds[key];
            }
        } else {
            console.warn('No Config features.spider.linkIds found!\nConsider updating your setup.json.\nusing default values for Spider');
        }

        //#region Körperpflege, g0
        const sum0 = Math.max(
            this.fieldToIntValue(assessment, linkIds.bodyCareUpper, 0), // Pflege OK
            this.fieldToIntValue(assessment, linkIds.bodyCareLower, 0)); // Pflege UK
        targetPoints.push(`p_${sum0}_0`);
        //#endregion

        //#region Bewegung, g1
        const sum1 = this.fieldToIntValue(assessment, linkIds.movement, 0); // Mobilität
        targetPoints.push(`p_${sum1}_1`);
        //#endregion

        //#region An- und Auskleiden, g2
        const sum2 = Math.max(
            this.fieldToIntValue(assessment, linkIds.dressUpper, 0), // Kleidung OK
            this.fieldToIntValue(assessment, linkIds.dressLower, 0)); // Kleidung UK
        targetPoints.push(`p_${sum2}_2`);
        //#endregion

        //#region Schmerzen, g3
        let sum3 = this.fieldToIntValue(assessment, linkIds.pain, 0); // Schmerzen
        switch (sum3) {
            default:
                sum3 = 0;
                break;
            case 1:
            case 2:
            case 3:
                sum3 = 1;
                break;
            case 4:
            case 5:
            case 6:
                sum3 = 2;
                break;
            case 7:
            case 8:
            case 9:
            case 10:
                sum3 = 3;
                break;
        }

        targetPoints.push(`p_${sum3}_3`);
        //#endregion

        //#region Ess- und Trinkverhalten, g4
        const sum4 = Math.max(
            this.fieldToIntValue(assessment, linkIds.nutritionalState, 0), // Ernährungszustand
            this.fieldToIntValue(assessment, linkIds.eating, 0), // Essen
            this.fieldToIntValue(assessment, linkIds.drinking, 0) // Trinken/Flüssigkeitsaufnahme
        );

        targetPoints.push(`p_${sum4}_4`);
        //#endregion

        //#region Ausscheidung, g5
        const sum5 = Math.max(
            this.fieldToIntValue(assessment, linkIds.toiletUse, 0), // Toilettenbenutzung
            this.fieldToIntValue(assessment, linkIds.handlingUrineExcretion, 0), // Umgang Harnausscheidung
            this.fieldToIntValue(assessment, linkIds.handlingStoolExcretion, 0)  // Umgang Stuhlausscheidung
        );

        targetPoints.push(`p_${sum5}_5`);
        //#endregion

        //#region Atmung, g6
        const sum6 = this.fieldToIntValue(assessment, 'NIT_SVAs_201', 0);

        targetPoints.push(`p_${sum6}_6`);
        //#endregion

        //#region Schlafqualität, g7
        const sum7 = Math.max(
            this.fieldToIntValue(assessment, 'NIT_SVAs_700', 0), // Ruhe und Schlafen
            this.fieldToIntValue(assessment, 'NIT_SVAs_702', 0), // Verhältnis Tag-/Nacht Schlaf
            this.fieldToIntValue(assessment, 'NIT_SVAs_703', 0)  // Schlafhygiene
        );
        targetPoints.push(`p_${sum7}_7`);
        //#endregion

        //#region Kommunikation, g8
        const sum8 = Math.max(
            this.fieldToIntValue(assessment, 'NIT_SVAs_00', 0), // Hörfähigkeit
            this.fieldToIntValue(assessment, 'NIT_SVAs_01', 0), // Sehfähigkeit
            this.fieldToIntValue(assessment, 'NIT_SVAs_03', 0)  // Fähigkeit, sich an einem Gespräch zu beteiligen
        );

        targetPoints.push(`p_${sum8}_8`);
        //#endregion

        //#region Beziehungsgestaltung, g9
        const sum9 = Math.max(
            this.fieldToIntValue(assessment, 'NIT_SVAs_902', 0), // Ist Nähe-Distanz-Empfinden krankheitsbedingt beeinflusst?
            this.fieldToIntValue(assessment, 'NIT_SVAs_1101', 0), // Umgang mit anderen Personen problemlos
            this.fieldToIntValue(assessment, 'NIT_SVAs_1203', 0) // Bestehende Beziehungen aufrechterhalten (Bekannte, Verwandte)
        );

        targetPoints.push(`p_${sum9}_9`);
        //#endregion

        this.setSvgPoints(targetPathId, targetPoints, svg);
    }

    //#region Risiko-Sonne
    public currentPage: number;
    public usePages: boolean = true;
    public backButtonSvg: SVGElement;
    public override async DisplayRiskPage(page: number = 0): Promise<SVGImageElement> {
        if (this.currentPage === page)
            return;

        return await this.GetRiskSun(this.patient);
    }

    get imagePath(): string {
        return `images/analyzers/${this._version}/pages/risk_sun`;
    }

    public override async GetRiskSun(patient: PatientItem): Promise<any> {
        window['risk_page_flip'] = async (idx) => {
            // alert(riskName + " => " + page);
            const svg = document.querySelector("#svg_overview");
            if (svg) {
                this.currentPage = idx;
                svg.innerHTML = new XMLSerializer().serializeToString(await this.GetRiskSun(patient));
            }
        };

        if (typeof this.currentPage === "undefined") {
            this.currentPage = 0;
        }

        let svgResult: HttpResponseMessage;
        if (this.usePages) {
            let svgPagePath = `${this.imagePath}/${this.currentPage}.svg`;
            try {
                svgResult = undefined; // just to be sure
                const pathResult = await new HttpClient().createRequest(svgPagePath + "?_=" + new Date().valueOf()).asGet().send();
                if (pathResult.statusCode === 404) {
                    if (this.currentPage === 0)
                        this.usePages = false;
                } else {
                    svgResult = pathResult;
                }
            } catch (ex) {
                console.warn(ex);
            }
        }

        if (!svgResult) {
            svgResult = await new HttpClient().createRequest('images/risk_overview_sempa.svg?_t=' + new Date().valueOf()).asGet().send();
        }

        const div = document.createElement('div');
        div.innerHTML = svgResult.response;
        const svg = div.getElementsByTagName('svg')[0];

        //#region Get the last two riskAssessments
        // http://161.97.139.36:8100/
        // ordered by date. result[0] = latest, result[1] = previous
        let url = `RiskAssessment?encounter=${patient.encounterId}&_count=2`;
        if (!ConfigService.Tiplu.enabled)
            url += '&_sort=-date';

        const riskAssessments = <any[]>await this.fhirService.fetch(url, true, undefined, false);
        //#endregion

        let ra0: any;
        let ra1: any;
        if (riskAssessments && riskAssessments.length >= 1) {
            ra0 = riskAssessments[0];
            ra1 = riskAssessments[1];
            if (!ra1) {
                ra1 = NitTools.Clone(ra0);
            }

            if (ra0?.prediction && ra1?.prediction) {
                const preds0 = ra0.prediction.filter(ra => ra.outcome?.coding?.[0].code);
                const preds1 = ra1.prediction.filter(ra => ra.outcome?.coding?.[0].code);

                for (const pred0 of preds0) {
                    const code0: string = String(pred0.outcome.coding[0].code);
                    const system0: string = String(pred0.outcome.coding[0].system);
                    const aS = system0.split('/');
                    const riskName = `${aS[aS.length - 1]}`;

                    //#region set the circle color. if risk=true->red, risk=no->green, undefined->gray
                    const circleId = `circle_${riskName}`;
                    let circle = <SVGPathElement>svg.querySelector("#" + circleId);

                    if (circle && code0) {
                        let circleColor = '#cccccc';
                        if (code0.toUpperCase() === 'TRUE')
                            circleColor = '#de0000';
                        else if (code0.toUpperCase() === 'FALSE')
                            circleColor = '#35b35e';
                        else console.debug(`Possibly wrong risk <-> circle assignment. Value "${code0}" is not a boolean.\nCheck risk with name: "${riskName}" and Element "${circleId}". Value from Risk is: "${code0}"`);

                        circle.style.fill = circleColor;
                    }
                    //#endregion

                    const pred1 = preds1.find(o => o.outcome.coding[0].system === system0);
                    if (pred1) {
                        const code1: string = String(pred1.outcome.coding[0].code);
                        const arrowId = `arr_${riskName}`;

                        if (code0 && code1 &&
                            !/\D/.test(code0) && /\d/.test(code0) &&
                            !/\D/.test(code1) && /\d/.test(code1)
                        ) // test if NO word Character (\D) and Digits (\d)
                        {
                            const val0 = parseInt(code0);
                            const val1 = parseInt(code1);

                            if (!isNaN(val0) && !isNaN(val1)) {
                                let rotation = 0;
                                let fill = '#cccccc';

                                if (val0 > val1) { // down
                                    rotation = 90;
                                    fill = '#de0000';
                                } else if (val0 < val1) { // up
                                    rotation = 270;
                                    fill = '#35b35e';
                                }

                                const arrow = <SVGPathElement>svg.querySelector("#" + arrowId);
                                if (arrow) {
                                    arrow.style.fill = fill;
                                    arrow.style.transformBox = 'fill-box';
                                    arrow.style.transformOrigin = 'center';
                                    arrow.style.transform = `rotate(${rotation}deg)`;
                                }
                            }
                        }
                    }
                }
            }
        }

        this.processRiskSunValues(svg, patient, ra0, ra1);
        await this.addBackButton(svg);

        return svg; // svgResult.response;
    }

    async addBackButton(targetSvg: SVGSVGElement) {
        if (!this.backButtonSvg) {
            try {
                const arrowResultMessage = await new HttpClient().get(`${this.imagePath}/back_arrow.svg?_=${new Date().valueOf()}`);
                const _div = document.createElement('div');

                _div.innerHTML = arrowResultMessage.response;
                this.backButtonSvg = _div.querySelector('svg');
                // this.backButtonSvg.style.fill = '#80a5ba';
                // this.backButtonSvg.setAttribute('onclick', 'JavaScript:risk_page_flip(0);')
            } catch (ex) {
                console.warn(ex);
                this.backButtonSvg = undefined;
            }
        }

        if (this.backButtonSvg && this.currentPage > 0) {
            const currentBackButtonSvg: SVGElement = <SVGElement>this.backButtonSvg.cloneNode(true);
            targetSvg.appendChild(currentBackButtonSvg);
            /*const translate = targetSvg.createSVGTransform();
            translate.setTranslate(0, targetSvg.getBoundingClientRect().height - currentBackButtonSvg.getBoundingClientRect().height);
            (<SVGPolygonElement>currentBackButtonSvg).transform.baseVal.appendItem(translate); */
        }
    }

    /**
     * Replaces ALL found elements in the given svg with the analyzed values
     * @param svg the svg to replace the values in
     * @param patient the current patient to get the values from
     */
    protected processRiskSunValues(svg: SVGSVGElement, patient: PatientItem, raCurrent: any, raPrevious: any) {
        const updateIndicator = function (element: SVGElement, deg: number, fillColor: string) {
            try {
                element.style.fill = fillColor;
                element.style.transformBox = 'fill-box';
                element.style.transformOrigin = 'center center';
                element.style.transform = 'rotate(' + deg + 'deg)';
            } catch (ex) {
                console.warn(ex);
            }
        };

        const updateIndicatorFromRisks = (riskName: string) => {
            try {
                const svgArrow: SVGPathElement = svg.querySelector(`#arr_${riskName}`);
                if (!svgArrow || !raCurrent || !raPrevious)
                    return;

                const curRisk = raCurrent?.prediction?.find(o => o?.outcome?.coding?.[0]?.system?.endsWith(riskName));
                const curCoding = curRisk ? curRisk.outcome.coding[0] : {
                    code: "0",
                    display: "placeholder for " + riskName
                };

                const prevRisk = raPrevious ? raPrevious.prediction?.find(o => o?.outcome?.coding?.[0]?.system?.endsWith(riskName)) : curRisk;
                const prevCoding = NitTools.IsArray(prevRisk?.outcome?.coding) ? prevRisk.outcome.coding[0] : {
                    code: "0",
                    display: "placeholder for " + riskName
                };

                let curValue = parseInt(curCoding.code);
                if (!curValue || isNaN(curValue)) {
                    curValue = 0;
                }

                let prevValue = parseInt(prevCoding.code);
                if (!prevValue || isNaN(prevValue)) {
                    prevValue = 0;
                }

                const rotation = curValue == prevValue ? 0 : curValue < prevValue ? -90 : 90;
                svgArrow.style.fill = curValue == prevValue ? this.fillGrey : curValue < prevValue ? this.fillGreen : this.fillRed;
                svgArrow.style.transformBox = 'fill-box';
                svgArrow.style.transformOrigin = 'center center';
                svgArrow.style.transform = 'rotate(' + rotation + 'deg)';
            } catch (e) {
                console.warn(e);
            }
        };

        const updateArrowIndicatorFromAssessment = (indicatorSelector: string, linkIdRisk: string) => {
            let indicatorColor = this.fillGrey;
            let indicatorRotation = 0;
            const indicatorPath: SVGPathElement = svg.querySelector(indicatorSelector);

            try {
                if (!indicatorPath) {
                    return;
                }

                const codeCurrent = QuestionnaireResponse.GetResponseItemValueByLinkId(currentAssessment, linkIdRisk, undefined);
                if (!codeCurrent) {
                    console.warn(`QuestionnaireResponse does not contain LinkId "${linkIdRisk}" !`);
                }

                let codePrevious = QuestionnaireResponse.GetResponseItemValueByLinkId(prevAssessment, linkIdRisk, undefined);
                if (!codePrevious) {
                    codePrevious = codeCurrent;
                }

                const intCurrent = QuestionnaireResponse.GetKeyValueAsInteger(codeCurrent);
                const intPrevious = QuestionnaireResponse.GetKeyValueAsInteger(codePrevious);
                if (!isNaN(intCurrent) && !isNaN(intPrevious)) {
                    if (intCurrent < intPrevious) {
                        indicatorColor = this.fillGreen;
                        indicatorRotation = -90;
                    } else if (intCurrent > intPrevious) {
                        indicatorColor = this.fillRed;
                        indicatorRotation = 90;
                    }
                }
            } catch (ex) {
                console.warn(ex);
                indicatorColor = 'transparent'; // do not display the value if something went wrong
            }

            updateIndicator(indicatorPath, indicatorRotation, indicatorColor);
        };

        const assessmentName = patient.getAssessmentName();
        const anamnesis = QuestionnaireService.GetLatestResponseOfName(patient, patient.getAnamnesisName(), [QuestionnaireResponseStatus.completed, QuestionnaireResponseStatus.amended]);
        const questionnaireAssessment = QuestionnaireService.GetQuestionnaireByNameDirect(assessmentName);
        if (!questionnaireAssessment) {
            console.warn(`Questionnaire with name "${assessmentName} not found`);
            return;
        }

        const assessments = QuestionnaireService.GetResponsesOfType(patient, questionnaireAssessment.id, [QuestionnaireResponseStatus.amended, QuestionnaireResponseStatus.completed]);
        if (assessments.length === 0) {
            return;
        }

        const currentAssessment = assessments[assessments.length - 1];

        let prevAssessment = assessments[assessments.length - 2];
        if (!prevAssessment) {
            prevAssessment = NitTools.Clone(currentAssessment);
        }

        //#region alter ihn Jahren
        const eleAlter: SVGTSpanElement = svg.querySelector("#lblAlter tspan");
        if (eleAlter) {
            eleAlter.textContent = patient.years?.toString();
        }
        //#endregion

        const linkIds = {
            /** lebt der Patient mit jemandem im Haushalt, default NIT_PAV_101 */
            livingAloneLinkId: "NIT_PAV_101",
            /** JA for lebt der Patient mit jemandem im Haushalt, default NIT_PAV_101_00 */
            livingAloneYesValue: "NIT_PAV_101_00",
            /** Anzahl der Probleme, default NIT_PAV_100 */
            problemCountLinkId: "NIT_PAV_100",
            /** Mehr als 5 Probleme, default NIT_PAV_100_05 */
            problemCountMoreThan5Value: "NIT_PAV_100_05",
            /** Ist PatientIn zu Hause in allen Bereichen selbstständig?, default NIT_PAV_102 */
            patientIndependantLinkId: "NIT_PAV_102",
            /** JA for Ist PatientIn zu Hause in allen Bereichen selbstständig?, default NIT_PAV_102_00 */
            patientIndependantYesValue: "NIT_PAV_102_00",
            /** Anzahl der med. Probleme bei Aufnahme */
            numberMedicalProblemsLinkId: "NIT_PAV_103",
            /** Mobilität, default NIT_SVAs_100 */
            mobilityLinkId: "NIT_SVAs_100",
            /** Umsetzen - NIT_SVAs_105 */
            changePositionLinkId: "NIT_SVAs_105",
            /** Halten einer stabilen Sitzposition - NIT_SVAs_104 */
            maintainPositionLinkId: "NIT_SVAs_104",
            /** Positionswechsel im Bett - NIT_SVAs_103 */
            changingPositionLinkId: "NIT_SVAs_103",
            /** Feuchtigkeit der Haut - NIT_SVAs_203 */
            skinMoistureLinkId: "NIT_SVAs_203",
            /** Sensorische Wahrnehmung - NIT_SVAs_200*/
            sensoryPerceptionLinkId: "NIT_SVAs_200",
            /** Ernährungszustand - NIT_SVAs_400 */
            nutritionStateLinkId: "NIT_SVAs_400",
            /** Atmung - NIT_SVAs_201 */
            respirationLinkId: "NIT_SVAs_201",
            /** Bewusstseinslage - NIT_SVAs_05 */
            consciousnessLinkId: "NIT_SVAs_05",
            /** Schluckstörung - NIT_SVAs_406 */
            chompingLinkId: "NIT_SVAs_406",
            /** Gangsicherheit - NIT_SVAs_108 */
            aisleSafetyLinkId: "NIT_SVAs_108",
            /** Gleichgewicht - NIT_SVAs_107 */
            balanceLinkId: "NIT_SVAs_107",
            /** Harnableitungssystem - NIT_SVAs_502 */
            urinaryDrainageSystemLinkId: "NIT_SVAs_502",
            /** Erkennen von Risiken / Gefahren - NIT_SVAs_1106 */
            recogniceDangersLinkId: "NIT_SVAs_1106",
            /** Verstehen von Sachverh./Informationen - NIT_SVAs_1103 */
            understandingInformationsLinkId: "NIT_SVAs_1103",
            /** Verstehen v. Aufforderungen - NIT_SVAs_1102 */
            understandingPromptsLinkId: "NIT_SVAs_1102",
            /** Treffen von Entscheidungen - NIT_SVAs_1109 */
            dailyDecisionsLinkId: "NIT_SVAs_1109",
            /** Steuern von mehrschrittigen Alltagshandlung - NIT_SVAs_1108 */
            dailyStepsLinkId: "NIT_SVAs_1108",
            /** Mitteilen von elementaren Bedürfnissen - NIT_SVAs_1107 */
            elementaryNeedsLinkId: "NIT_SVAs_1107",
            /** Erinnern an Ereignisse - NIT_SVAs_1104 */
            rememberingLinkId: "NIT_SVAs_1104",
            /** Erkennen von Personen aus dem näheren Umf. - NIT_SVAs_1105 */
            recognizeKnownPersonsLinkId: "NIT_SVAs_1105",
            /** Wie ist das Verhältnis zwischen Nacht- und Tagschlafes? - NIT_SVAs_702 */
            sleepBalanceLinkId: "NIT_SVAs_702",
            /** Gestaltung des Tagesablaufs und Anpassung - NIT_SVAs_803 */
            dayPlanLinkId: "NIT_SVAs_803",
            /** Ist Nähe-Distanz-Empfinden krankheitsbedingt... - NIT_SVAs_902 */
            closureDistanceRecognizionLinkId: "NIT_SVAs_902",
            /** Sich beschäftigen - NIT_SVAs_800 */
            selfEntertainLinkId: "NIT_SVAs_800",
            /** Orientierung beeinträchtigt - NIT_SVAs_06 */
            orientationLinkId: "NIT_SVAs_06",
            /** Einnahme von Medikamenten - NIT_SVAs_1005 */
            medicationIntakeLinkId: "NIT_SVAs_1005",
            /** Mobilität bei der Aufnahme - NIT_SVAn_02 */
            mobilityAtAdmissionLinkId: "NIT_SVAn_02",
            /** Green Value for Mobilität bei der Aufnahme - NIT_SVAn_02_00*/
            mobilityAtAdmissionGreenValue: "NIT_SVAn_02_00",
            /** Orange/mostly mobile value for Mobilität bei der Aufnahme - NIT_SVAn_02_01*/
            mobilityAtAdmissionOrangeValue: "NIT_SVAn_02_01",
            /** Red/not mobile Value for Mobilität bei der Aufnahme  - NIT_SVAn_02_02*/
            mobilityAtAdmissionRedValue: "NIT_SVAn_02_02",
            /** ungewollter Gewichtsverlust - NIT_SVAn_804 */
            unwantedWeightLossLinkId: "NIT_SVAn_804",
            /** NO ungewollter Gewichtsverlust NIT_SVAn_804_00 */
            unwantedWeightLossNoValue: "NIT_SVAn_804_00",
            /** ungewollter Gewichtsverlust 1-3 - NIT_SVAn_804_01 */
            unwantedWeightLoss1To3Value: "NIT_SVAn_804_01",
            /** ungewollter Gewichtsverlust > 3 - NIT_SVAn_804_02 */
            unwantedWeightLossOver3Value: "NIT_SVAn_804_02",
            /** Abnahme der Nahrungsmenge - NIT_SVAn_803 */
            decreasedFoodAmountLinkId: "NIT_SVAn_803",
            /** GREEN for Abnahme der Nahrungsmenge - NIT_SVAn_803_00 */
            decreasedFoodAmountGreenValue: "NIT_SVAn_803_00",
            /** ORANGE for Abnahme der Nahrungsmenge - NIT_SVAn_803_01 */
            decreasedFoodAmountOrangeValue: "NIT_SVAn_803_01",
            /** RED for Abnahme der Nahrungsmenge - NIT_SVAn_803_02 */
            decreasedFoodAmountRedValue: "NIT_SVAn_803_02",
            /** Akute Krankheit / Stress - NIT_SVAn_900 */
            acuteSicknessOrstressLinkId: "NIT_SVAn_900",
            /** NO Value for Akute Krankheit / Stress - NIT_SVAn_900_00 */
            acuteSicknessOrstressNoValue: "NIT_SVAn_900_00",
            /** YES Value for Akute Krankheit / Stress - NIT_SVAn_900_00 */
            acuteSicknessOrstressYesValue: "NIT_SVAn_900_01",
            /** Neuro-Psycholog. Probleme - NIT_SVAn_903 */
            neuroPsycProblemsLinkId: "NIT_SVAn_903",
            /** GREEN value for Neuro-Psycholog. Probleme - NIT_SVAn_903_00 */
            neuroPsycProblemsGreenValue: "NIT_SVAn_903_00",
            /** ORANGE value for Neuro-Psycholog. Probleme - NIT_SVAn_903_01 */
            neuroPsycProblemsOrangeValue: "NIT_SVAn_903_01",
            /** RED value for Neuro-Psycholog. Probleme - NIT_SVAn_903_02 */
            neuroPsycProblemsRedValue: "NIT_SVAn_903_02",
            /** BMI Value - NIT_SVAn_802 */
            bmiLinkId: "NIT_SVAn_802",
            /** Pain - NIT_SVAs_202 */
            painLinkId: "NIT_SVAs_202",
            /** Diabetes Mellitus (page 0) - NIT_SVAn_806 */
            diabetesMellitusLinkId: "NIT_SVAn_806",
            /** NO for Diabetes Mellitus (page 0) - NIT_SVAn_806_00 */
            diabetesMellitusNoValue: "NIT_SVAn_806_00",
            /** YES for Diabetes Mellitus (page 0) - NIT_SVAn_806_01 */
            diabetesMellitusYesValue: "NIT_SVAn_806_01"
        }

        const cfgLinkIds = ConfigService.cfg?.features?.sun?.linkIds;
        if (cfgLinkIds) {
            for (const key of Object.keys(cfgLinkIds)) {
                linkIds[key] = cfgLinkIds[key];
            }
        } else {
            console.warn("No Config features.sun.linkIds found\nConsider adjusting the setup.json.\nusing default values for Sun");
        }

        //#region lebt der Patient mit jemandem im Haushalt
        const itemHouseHold = QuestionnaireResponse.GetResponseItemByLinkId(anamnesis, linkIds.livingAloneLinkId);
        let lebenValue: string;
        if (itemHouseHold?.answer?.[0]?.valueCoding?.code) {
            lebenValue = itemHouseHold.answer[0].valueCoding.code;
        }

        const checkLeben = <SVGPathElement>svg.querySelector("#checkHousehold");
        if (checkLeben) {
            checkLeben.style.fill = lebenValue === linkIds.livingAloneYesValue ? this.fillGreen : this.fillGrey;
        }
        //#endregion

        //#region Anzahl der Probleme
        const lblProblemCount: SVGTSpanElement = svg.querySelector("#lblProblemsCount tspan");
        if (lblProblemCount) {
            let txt = '?';
            const problemCountItem = QuestionnaireResponse.GetResponseItemByLinkId(anamnesis, linkIds.problemCountLinkId);
            if (problemCountItem.answer && problemCountItem.answer[0] &&
                problemCountItem.answer[0].valueCoding && problemCountItem.answer[0].valueCoding.display) {
                if (problemCountItem.answer[0].valueCoding.code === linkIds.problemCountMoreThan5Value) {
                    txt = '5+';
                } else {
                    txt = problemCountItem.answer[0].valueCoding.display;
                }
            }

            lblProblemCount.textContent = txt;
        }
        //#endregion

        //#region Ist PatientIn zu Hause in allen Bereichen selbstständig?
        const checkSelbstStd: SVGPathElement = svg.querySelector("#checkSelbstStd");
        if (checkSelbstStd) { // NIT_PAV_102_00 - JA
            let selbstStdItem = QuestionnaireResponse.GetResponseItemByLinkId(anamnesis, linkIds.patientIndependantLinkId);
            let selbstStdChecked = false;
            if (selbstStdItem?.answer?.[0]?.valueCoding?.code) {
                selbstStdChecked = selbstStdItem.answer[0].valueCoding.code === linkIds.patientIndependantYesValue;
            }

            checkSelbstStd.style.fill = selbstStdChecked ? this.fillGreen : this.fillGrey;
        }
        //#endregion

        //#region Anzahl der med. Probleme bei Aufnahme
        const lblSectionCount: SVGTSpanElement = svg.querySelector("#lblSectionCount tspan");
        if (lblSectionCount) {
            const sectionCountItem = QuestionnaireResponse.GetResponseItemByLinkId(anamnesis, linkIds.numberMedicalProblemsLinkId);
            let sectionCountText = '-';
            if (sectionCountItem && NitTools.IsArray(sectionCountItem.answer)) {
                sectionCountText = sectionCountItem.answer.length.toString(); //map(o=>o.valueCoding.display).join('');
            }

            lblSectionCount.textContent = sectionCountText;
        }
        //#endregion

        // update the arrows on page 0 from risk values:
        updateIndicatorFromRisks("risk_sturz_sum");

        //Mobilität - NIT_SVAs_100
        updateArrowIndicatorFromAssessment('#indicator_mobility', linkIds.mobilityLinkId);

        //Umsetzen - NIT_SVAs_105
        updateArrowIndicatorFromAssessment('#indicator_change_position', linkIds.changePositionLinkId);

        //Halten einer stabilen Sitzposition - NIT_SVAs_104
        updateArrowIndicatorFromAssessment('#indicator_hold_position', linkIds.maintainPositionLinkId);

        //Positionswechsel im Bett - NIT_SVAs_103
        updateArrowIndicatorFromAssessment('#indicator_change_bed_position', linkIds.changePositionLinkId);

        //Feuchtigkeit der Haut - NIT_SVAs_203
        updateArrowIndicatorFromAssessment('#indicator_skin_moisture', linkIds.skinMoistureLinkId);

        //Sensorische Wahrnehmung - NIT_SVAs_200
        updateArrowIndicatorFromAssessment('#indicator_sensoric', linkIds.sensoryPerceptionLinkId);

        //Ernährungszustand - NIT_SVAs_400
        updateArrowIndicatorFromAssessment('#indicator_nutrition_state', linkIds.nutritionStateLinkId);

        // Atmung - NIT_SVAs_201
        updateArrowIndicatorFromAssessment('#indicator_respiration', linkIds.respirationLinkId);

        // Bewusstseinslage - NIT_SVAs_05
        updateArrowIndicatorFromAssessment('#indicator_concious', linkIds.consciousnessLinkId);

        // Schluckstörung - NIT_SVAs_406
        updateArrowIndicatorFromAssessment('#indicator_chomping', linkIds.chompingLinkId);

        // Gangsicherheit - NIT_SVAs_108
        updateArrowIndicatorFromAssessment('#indicator_gangsicherheit', linkIds.aisleSafetyLinkId);

        // Gleichgewicht - NIT_SVAs_107
        updateArrowIndicatorFromAssessment('#indicator_balance', linkIds.balanceLinkId);

        // Harnableitungssystem - NIT_SVAs_502
        updateArrowIndicatorFromAssessment('#indicator_piss_system', linkIds.urinaryDrainageSystemLinkId);

        // Erkennen von Risiken / Gefahren - NIT_SVAs_1106
        updateArrowIndicatorFromAssessment('#indicator_risks', linkIds.recogniceDangersLinkId);

        // Verstehen von Sachverh./Informationen - NIT_SVAs_1103
        updateArrowIndicatorFromAssessment('#indicator_informations', linkIds.understandingInformationsLinkId);

        // Verstehen v. Aufforderungen - NIT_SVAs_1102
        updateArrowIndicatorFromAssessment('#indicator_aufforderungen', linkIds.understandingPromptsLinkId);

        // Treffen von Entscheidungen - NIT_SVAs_1109
        updateArrowIndicatorFromAssessment('#indicator_daily_decisions', linkIds.dailyDecisionsLinkId);

        // Steuern von mehrschrittigen Alltagshandlung - NIT_SVAs_1108
        updateArrowIndicatorFromAssessment('#indicator_daily_steps', linkIds.dailyStepsLinkId);

        // Mitteilen von elementaren Bedürfnissen - NIT_SVAs_1107
        updateArrowIndicatorFromAssessment('#indicator_elementary_needs', linkIds.elementaryNeedsLinkId);

        // Erinnern an Ereignisse - NIT_SVAs_1104
        updateArrowIndicatorFromAssessment('#indicator_memories', linkIds.rememberingLinkId);

        // Erkennen von Personen aus dem näheren Umf. - NIT_SVAs_1105
        updateArrowIndicatorFromAssessment('#indicator_person_recognition', linkIds.recognizeKnownPersonsLinkId);

        // Wie ist das Verhältnis zwischen Nacht- und Tagschlafes? - NIT_SVAs_702
        updateArrowIndicatorFromAssessment('#indicator_sleep_balance', linkIds.sleepBalanceLinkId);

        // Gestaltung des Tagesablaufs und Anpassung - NIT_SVAs_803
        updateArrowIndicatorFromAssessment('#indicator_day_plan', linkIds.dayPlanLinkId);

        // Ist Nähe-Distanz-Empfinden krankheitsbedingt... - NIT_SVAs_902
        updateArrowIndicatorFromAssessment('#indicator_closure_distance', linkIds.closureDistanceRecognizionLinkId);

        // Sich beschäftigen - NIT_SVAs_800
        updateArrowIndicatorFromAssessment('#indicator_entertainment', linkIds.selfEntertainLinkId);

        // Orientierung beeinträchtigt - NIT_SVAs_06
        updateArrowIndicatorFromAssessment('#indicator_orientation', linkIds.orientationLinkId);

        //#region Einnahme von Medikamenten - NIT_SVAs_1005
        const medicationIndicator: SVGPathElement = svg.querySelector("#indicator_medication_takein");
        if (medicationIndicator) {
            const currentMedicationsItem = QuestionnaireResponse.GetResponseItemByLinkId(currentAssessment, linkIds.medicationIntakeLinkId);
            const previousMedicationsItem = QuestionnaireResponse.GetResponseItemByLinkId(prevAssessment, linkIds.medicationIntakeLinkId);
            let countCurrent = 0;
            let countPrevious = 0;
            let medicationsIndicatorColor = this.fillGrey;
            let medications0otation = 0;
            if (currentMedicationsItem && currentMedicationsItem.answer) {
                countCurrent = currentMedicationsItem.answer.length;
            }

            if (previousMedicationsItem && previousMedicationsItem.answer) {
                countPrevious = previousMedicationsItem.answer.length;
            }

            if (countCurrent === countPrevious) { // same amount of medications take
                // let default values reside
            } else if (countCurrent < countPrevious) { // currently taking lesser meds than previously
                medicationsIndicatorColor = this.fillGreen;
                medications0otation = 90;
            } else if (countCurrent > countPrevious) { // currently taking more meds than previously
                medications0otation = -90;
                medicationsIndicatorColor = this.fillRed;
            }

            updateIndicator(medicationIndicator, medications0otation, medicationsIndicatorColor);
        }
        //#endregion

        //#region Mobilität bei der Aufnahme - NIT_SVAn_02
        const checkMobilAufnahme: SVGElement = svg.querySelector('#check_mobil_aufnahme');
        const alertMobilAufnahme: SVGElement = svg.querySelector('#alert_mobil_aufnahme');
        if (checkMobilAufnahme && alertMobilAufnahme) {
            alertMobilAufnahme.style.display = 'none';

            const itemNIT_SVAn_02 = QuestionnaireResponse.GetResponseItemByLinkId(anamnesis, linkIds.mobilityAtAdmissionLinkId);
            let colorNIT_SVAn_02 = this.fillGrey;

            if (itemNIT_SVAn_02) {
                const valueNIT_SVAn_02 = QuestionnaireResponse.GetResponseItemValue(itemNIT_SVAn_02);
                const displayNIT_SVAn_02 = QuestionnaireResponse.GetResponseItemDisplay(itemNIT_SVAn_02);

                if (valueNIT_SVAn_02) {
                    if (valueNIT_SVAn_02 === linkIds.mobilityAtAdmissionGreenValue) {
                        colorNIT_SVAn_02 = this.fillGreen;
                    } else if (valueNIT_SVAn_02 === linkIds.mobilityAtAdmissionOrangeValue) {
                        // mostly mobile
                        colorNIT_SVAn_02 = this.fillOrange;
                    } else if (valueNIT_SVAn_02 === linkIds.mobilityAtAdmissionRedValue) {
                        // not mobile, display alert sign
                        colorNIT_SVAn_02 = this.fillRed;
                        alertMobilAufnahme.style.display = 'block';
                        checkMobilAufnahme.style.display = 'none';
                    }
                }

                const titleSVAn_02 = svg.querySelector("#title_mobil_aufnahme");
                if (titleSVAn_02) {
                    titleSVAn_02.textContent = displayNIT_SVAn_02;
                }
            }

            updateIndicator(checkMobilAufnahme, 0, colorNIT_SVAn_02);
        }
        //#endregion

        //#region ungewollter Gewichtsverlust - NIT_SVAn_804
        const labelGewichtsverlusst: SVGTSpanElement = svg.querySelector("#label_gewichtsverlusst tspan");
        if (labelGewichtsverlusst) {
            const itemNIT_SVAn_804 = QuestionnaireResponse.GetResponseItemByLinkId(anamnesis, linkIds.unwantedWeightLossLinkId);
            const itemNIT_SVAn_804Value = QuestionnaireResponse.GetResponseItemValue(itemNIT_SVAn_804);
            const itemNIT_SVAn_804Display = QuestionnaireResponse.GetResponseItemDisplay(itemNIT_SVAn_804);

            let itemNIT_SVAn_804Text = this.i18n.tr("N.b.");
            if (itemNIT_SVAn_804Value) {
                switch (itemNIT_SVAn_804Value) {
                    case linkIds.unwantedWeightLossNoValue:
                        itemNIT_SVAn_804Text = this.i18n.tr('no');
                        break;
                    case linkIds.unwantedWeightLoss1To3Value:
                        itemNIT_SVAn_804Text = '1-3';
                        break;
                    case linkIds.unwantedWeightLossOver3Value:
                        itemNIT_SVAn_804Text = '> 3';
                        break;
                }

                labelGewichtsverlusst.textContent = itemNIT_SVAn_804Text;

                const labelGewichtsverlustTitle: SVGTSpanElement = svg.querySelector("#title_gewichtsverlusst");
                if (labelGewichtsverlustTitle) {
                    labelGewichtsverlustTitle.textContent = itemNIT_SVAn_804Display;
                }
            }
        }
        //#endregion

        //#region Abnahme der Nahrungsmenge - NIT_SVAn_803
        const check_nahrungsmenge: SVGPathElement = svg.querySelector('#check_nahrungsmenge');
        const alert_nahrungsmenge: SVGPathElement = svg.querySelector('#alert_nahrungsmenge');
        if (check_nahrungsmenge && alert_nahrungsmenge) {
            check_nahrungsmenge.style.display = 'none';
            alert_nahrungsmenge.style.display = 'none';

            const itemNIT_SVAn_803 = QuestionnaireResponse.GetResponseItemByLinkId(anamnesis, linkIds.decreasedFoodAmountLinkId);
            const itemNIT_SVAn_803_Value = QuestionnaireResponse.GetResponseItemValue(itemNIT_SVAn_803);
            const itemNIT_SVAn_803_Display = QuestionnaireResponse.GetResponseItemDisplay(itemNIT_SVAn_803);

            let color803 = 'transparent';
            if (itemNIT_SVAn_803_Value) {

                const toggleElement = (path: SVGPathElement, show: boolean) => {
                    if (path)
                        path.style.display = show ? 'block' : 'none';
                };
                const toggleCheck = (show: boolean) => {
                    if (check_nahrungsmenge) {
                        check_nahrungsmenge.style.fill = color803;
                        check_nahrungsmenge.parentElement?.setAttribute("title", itemNIT_SVAn_803_Display);
                    }

                    toggleElement(check_nahrungsmenge, show);
                    toggleElement(alert_nahrungsmenge, !show);
                };

                switch (itemNIT_SVAn_803_Value) {
                    case linkIds.decreasedFoodAmountGreenValue: // none
                        color803 = this.fillGreen;
                        toggleCheck(true);
                        break;
                    case linkIds.decreasedFoodAmountOrangeValue: // light
                        color803 = this.fillOrange;
                        toggleCheck(true);
                        break;
                    case linkIds.decreasedFoodAmountRedValue: //strong
                        toggleCheck(false);
                        break;
                }

                const labelNahrungsabnahmeTitle: SVGTitleElement = svg.querySelector("#title_nahrungsabnahme");
                if (labelNahrungsabnahmeTitle) {
                    labelNahrungsabnahmeTitle.textContent = itemNIT_SVAn_803_Display;
                }
            }
        }
        //#endregion

        //#region Akute Krankheit / Stress - NIT_SVAn_900
        const label_stress_JaNain: SVGTSpanElement = svg.querySelector('#label_stress_JaNain tspan');
        let txt = 'n.B.';

        if (label_stress_JaNain) {
            const itemNIT_SVAn_900 = QuestionnaireResponse.GetResponseItemByLinkId(anamnesis, linkIds.acuteSicknessOrstressLinkId);
            if (itemNIT_SVAn_900) {
                const valueNIT_SVAn_900 = QuestionnaireResponse.GetResponseItemValue(itemNIT_SVAn_900);
                const displayNIT_SVAn_900 = QuestionnaireResponse.GetResponseItemDisplay(itemNIT_SVAn_900);

                if (valueNIT_SVAn_900) {
                    if (valueNIT_SVAn_900 === linkIds.acuteSicknessOrstressYesValue) {
                        // problems exist, display alert
                        txt = this.i18n.tr('yes');
                    } else if (valueNIT_SVAn_900 === linkIds.acuteSicknessOrstressNoValue) {
                        // no problems, display green check
                        txt = this.i18n.tr('no');
                    }
                }
            }

            label_stress_JaNain.textContent = txt;
        }
        //#endregion

        //#region Neuro-Psycholog. Probleme - NIT_SVAn_903
        const checkNeuro: SVGElement = svg.querySelector("#check_neuro_problems");
        const alertNeuro: SVGElement = svg.querySelector("#alert_neuro_problems");

        if (checkNeuro && alertNeuro) {
            alertNeuro.style.display = 'none';
            checkNeuro.style.display = 'block';

            const itemNIT_SVAn_903 = QuestionnaireResponse.GetResponseItemByLinkId(anamnesis, linkIds.neuroPsycProblemsLinkId);
            let colorNIT_SVAn_903 = this.fillGrey;

            if (itemNIT_SVAn_903) {
                const displayNIT_SVAn_903 = QuestionnaireResponse.GetResponseItemDisplay(itemNIT_SVAn_903);
                const valueNIT_SVAn_903 = QuestionnaireResponse.GetResponseItemValue(itemNIT_SVAn_903);
                colorNIT_SVAn_903 = this.fillGreen;

                switch (valueNIT_SVAn_903) {
                    case linkIds.neuroPsycProblemsGreenValue:
                        // none - green check
                        colorNIT_SVAn_903 = this.fillGreen;
                        break;
                    case linkIds.neuroPsycProblemsOrangeValue:
                        // light - orange check
                        colorNIT_SVAn_903 = this.fillOrange;
                        break;
                    case linkIds.neuroPsycProblemsRedValue:
                        colorNIT_SVAn_903 = this.fillRed;
                        // strong - display "!"
                        alertNeuro.style.display = 'block';
                        checkNeuro.style.display = 'none';
                        break;
                }

                checkNeuro.style.fill = colorNIT_SVAn_903;

                // update on page 0
                if (valueNIT_SVAn_903 && valueNIT_SVAn_903 !== linkIds.neuroPsycProblemsGreenValue) {
                    colorNIT_SVAn_903 = this.fillRed;
                }

                const titleNeuro = svg.querySelector("#title_neuro_problems");
                if (titleNeuro) {
                    titleNeuro.textContent = displayNIT_SVAn_903;
                }
            }

            //updateIndicator(checkNeuro, 0, colorNIT_SVAn_903);
        }
        //#endregion

        //#region BMI - NIT_SVAn_802
        const labelBMI: SVGTSpanElement = svg.querySelector('#label_bmi');
        if (labelBMI) {
            labelBMI.textContent = '-';
            const bmiItem = QuestionnaireResponse.GetResponseItemByLinkId(anamnesis, linkIds.bmiLinkId);
            if (bmiItem) {
                const bmiValue: number = QuestionnaireResponse.GetResponseItemValue(bmiItem);
                if (bmiValue) {
                    labelBMI.textContent = bmiValue.toFixed(1);
                }
            }
        }
        //#endregion

        //#region Pain - NIT_SVAs_202
        updateArrowIndicatorFromAssessment('#arr_risk_pain_sum', linkIds.painLinkId); // update Arrow

        let valueNIT_SVAs_202 = QuestionnaireResponse.GetResponseItemValueByLinkId(currentAssessment, linkIds.painLinkId, `${linkIds.painLinkId}_999`);
        valueNIT_SVAs_202 = QuestionnaireResponse.GetKeyValueAsInteger(valueNIT_SVAs_202);
        if (typeof valueNIT_SVAs_202 === "number") {
            const painOuter: SVGPathElement = svg.querySelector('#circle_risk_pain');
            if (painOuter) {
                let outerColor = this.fillGrey;
                if (valueNIT_SVAs_202 < 999) { // 999 should be the default value and indicates, that no value could be retrieved
                    if (valueNIT_SVAs_202 == 0) {
                        outerColor = this.fillGreen;
                    } else if (valueNIT_SVAs_202 > 0 && valueNIT_SVAs_202 <= 5) {
                        outerColor = this.fillOrange;
                    } else if (valueNIT_SVAs_202 > 5) {
                        outerColor = this.fillRed;
                    }
                }

                painOuter.style.fill = outerColor;
            }

            // gradient fill template is located in #inner_pain_fill
            const gradientFillElement: SVGElement = svg.querySelector("#inner_pain_fill");
            if (gradientFillElement) {
                const fillGradient = '#80a5ba'; // gradientFillElement.style.fill;

                for (let i = 0; i < valueNIT_SVAs_202 + 1; i++) {
                    const grad: SVGElement = svg.querySelector(`#inner_pain_${i}`);
                    if (grad) {
                        grad.style.fill = fillGradient;
                    }
                }
            }
        }
        //#endregion

        //#region Diabetes Mellitus (page 0) - NIT_SVAn_806
        const txtDiabetes: SVGElement = svg.querySelector("#txt_Diabetes__Mellitus");
        if (txtDiabetes) {
            // default equals NIT_SVAn_806_02 - aka unknown
            let diabetesColor = this.fillGrey;
            let diabetesText = 'N.b.';

            const NIT_SVAn_806 = QuestionnaireResponse.GetResponseItemValueByLinkId(anamnesis, linkIds.diabetesMellitusLinkId);
            if (NIT_SVAn_806 === linkIds.diabetesMellitusNoValue) {
                diabetesText = this.i18n.tr('no');
                diabetesColor = this.fillGreen;
            } else if (NIT_SVAn_806 === linkIds.diabetesMellitusYesValue) {
                diabetesText = this.i18n.tr('yes');
                diabetesColor = this.fillRed;
            }

            txtDiabetes.textContent = diabetesText;
            txtDiabetes.style.fill = diabetesColor;
        }
        //#endregion

        //#region update PAV Sum display
        const pavTxt = svg.querySelector('#txt_pav_k tspan') || svg.querySelector('#txt_pav_k');
        if (pavTxt) {
            const riskPav = raCurrent?.prediction?.find(o => o.outcome?.coding && o.outcome.coding.find(c => c.system.endsWith('risk_pav')));
            if (riskPav?.outcome?.coding[0] && riskPav.outcome?.coding[0].code) {
                pavTxt.textContent = riskPav.outcome?.coding[0].code;
            }
        }
        //#endregion
    }

    public override async GetSPISpider(patient: PatientItem): Promise<SVGImageElement> {
        let svg: SVGImageElement = await AnalyzerSEMPA.reloadSVGImage('risk_chart_sempa.svg');

        function togglePath(pathId: string, show: boolean) {
            const path: SVGPathElement = svg.querySelector('#' + pathId);
            if (path) {
                path.setAttribute('display', show ? 'inline' : 'none');
                path.style.display = show ? 'inline' : 'none';
            }
        }

        const assessmentConfig = ConfigService.GetFormSettings('assessment');
        if (assessmentConfig?.questionnaireName) {
            const questionnaire = QuestionnaireService.GetQuestionnaireByNameDirect(assessmentConfig.questionnaireName);
            const assessments = QuestionnaireService.GetResponsesOfType(patient, questionnaire?.id, [QuestionnaireResponseStatus.amended, QuestionnaireResponseStatus.completed]);
            const assessment = assessments[assessments.length - 1];
            if (assessment) {
                if (assessments[assessments.length - 2]) {
                    try {
                        const previous = assessments[assessments.length - 2];
                        if (previous) {
                            this.calculatePoints(previous, svg, 'pathPrevious');
                            togglePath('pathPrevious', true);
                        } else
                            togglePath('pathPrevious', false);
                    } catch (e) {
                        console.warn(e.message);
                        togglePath('pathPrevious', false);
                    }
                } else {
                    togglePath('pathPrevious', false);
                }

                try {
                    this.calculatePoints(assessment, svg, 'pathCurrent');
                    togglePath('pathCurrent', true);
                } catch (e) {
                    console.warn(e.message);
                    togglePath('pathCurrent', false);
                }

            } else {
                togglePath('pathCurrent', false);
                togglePath('pathPrevious', false);
            }
        }
        return svg;
    }

    public override getNCSSpiderTextHtml(patient: PatientItem): string {
        try {
            let ncsA = '-';
            let ncsP = '-';
            const ncsaRisk = this.findRisk(patient, "/risk_ncsa_overview");
            const ncspRisk = this.findRisk(patient, "/risk_ncsp");
            if (ncsaRisk?.display) {
                ncsA = ncsaRisk.display;
            }

            if (ncspRisk && ncspRisk.display) {
                ncsP = ncspRisk.display;
            }


            // add the NCA-S value
            const ncspTitle = this.i18n.tr('ncsp_spider_title');
            const ncsaTitle = this.i18n.tr('ncsa_spider_title');
            const ncspTitleShort = this.i18n.tr('ncsp_spider_title_short');
            const ncsaTitleShort = this.i18n.tr('ncsa_spider_title_short');
            const ncsADiv = `<div class="col-xs-6">
                                        <label class="hidden visible-xl-inline">${ncsaTitle}:</label>
                                        <label class="hidden visible-xs-inline visible-sm-inline visible-md-inline visible-lg-inline">${ncsaTitleShort}:</label>
                                         <span>${ncsA}</span>
                                    </div>`;

            const ncsPDiv = `<div class="col-xs-6 text-right">
                                        <label class="hidden visible-xl-inline">${ncspTitle}:</label>
                                        <label class="hidden visible-xs-inline visible-sm-inline visible-md-inline visible-lg-inline">${ncspTitleShort}:</label>
                                        <span>${ncsP}</span>
                                    </div>`;

            return ncsADiv + ncsPDiv;
        } catch (e) {
            console.warn(e);
            return `<span><b>${e}</b></span>`;
        }
    }

    protected setSvgPoints(polyName: string, targetPoints: string[], svg: SVGImageElement) {
        let poly: SVGPathElement = svg.querySelector('#' + polyName);
        if (!poly) {
            /* if (polyName === "pathCurrent") {
                polyName = "path_current";
            } else if (polyName === 'pathPrevious') {
                polyName = "path_previous";
            } */

            poly = svg.querySelector('#' + polyName);
            if (!poly) {
                console.warn(`Poly "${polyName}" not found!`);
                return;
            }
        }

        let d: string = "";

        for (let i = 0; i < targetPoints.length; i++) {
            let pointElement = <SVGEllipseElement>svg.querySelector(`#${targetPoints[i]}`);

            if (!pointElement) {
                // set center point as default
                let [p, value, group] = targetPoints[i].split('_');
                pointElement = <SVGEllipseElement>svg.querySelector('#p_0');

                // maybe a value > 3 has been specified, so fall back to the #p_3_* value
                if (value && group) {
                    if (parseInt(value) > 3) {
                        value = '3';
                    } else if (parseInt(value) < 0) {
                        value = '0';
                    }

                    const newSelector = `#p_${value}_${group}`;
                    const tmp = <SVGEllipseElement>svg.querySelector(newSelector);
                    if (tmp)
                        pointElement = tmp;
                    else
                        console.warn(`Point #${targetPoints[i]} --converted--> ${newSelector} not found!`);
                }
            }

            if (pointElement)
                d += `${i === 0 ? '' : 'L'} ${pointElement.cx.baseVal.value},${pointElement.cy.baseVal.value} `;
        }

        d = `m ${d.trim()} z`;

        poly.setAttribute('d', d);
    }

    private substituteMailString(mailString: string) {
        mailString = mailString.replace(/\\n/gi, '%0A'); // replace newline with %0A in the mail body

        //#region replace %PATIENTNAME% with the patients display name
        mailString = mailString.replace(/%PATIENTNAME%/gi, this.patient.display);
        //#endregion

        //#region old-style-replacing
        let patientId = this.patient.id;
        if (this.patient.identifier) {
            let ident = this.patient.identifier.find(o => o.use === "official");
            if (!ident) ident = this.patient.identifier.find(o => o.value);
            if (ident) patientId = ident.value;
        }

        let born: any = new Date(this.patient.birthDate);
        born = `${NitTools.ToString((<Date>born).getDate(), 2, '0')}.${NitTools.ToString((<Date>born).getMonth() + 1, 2, '0')}.${(<Date>born).getFullYear()}`;

        let givenName = '';
        let familyName = '';
        if (this.patient.name) {
            let name = this.patient.name.find(o => o.use === 'official');
            if (!name) name = this.patient.name.find(o => o.use === 'usual');
            if (!name) name = this.patient.name.find(o => o.family);

            if (name) {
                try {
                    familyName = name.family;
                    givenName = name.given?.join(', ');
                } catch (e) {
                    console.warn(e.message || e);
                }
            }
        }

        mailString = mailString.replace("%USER%", encodeURIComponent(UserService.UserName))
            .replace("%PATIENTID%", encodeURIComponent(patientId))
            .replace("%ENCOUNTERID%", encodeURIComponent(this.patient.encounterId))
            .replace("%BORN%", born)
            .replace("%PATIENTFAMILY%", encodeURIComponent(familyName))
            .replace("%PATIENTGIVEN%", encodeURIComponent(givenName));
        //#endregion

        //#region replace %PATIENTID% with the patient identifier
        let patientIdentifier = '-';
        const patientIdentifierSystem = this.analyserConfig.settings["patientIdentifierSystem"];
        if (!patientIdentifierSystem) {
            console.warn('No "patientIdentifierSystem" has been specified in forms->route="analysis"->settings->patientIdentifierSystem\n'
                + ' this settings should contain the identifier system used in the patient identifiers to find the patients Identifier (not to mess up with case-identifier!) like "patientNumber".');
        } else {
            if (NitTools.IsArray(this.patient.identifier)) {
                let ident = this.patient.identifier.find(o => o.value && o.system?.indexOf('patientNumber') > -1);
                if (ident)
                    patientIdentifier = ident.value;
            }
        }

        mailString = mailString.replace(/%PATIENTID%/gi, patientIdentifier);
        //#endregion

        //#region replace %PATIENTBORN% with patient birthdate and %WARDNAME% with the current ward
        let bDay = this.patient.birthDate ? moment(this.patient.birthDate).format(RuntimeInfo.DateFormat) : '-';
        mailString = mailString.replace(/%PATIENTBORN%/gi, bDay);

        mailString = mailString.replace(/%WARDNAME%/gi, this.patient.ward || '-');
        //#endregion

        //#region replace %CASEID% with the encounter identifier
        let caseId = '-';
        let caseIdentifierSystem = this.analyserConfig.settings["caseIdentifierSystem"];
        if (!caseIdentifierSystem) {
            console.warn('No "caseIdentifierSystem" has been specified in forms->route="analysis"->settings->caseIdentifierSystem\n'
                + ' this settings should contain the identifier system used in the encounter (like "visitNumbers").');
        }

        if (caseIdentifierSystem && NitTools.IsArray(this.patient.encounter.identifier)) {
            const ident = this.patient.encounter.identifier.find(o => o.system && o.value && o.system.endsWith(caseIdentifierSystem));
            if (ident)
                caseId = ident.value;
        }

        mailString = mailString.replace(/%CASEID%/gi, caseId);
        //#endregion

        //#region replace %ANAMNESISDATE% with the date of _anamnesis.authored

        const assessment = this.patient.latestAssessment ?? QuestionnaireService.GetLatestResponseOfName(this.patient, this.assessmentName, [QuestionnaireResponseStatus.amended, QuestionnaireResponseStatus.completed]);
        mailString = mailString.replace(/%ANAMNESISDATE%/gi, moment(this._anamnesis.authored).format(RuntimeInfo.DateFormat));
        mailString = mailString.replace(/%ASSESSMENTDATE%/gi, moment(assessment?.authored).format(RuntimeInfo.DateFormat));
        //#endregion

        //#region replace %USERDISPLAY% with the current user display
        let name = UserService.UserLastName;
        if (UserService.UserFirstName) name += ', ' + UserService.UserFirstName;
        if (!name) name = UserService.UserName;
        if (!name) name = '-';
        mailString = mailString.replace(/%USERDISPLAY%/gi, name);
        //#endregion

        // mailString = encodeURIComponent(mailString);

        return encodeURIComponent(mailString);
    }

    private async confirmMailHasBeenSendDialog(): Promise<boolean> {
        const message = this.i18n.tr("sempa_diabetes_confirm_message");
        const title = this.i18n.tr("confirm");
        return new Promise<boolean>((resolve, reject) => {
            this.dialogService
                .open({
                    viewModel: Prompt,
                    model: {
                        message: message,
                        title: title,
                        showNo: true,
                        yesText: translations.translate('yes'),
                        noText: translations.translate('no'),
                        headerClass: '',
                        headerCorrospondingClass: ''
                    },
                    lock: true
                })
                .whenClosed(r => {
                    resolve(!r.wasCancelled);
                })
                .catch(e => {
                    resolve(false);
                });
        });
    }

    private getDiabetesButtonText(_anamnesis: any, _assessment: any, patient: PatientItem): any[] {
        if (!_assessment || !_anamnesis || !patient) return;

        const diabetesItemLinkId = 'NIT_SVAn_806';
        const diabetesItem = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(_anamnesis, diabetesItemLinkId);
        if (!diabetesItem) return;

        let button;
        let confirm;
        this.analyserConfig ??= ConfigService.GetFormSettings('analysis');
        const diabetesObject = this.analyserConfig.settings["diabetes"];

        let diabetesValue = undefined;
        let diabetesButton = '';
        let diabetesDisplay = '?';
        let diabetesMailAdress = this.analyserConfig.settings["diabetesMailAdress"];
        let diabetesMailSubject = this.analyserConfig.settings["diabetesMailSubject"];
        let diabetesMailBody = this.analyserConfig.settings["diabetesMailBody"];
        let outComeColumnText = '';

        let color;
        if (diabetesItem.answer && diabetesItem.answer[0] && diabetesItem.answer[0].valueCoding) {
            const coding = diabetesItem.answer[0].valueCoding;
            if (coding) {
                diabetesValue = coding.code;
                color = diabetesValue === "NIT_SVAn_806_01" ? true : undefined;
                if (coding.display) {
                    diabetesDisplay = coding.display;
                } else {
                    const questionnaireAnamnesis = QuestionnaireService.GetQuestionnaireDirect(_anamnesis?.questionnaire);
                    if (questionnaireAnamnesis) {
                        const item = Fhir.Questionnaire.GetQuestionnaireItemByLinkId(questionnaireAnamnesis, diabetesItemLinkId);
                        if (item && NitTools.IsArray(item.option)) {
                            const option = item.option.find(o => o.valueCoding && o.valueCoding.code === coding.code);
                            if (option && option.valueCoding && option.valueCoding.display) {
                                diabetesDisplay = option.valueCoding.display;
                            }
                        }
                    }
                }
            }
        }

        if (diabetesValue !== 'NIT_SVAn_806_01') { // when value != yes/ja or one of the config settings has not been provided then do not display the button(s)
            diabetesButton = '&nbsp;';
            outComeColumnText = '&nbsp;';
        } else {
            let mailSend = false;

            if (patient.flags) {
                const sendingDateSystem = `${NitTools.ExcludeTrailingSlash(SystemHeaders.vendorBase)}/StatusInfo/diabetes-mail-sending-date`;
                const sendingUserSystem = `${NitTools.ExcludeTrailingSlash(SystemHeaders.vendorBase)}/StatusInfo/diabetes-mail-sending-user`;
                let flagDate = patient.flags.code.coding.find(o => o.system && o.system === sendingDateSystem);
                let flagUser = patient.flags.code.coding.find(o => o.system && o.system === sendingUserSystem);

                if (flagUser && flagUser) { // display the values from the risk
                    confirm = outComeColumnText = `${flagDate.display}, ${flagUser.display}`;
                    mailSend = true;
                } else { // not yet send confirmed, so add a button to confirm that the mail has been send by the user
                    if (typeof diabetesObject === "object") {
                        diabetesButton = '';
                        outComeColumnText = '';
                        for (const cfg of diabetesObject) {
                            [button, confirm] = this.createMailButtons(<IMailSetting>cfg);
                            diabetesButton += button;
                            outComeColumnText += confirm;
                        }
                    } else {
                        //#region this is the old version

                        //#region output some warning messages if not correctly configured
                        if (!diabetesMailAdress) {
                            console.warn('No mail-adress specified for diabetes info.\n' +
                                '  This should be placed in config as forms->route="analysis"->settings->diabetesMailAdress\n' +
                                ' it could contain CC and BCC. Example: diabetes@khporz.de?cc=CC@khporz.de&bcc=BCC@khporz');
                        }

                        if (!diabetesMailSubject) {
                            console.warn('No mail-subject specified for diabetes info.\n' +
                                '  This should be placed in config as forms->route="analysis"->settings->diabetesMailSubject\n' +
                                '  Example: Diabetes Mellitus Information %PATIENTNAME%');
                        }

                        if (!diabetesMailBody) {
                            console.warn('No mail-body specified for diabetes info.\n' +
                                '  This should be placed in config as forms->route="analysis"->settings->diabetesMailBody' +
                                '  Example: \n' +
                                '  "Sehr geehrte Damen und Herren, wir haben einen Diabetes Fall zu melden für den Patienten %PATIENTNAME%, geb. %PATIENTBORN%, derzeit auf Station %WARDNAME% mit unserer PatientenId: %PATIENTID% (Fall Nummer %CASEID%). Der Befund wurde erhoben am %ANAMNESISDATE%. Mit freundlichen Grüßen, %USERDISPLAY%.",\n\n' +
                                ' Possible substistutions arr: %PATIENTNAME%, %PATIENTID%, %PATIENTBORN%, %WARDNAME%, %CASEID%, %ANAMNESISDATE%, %USERDISPLAY%');
                        }
                        //#endregion

                        if (diabetesMailAdress && diabetesMailSubject && diabetesMailBody) {
                            console.warn("Old Version of Sempa Mail Settings. Please update the setup to use the new variant in settings.diabetes!");
                            const txt = this.i18n.tr("sempa_diabetes_confirm_send");
                            outComeColumnText = `<button onclick="window.confirmDiabetesMail()" class="btn-confirm-diabetes-mail" style="float:right" type="button">${txt}</button>`;

                            window["confirmDiabetesMail"] = async () => {
                                const confirmResult = await this.confirmMailHasBeenSendDialog();

                                //#region store the send-mail information in the flags
                                if (confirmResult && patient.flags && patient.flags.code) {
                                    try {
                                        RuntimeInfo.IsLoading = true;
                                        if (!NitTools.IsArray(patient.flags.code.coding)) patient.flags.code.coding = [];

                                        //#region create/update date flag
                                        if (!flagDate) {
                                            flagDate = {
                                                system: sendingDateSystem
                                            };

                                            patient.flags.code.coding.push(flagDate);
                                        }

                                        flagDate.code = moment(new Date()).toJSON();
                                        flagDate.display = moment(new Date()).format(RuntimeInfo.DateTimeFormat);
                                        //#endregion

                                        //#region create/update user flag
                                        if (!flagUser) {
                                            flagUser = {
                                                system: sendingUserSystem
                                            };

                                            patient.flags.code.coding.push(flagUser);
                                        }

                                        flagUser.code = UserService.UserName;
                                        flagUser.display = `${UserService.UserLastName}, ${UserService.UserFirstName}`;
                                        //#endregion

                                        await this.fhirService.update(patient.flags);
                                        if (!ConfigService.Debug)   // remove the function, because it is potential dangerous
                                            delete window["confirmDiabetesMail"];

                                        //#region hide the confirm-button and display instead the date/user
                                        $(".btn-confirm-diabetes-mail").addClass('hidden');
                                        outComeColumnText = `${flagDate.display}, ${flagUser.display}`;
                                        $(".btn-confirm-diabetes-mail").parent().append('<span>' + outComeColumnText + '</span>');

                                        const sndButton = $(".btn-send-diabetes-mail")[0];
                                        if (sndButton) {
                                            sndButton.classList.add('disabled', 'readonly');
                                            sndButton.style.opacity = '0.5';
                                        }
                                        //#endregion
                                    } catch (e) {
                                        console.warn(e);
                                    } finally {
                                        RuntimeInfo.IsLoading = false;
                                    }
                                }
                                //#endregion
                            };

                            //#region create the sending button
                            if (!mailSend) { // not needed to do all this when the mail has been marked to be send
                                diabetesMailBody = this.substituteMailString(diabetesMailBody);
                                diabetesMailSubject = this.substituteMailString(diabetesMailSubject);
                            }

                            const btn = document.createElement('a');
                            btn.setAttribute('style', `overflow: hidden; text-overflow: ellipsis; opacity: ${mailSend ? '0.5' : '1.0'}`);
                            btn.classList.add('btn', 'btn-primary', 'width-100', 'btn-send-diabetes-mail');
                            if (mailSend) {
                                btn.classList.add('disabled', 'readonly');
                            } else {
                                let divider = '?';
                                if (diabetesMailAdress.indexOf('?') > -1) {
                                    divider = '&';
                                }

                                btn.setAttribute('href', `mailto:${diabetesMailAdress}${divider}subject=${diabetesMailSubject}&body=${diabetesMailBody}`);
                            }

                            let buttonText = this.i18n.tr('sempa_diabetes_button_text');
                            if (buttonText === 'sempa_diabetes_button_text') {
                                buttonText = 'Informationsmail senden';
                            }

                            btn.innerHTML = buttonText;
                            diabetesButton = btn.outerHTML;
                            //#endregion
                        }
                        //#endregion
                    }
                }
            }
        }

        return [diabetesDisplay, diabetesButton, outComeColumnText, color];
    }

    protected getButtonText(onclick: string, buttonText: string, route: string) {
        if (!onclick.endsWith('()'))
            onclick += '()';

        let result = `<div style="width: 100%; white-space: nowrap">`;
        if (onclick) {
            result += `<div style="width: 66%; display: inline-block">
                            <button class="btn btn-primary width-100" type="button"
                            style="overflow: hidden; text-overflow: ellipsis;"                             
                            onclick="${onclick};">${buttonText}</button>
                        </div>`;
        }

        if (ReportService.ReportServer) {
            result += `<div style="width: 33%; display: inline-block">
                    <button type="button" class="btn btn-primary" onclick="printSubReport('${route}');"
                        style="border-left:none;">
                        <span><i class="mdi mdi-print"></i></span>
                        <span class="hidden-mini">${this.i18n.tr('print')}</span>                                
                    </button>
                </div>`;
        }

        result += '\n</div>';

        return result;
    }

    // return the /!\ message if not validated
    protected getWarningMessage(response: any) {
        if (response) {
            return response.status === 'in-progress' ?
                ` <span class="not-validated" title="${this.i18n.tr('not_validated_title')}"> <i class="mdi mdi-warning"></i></span>`
                : '';
        } else {
            return ` <span class="not-validated" title="${this.i18n.tr('not_existent_title')}"> <i class="mdi mdi-warning"></i></span>`;
        }
    }

    protected currentAssessment: any;

    private getPatientFlag(system: string): any {
        if (!this.patient || !this.patient.flags || !this.patient.flags.code || !NitTools.IsArray(this.patient.flags.code.coding))
            return undefined;

        let flagDate = this.patient.flags.code.coding.find(o => o.system && o.system.endsWith(`/${system}`));
        return flagDate;
    }

    private createFlag(system: string, value: string, display: string): any {
        if (!this.patient || !this.patient.flags || !this.patient.flags.code) return undefined;
        if (!this.patient.flags.code.coding) this.patient.flags.code.coding = [];
        const sendingDateSystem = `${NitTools.ExcludeTrailingSlash(SystemHeaders.vendorBase)}/StatusInfo/${system}`;

        let flagDate = this.getPatientFlag(system);
        if (!flagDate) {
            flagDate = {
                system: sendingDateSystem,
                display: display
            };

            this.patient.flags.code.coding.push(flagDate);
        }

        flagDate.code = value;

        return flagDate;
    }

    createMailButtons(mailInfo: IMailSetting): string[] {
        let btnSendMail: string = '';
        let btnConfirm: string = '';

        if (mailInfo.to && mailInfo.mailBody && mailInfo.title && mailInfo.buttonId) {
            const subject = this.substituteMailString(mailInfo.mailSubject);
            const body = this.substituteMailString(mailInfo.mailBody);
            const buttonText = this.i18n.tr(mailInfo.title);
            const id = mailInfo.buttonId;
            const btnId = 'btn_' + mailInfo.buttonId + '_' + NitTools.UidName();
            const mailSendAt = this.getPatientFlag('mail-' + id + '-sending-date');
            const mailSendBy = this.getPatientFlag('mail-' + id + '-sending-user');

            let isEnabled = typeof mailSendAt === "undefined" || typeof mailSendBy === "undefined"; // let send mail if *one* of the props is not flagged
            const divider = mailInfo.to.indexOf('?') > -1 ? '&' : '?';

            btnSendMail += `<a `;
            if (isEnabled)
                btnSendMail += `href="mailto:${mailInfo.to}${divider}subject=${subject}&body=${body}" `;
            else
                btnSendMail += `style="opacity:0.5;" `;

            btnSendMail += `class="btn btn-primary w-100 mail-send-info ${isEnabled ? '' : 'disabled readonly'}" id="${btnId}"><span>${buttonText}</span></a>`;

            if (isEnabled) {
                const clickName = `${btnId}_click`;
                const txt = this.i18n.tr("sempa_diabetes_confirm_send");
                const patient = this.patient;
                btnConfirm += `<div style="display: block; width: 100%; text-align: right; height: 34px">\
                                                <button id="${btnId}_confirm" class="btn btn-secondary btn-confirm-diabetes-mail " onclick="${clickName}('${id}', '${clickName}', '${btnId}');">\
                                                    <span>${txt}</span>\
                                                    <i class="mdi mdi-check"></i>
                                                </button>\
                                            </div>`;

                ////#region the callback when the "has been send" button is clicked
                window[clickName] = async (id, callback, button) => {
                    const confirmResult = await this.confirmMailHasBeenSendDialog();

                    //#region store the send-mail information in the flags
                    if (confirmResult && patient.flags && patient.flags.code) {
                        try {
                            RuntimeInfo.IsLoading = true;
                            const flagDate = this.createFlag('mail-' + id + '-sending-date', moment(new Date()).toJSON(), moment(new Date()).format(RuntimeInfo.DateTimeFormat));
                            const flagUser = this.createFlag('mail-' + id + '-sending-user', UserService.UserName, `${UserService.UserLastName}, ${UserService.UserFirstName}`);

                            await this.fhirService.update(patient.flags);

                            if (!ConfigService.Debug)   // remove the function, because it is potential dangerous
                                delete window[callback];

                            //#region hide the confirm-button and display instead the date/user
                            const outComeColumnText = `${flagDate.display}, ${flagUser.display}`;
                            let $button = $(`#${button}`);
                            if ($button) {
                                const btn = $button[0];
                                if (btn) {
                                    // change the mail button
                                    btn.classList.add('disabled', 'readonly');
                                    btn.style.opacity = '0.5';
                                    btn.removeAttribute("href");
                                }
                            }

                            $button = $(`#${button}_confirm`);
                            $button.parent().append('<span class="mail-send-info">' + outComeColumnText + '</span>');
                            $button.remove();
                            //#endregion
                        } catch (e) {
                            console.warn(e);
                        } finally {
                            RuntimeInfo.IsLoading = false;
                        }
                    }
                    //#endregion
                };
                //#endregion

            } else {
                btnConfirm += `<span class="mail-send-info">${mailSendAt.display}, ${mailSendBy.display}</span>`;
            }
        }

        return [btnSendMail, btnConfirm];
    }

    //#endregion

    protected generateHtmlHeaders(values: ISempaResult): string {
        if (!values) {
            const msg = "No values provided for generating Html Headers";
            if (ConfigService.Debug)
                console.warn(msg);

            return `<div data-reason="${msg}"></div>`;
        }

        window["reRunAnalysis"] = async () => {
            await this.analyse(this.patient, this.patient.latestAssessment, true, true);
        };

        let resultHtml = `<!-- Header values -->
            <div class="container-fluid">
                <div class="row">
                    <div class="col-xs-12">
                        <i>${this.i18n.tr("sempa_analysis_based_on")}</i>
                        <i class="mdi mdi-refresh float-right hand" onclick="window.reRunAnalysis();"></i>
                        <br /><br />
                    </div>                
                </div>
                <div class="row">
                    <div class="col-xs-12">
                    <style>
                        .default-table.analyze-time-info td {
                            padding-left: 0;
                        }
                    </style>
                        <table class="default-table analyze-time-info">
                            <tr>
                                <td>${this.i18n.tr("sempa_anamnesis_date")}</td>
                                <td id="sempaAnamnesisDateDisplay">${values.dates.anamnesis ? moment(values.dates.anamnesis).format(RuntimeInfo.DateTimeFormat) : '-'}</td>
                            </tr>
                            <tr>
                                <td>${this.i18n.tr("sempa_assessment_date")}</td> 
                                <td id="sempaAssessmentDateDisplay">${values.dates.assessment ? moment(values.dates.assessment).format(RuntimeInfo.DateTimeFormat) : '-'}</td>
                            </tr>
                        </table>            
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6">&nbsp;</div>
                 </div>
                ${this.generateHtmlScores(values)}
            </div>
            <br />`;

        return resultHtml;
    }

    protected generateHtmlScores(values: ISempaResult): string {
        if (!values) {
            const msg = "No values provided for generting html scores";
            if (ConfigService.Debug && !ConfigService.IsTest)
                console.warn(msg);

            return `<div data-reason="${msg}"></div>`;
        }

        const result: string = `<div class="row" id="htmlScoresParent">
                    <div class="col-sm-6" id="ncsAResultTitle"><span>${values.ncsA.title}</span> <b style="color:${values.ncsA.color}">${values.ncsA.text}</b></div>
                    <div class="col-sm-6 text-right" id="ncsPResultTitle"><b style="color:${values.ncsP.color}; margin-right:-9px">${values.ncsP.text}</b></div>                    
                </div>`;

        return result;
    }

    protected formSetting: IFormSetting;

    get showICDs(): boolean {
        if (!this.formSetting)
            this.formSetting = ConfigService.GetFormSettings('analysis');
        if (!this.formSetting)
            return true;

        return this.formSetting.settings["enableICDMapping"];
    }

    generateFrueRehaRow() : string {
        const settings = this.getFrueRehaConfig();
        if (!settings) return '';

        const _title = this.i18n.tr(settings.title);
        let row : ISempaRow = {
            title: _title,
            text1: '',
            text2: '',
            button: this.getButtonText('freha_clicked', _title, settings.route)
        };

        const response = Fhir.QuestionnaireResponse.GetAttachedResponseDirect(this.patient, this.currentAssessment, settings.questionnaireName);
        if (['completed', 'amended'].indexOf(response?.status) > -1) {
            const item = QuestionnaireResponse.GetResponseItemValueByLinkId(response, 'NIT_FRI_07');
            if (item) {
                row.text1 = String(item);
            }
        }

        window['freha_clicked'] = async () => {
            await this.createPopup(this.patient, this.currentAssessment, settings.questionnaireName, true);
        };

        return this.generateRow(row, false);
    }

    protected generateRow(row: ISempaRow, isICDs?: boolean): string {
        if (!row || (isICDs && !this.showICDs)) return '';
        if (typeof row.isVisible === "boolean" && row.isVisible === false) return '';

        let extraIcon = '';
        if (this.analysisConfig?.settings?.additionalHintText && NitTools.IsArray(this.analysisConfig?.settings?.additionalHintTextCategories)) {
            if (row.tag && this.analysisConfig?.settings?.additionalHintTextCategories.indexOf(String(row.tag).toLowerCase()) > -1 ) {
                extraIcon = `<span class="analysis-additional-hint-icon" title="${this.i18n.tr(this.analysisConfig?.settings?.additionalHintText)}"></span>`;
            }
        }

        // convert color to style. If color property is not a string then simply make the value bold
        // const colorStyle = row && row.color ? (typeof row.color === "string" ? `style="color: ${row.color};"` : `style="font-weight: bold;"`) : "";
        const colorStyle = row && row.color ? `style="font-weight: bold; font-style: italic;"` : "";
        const tag = row.tag || 'untagged';

        const result = isICDs ?
            `<div class="row"> 
                <div class="col-xs-3">${row.title || '&nbsp;'}</div>
                <div class="col-xs-9">${row.text1 || '&nbsp;'}</div>
            </div>`
            :
            `<div class="row" data-tag="${tag}"> 
                <div class="col-xs-3" data-tag="${tag}-title">${row.title || '&nbsp;'}</div>
                <div class="col-xs-3" data-tag="${tag}-text1" ${colorStyle}>${row.text1 || '&nbsp;'}</div>
                <div class="col-xs-3" data-tag="${tag}-button">${row.button || '&nbsp;'}</div>
                <div class="col-xs-3" data-tag="${tag}-info">${extraIcon}${row.text2 || '&nbsp;'}</div>
            </div>`;

        return result;
    }

    protected generateHtmlRows(values: ISempaResult): string {
        const that = this;
        const checkVisibility = function (name): boolean {
            if (!name) return false;

            let result = true;

            if (!that.analysisConfig)
                that.analysisConfig = ConfigService.GetFormSettings('analysis');

            if (!that.analysisConfig || !that.analyserConfig.settings || !that.analysisConfig.settings.riskOverview)
                return result;

            const fieldConfig = that.analysisConfig.settings.riskOverview.find(o => o.field === name);
            if (fieldConfig && typeof fieldConfig.visible === "boolean")
                result = fieldConfig.visible;

            return result;
        };

        const dic: string = '<!-- disabled in config -->';
        let result = `  <!-- SozialDienst / PAV -->
                        ${checkVisibility('pav') ? this.generateRow(values.pav) : dic}
                        
                        <!-- Diabetes Mellitus / diabetes -->
                        ${checkVisibility('diabetes') ? this.generateRow(values.diabetes) : dic}
                        
                        <!-- Dekubitus -->
                        ${checkVisibility('decubitus') ? this.generateRow(values.decu) : dic}
                        
                        <!-- Fall -->
                        ${checkVisibility('fall') ? this.generateRow(values.fall) : dic}
                        
                        <!-- Pneumo -->
                        ${checkVisibility('pneumo') ? this.generateRow(values.pneumo) : dic}
                        
                        <!-- VDD -->
                        ${checkVisibility('demenz') ? this.generateRow(values.vdd) : dic}
                        
                        <!-- MNA -->
                        ${checkVisibility('malnutrition') ? this.generateRow(values.mna) : dic}
                        
                        <!-- NRS -->
                        ${checkVisibility('nrs') ? this.generateRow(values.nrs) : dic}
                        
                        <!-- Mobility / demmi -->
                        ${checkVisibility('mobility') ? this.generateRow(values.demmi) : dic}
                        
                        <!-- Dysphagie / EAT10 -->
                        ${checkVisibility('dysphagie') ? this.generateRow(values.eat) : dic}
                        
                        <!-- Barthel-Index -->
                        ${checkVisibility('barthel') ? this.generateRow(values.barthelIndex) : dic}
                        
                        <!-- Ext. Barthel-Index -->
                        ${checkVisibility('barthelEx') ? this.generateRow(values.barthelIndexEx) : dic}
                        
                        <!-- FrühReha -->
                        ${checkVisibility(this.getFrueRehaConfig()?.route) ? this.generateFrueRehaRow() : dic}
                        
                        <!-- Kontinenz -->
                        ${checkVisibility('continence') ? this.generateRow(values.continence) : dic}
                        
                        <!-- NBA -->
                        ${checkVisibility('nba') && values.nba?.isVisible ? this.generateRow(values.nba) : '<!-- disabled -->'}
                        
                        <!-- extra rows -->
                        ${this.extraRows ? this.extraRows : ''}`;

        result += `<!-- ICD List -->
                   ${checkVisibility('icds') ? this.generateRow(values.icds, true) : dic}`;

        return result;
    }

    protected generateHtmlBody(values: ISempaResult): string {
        if (!values) {
            const msg = "No values provided for html body generation";
            console.warn(msg);
            return `<div data-reason="${msg}"></div>`;
        }

        const rowHtml = this.generateHtmlRows(values);
        let showPrint = !!(ReportService.ReportServer) && (typeof this.analyserConfig?.settings?.showStammblatt === "boolean" ? this.analyserConfig.settings.showStammblatt : true);

        const printButton = showPrint ? `<button style="margin-top:-6px" class="btn btn-default ${showPrint ? '' : 'hidden'}" onclick="printstammblatt();" type="button">${this.i18n.tr("master_data_sheet")}</button>` : '';

        let resultHtml = `<!-- Resulting values: -->
            <div class="panel panel-default">
                <div class="panel-heading">
                    <div style="display:inline-block">${values.title}</div>
                    <div style="float:right">${printButton}</div>
                </div>
                <div class="panel-body">
                    <style scoped>
                      .icd-id-column {
                        padding-right: 0.5em;
                      }
                      
                      .analyse-result-table tr td {
                        vertical-align: middle!important;
                      }
                      
                      div.analyse-result-rows div.row div button.btn{
                          padding: 3px 12px;
                          margin-top: -5px;
                      }
                      
                      div.analyse-result-rows div.row {                          
                          border-bottom: 1px solid rgba(0,0,0,0.5);
                          margin-bottom: 0.5em;
                          padding-bottom: 5px;
                          padding-top: 3px;                          
                      }
                      
                      div.row.analyzer-result-header {
                        font-weight: bold;
                        font-size: 1.1em;
                        margin-bottom: 0.5em;
                        background: rgba(0,0,0,0.05);
                        margin-top: -15px;
                        padding-top: 0.5em;
                        padding-bottom: 0.5em;
                      }
                    </style>
                    <div class="row analyzer-result-header"> <!-- Title Columns -->
                        <div class="col-xs-3" t="sempa_description">${this.i18n.tr("sempa_description")}</div>                    
                        <div class="col-xs-3" t="sempa_result">${this.i18n.tr("sempa_result")}</div>                    
                        <div class="col-xs-3" t="sempa_additional_diagnostics">${this.i18n.tr("sempa_additional_diagnostics")}</div>
                        <div class="col-xs-3" t="sempa_result">${this.i18n.tr("sempa_result")}</div>
                    </div>
                    
                    <div class="analyse-result-rows">                    
                        ${rowHtml}
                    </div>
                </div>
            </div>`;

        return resultHtml;
    }

    protected generateHtml(values: ISempaResult): string {
        let resultHtml = this.generateHtmlHeaders(values);
        resultHtml += this.generateHtmlBody(values);

        return resultHtml;
    }
}

export interface IDemmiMapping {
    source: string;
    mappings: IDemmiMappingDetail[];
}

export interface IDemmiMappingItem {
    field: string;
    value: string;
}

export interface IDemmiMappingDetail {
    sourceValue: string;
    targets: IDemmiMappingItem[];
}

export interface IMailSetting {
    to: string;
    title: string;
    mailBody: string;
    mailSubject: string;
    buttonId: string;
}
